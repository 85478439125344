import Button from "app/storybookComponents/Button";
import { Department } from "app/containers/AdminConsole/types";
import { Team } from "app/containers/Global/types";
import { ALL_TEAM_360_FACTORS } from "app/components/Team360Assessment/constants";
import { TTeam360Factor } from "app/components/Team360Assessment/types";
import { memo, useEffect, useState } from "react";
import Select from "react-select";
import { getSelectProps } from "utils/helperFunctions";
import SideDrawer from "app/components/SideDrawer/SideDrawer";

interface Props {
  show: boolean;
  onHide: () => void;
  departments: {
    [departmentId: number]: Department;
  };
  teamInfoById: {
    [teamId: number]: Team;
  };
  orgScoresEnabled: boolean;
  activeFactorFilters: (TTeam360Factor | "Overall TEAM Score")[];
  activeFilters: {
    Department: number[];
    Team: number[];
  };
  setActiveFactorFilters: (
    filters: (TTeam360Factor | "Overall TEAM Score")[]
  ) => void;
  setActiveFilters: (filters: { Department: number[]; Team: number[] }) => void;
  setOrgScoresEnabled: (enabled: boolean) => void;
  areDepartmentsHidden?: boolean;
}

const FilterDrawer = ({
  show,
  onHide,
  departments,
  teamInfoById,
  orgScoresEnabled,
  activeFactorFilters,
  activeFilters,
  setActiveFactorFilters,
  setActiveFilters,
  setOrgScoresEnabled,
  areDepartmentsHidden,
}: Props) => {
  const MAX_FILTERS = 5;
  const [showAllTeams, setShowAllTeams] = useState(false);
  const [showAllDepartments, setShowAllDepartments] = useState(false);
  const [teamInput, setTeamInput] = useState("");
  const [departmentInput, setDepartmentInput] = useState("");
  const [drawerActiveFilters, setDrawerActiveFilters] = useState<{
    Department: number[];
    Team: number[];
  }>({
    Department: [],
    Team: [],
  });

  const [drawerActiveFactorFilters, setDrawerActiveFactorFilters] = useState<
    (TTeam360Factor | "Overall TEAM Score")[]
  >(["Overall TEAM Score"]);
  const [drawerOrgScoresEnabled, setDrawerOrgScoresEnabled] = useState(true);

  useEffect(() => {
    if (show) {
      setDrawerActiveFilters(activeFilters);
    }
  }, [activeFilters, show]);

  useEffect(() => {
    if (show) {
      setDrawerActiveFactorFilters(activeFactorFilters);
    }
  }, [activeFactorFilters, show]);

  useEffect(() => {
    if (show) {
      setDrawerOrgScoresEnabled(orgScoresEnabled);
    }
  }, [orgScoresEnabled, show]);

  const handleSave = () => {
    setActiveFilters(drawerActiveFilters);
    setActiveFactorFilters(drawerActiveFactorFilters);
    setOrgScoresEnabled(drawerOrgScoresEnabled);
    onHide();
  };

  const onResetFilters = () => {};

  const getDepartments = (departmentLength: number) =>
    Object.values(departments)
      .splice(0, showAllDepartments ? departmentLength : 6)
      .filter(
        (department) =>
          departmentInput === "" ||
          department.name?.toLowerCase().includes(departmentInput.toLowerCase())
      )
      .map((department) => (
        <div key={department.departmentId}>
          <label>
            <input
              type="checkbox"
              checked={drawerActiveFilters.Department.includes(
                department.departmentId
              )}
              onClick={() => {
                if (
                  drawerActiveFilters.Department.includes(
                    department.departmentId
                  )
                ) {
                  return setDrawerActiveFilters({
                    ...drawerActiveFilters,
                    Department: drawerActiveFilters.Department.filter(
                      (id) => id !== department.departmentId
                    ),
                  });
                }

                setDrawerActiveFilters({
                  ...drawerActiveFilters,
                  Department: [
                    ...drawerActiveFilters.Department,
                    department.departmentId,
                  ],
                });
              }}
              disabled={
                drawerActiveFilters.Department.length +
                  drawerActiveFilters.Team.length >=
                  MAX_FILTERS &&
                !drawerActiveFilters.Department.includes(
                  department.departmentId
                )
              }
            />
            <span>{department.name}</span>
          </label>
        </div>
      ));

  const getTeams = (teamLength: number) =>
    Object.values(teamInfoById)
      .splice(0, showAllTeams ? teamLength : 6)
      .filter(
        (team) =>
          teamInput === "" ||
          team.teamName?.toLowerCase().includes(teamInput.toLowerCase())
      )
      .map((team) => (
        <div key={team.teamId}>
          <label>
            <input
              type="checkbox"
              checked={drawerActiveFilters.Team.includes(team.teamId)}
              onClick={() => {
                if (drawerActiveFilters.Team.includes(team.teamId)) {
                  setDrawerActiveFilters({
                    ...drawerActiveFilters,
                    Team: drawerActiveFilters.Team.filter(
                      (id) => id !== team.teamId
                    ),
                  });
                  return;
                }
                setDrawerActiveFilters({
                  ...drawerActiveFilters,
                  Team: [...drawerActiveFilters.Team, team.teamId],
                });
              }}
              disabled={
                drawerActiveFilters.Department.length +
                  drawerActiveFilters.Team.length >=
                  MAX_FILTERS && !drawerActiveFilters.Team.includes(team.teamId)
              }
            />
            <span>{team.teamName}</span>
          </label>
        </div>
      ));

  const getDepartmentFilters = () => {
    if (areDepartmentsHidden) {
      return null;
    }
    const departmentLength = Object.keys(departments).length;
    const { selectStyles, components } = getSelectProps();
    return (
      <>
        <div>
          <h4>Departments</h4>
          <Select
            placeholder={"Search for a department..."}
            isClearable={true}
            isSearchable={true}
            components={components}
            inputValue={departmentInput}
            onInputChange={(e, actionMeta) => {
              if (actionMeta.action === "input-change") {
                setDepartmentInput(e);
              }
            }}
            styles={selectStyles}
            menuIsOpen={false}
            className="mb-2"
          />
          <div className="checkbox-group">
            {getDepartments(departmentLength)}
          </div>
          {departmentLength > 6 ? (
            <Button
              variant="secondary-blue"
              onClick={() => {
                setShowAllDepartments(!showAllDepartments);
              }}
            >
              {showAllDepartments ? "Hide" : "Show All Departments"}
            </Button>
          ) : null}
        </div>
        <hr />
      </>
    );
  };

  const getDrawerBody = () => {
    const { selectStyles, components } = getSelectProps();
    const teamLength = Object.keys(teamInfoById).length;

    return (
      <div className={`analytics-filter-drawer-body${show ? " active" : ""}`}>
        <div>
          <h4>Team Scores</h4>
          <div className="checkbox-group">
            {ALL_TEAM_360_FACTORS.map((factorName) => (
              <div key={factorName}>
                <label>
                  <input
                    type="checkbox"
                    checked={drawerActiveFactorFilters.includes(factorName)}
                    onClick={() => {
                      if (drawerActiveFactorFilters.includes(factorName)) {
                        setDrawerActiveFactorFilters(
                          drawerActiveFactorFilters.filter(
                            (factor) => factor !== factorName
                          )
                        );
                      } else {
                        setDrawerActiveFactorFilters([
                          ...drawerActiveFactorFilters,
                          factorName,
                        ]);
                      }
                    }}
                    disabled={
                      drawerActiveFactorFilters.length >= MAX_FILTERS &&
                      !drawerActiveFactorFilters.includes(factorName)
                    }
                  />
                  <span>{factorName}</span>
                </label>
              </div>
            ))}
          </div>
        </div>
        <hr />
        <div>
          <h4>Organization</h4>
          <label>
            <input
              type="checkbox"
              checked={drawerOrgScoresEnabled}
              onClick={() => setDrawerOrgScoresEnabled(!drawerOrgScoresEnabled)}
            />
            This organization’s overall score
          </label>
        </div>
        <hr />
        {getDepartmentFilters()}
        <div>
          <h4>Teams</h4>
          <Select
            placeholder={"Search for a team..."}
            isClearable={true}
            isSearchable={true}
            components={components}
            inputValue={teamInput}
            onInputChange={(e, actionMeta) => {
              if (actionMeta.action === "input-change") {
                setTeamInput(e);
              }
            }}
            styles={selectStyles}
            menuIsOpen={false}
            className="mb-2"
          />
          <div className="checkbox-group">{getTeams(teamLength)}</div>
          {teamLength > 6 ? (
            <Button
              variant="secondary-blue"
              onClick={() => {
                setShowAllTeams(!showAllTeams);
              }}
            >
              {showAllTeams ? "Hide" : "Show All Teams"}
            </Button>
          ) : null}
        </div>
        <hr />
        <div>
          <Button onClick={handleSave} className="me-2">
            Show Results
          </Button>
          <Button variant="secondary-gray" onClick={() => onResetFilters()}>
            Reset filters
          </Button>
        </div>
      </div>
    );
  };

  return (
    <SideDrawer show={show} onHide={onHide} title="Add Comparison">
      {getDrawerBody()}
    </SideDrawer>
  );
};

export default memo(FilterDrawer);
