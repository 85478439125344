import { ReactElement, useEffect, useState } from "react";
import { showAddModuleModal } from "app/components/Modules/slice";
import {
  selectCurrentUserAccountId,
  selectCompanyInfo,
  selectAllCompanyUsersById,
  selectTeamsByTeamId,
  selectIsCurrentUserAdmin,
} from "app/containers/Global/slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Dropdown } from "react-bootstrap";
import ModulesModal from "app/components/Modules/Modals/ModuleModal";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import Section from "app/components/Modules/Section";
import Button from "app/storybookComponents/Button";
import Header from "app/storybookComponents/GuideHeaderCard";
import BuildYourUserGuideCard from "app/components/BuildYourGuideCard";
import {
  getTeam360Scores,
  selectAllTeam360Results,
  selectGettingAllTeam360AssessmentResultsStatus,
} from "app/components/SurveyDataInstances/slice";
import {
  getModuleTemplates,
  selectCompanyModuleTemplates,
  selectGetModuleTemplatesStatus,
  selectCompanyGuide,
  selectUsedCompanyTemplateIds,
  deleteCompanyModuleById,
  deleteSectionById,
  selectDeletingStatus,
  selectUpdatingGuidePhotoStatus,
  selectDeletingGuidePhotoStatus,
  updateGuidePhoto,
  deleteGuidePhoto,
} from "./slice";
import {
  selectAnalyticsText,
  selectGetAnalyticsTextStatus,
  selectDepartments,
  getAnalyticsText as getAnalyticsTextAction,
} from "app/containers/AdminConsole/slice";
import { showScheduleAssessmentModalForOrganization } from "app/components/LaunchAssessmentModal/slice";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import { ClipLoader } from "react-spinners";
import UploadPictureModal from "app/components/ImageEditor/UploadPictureModal";
import AvatarCircle from "app/components/AvatarCircle";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ORG_TABS } from "./constants";
import { OrgGuideTab, isOrgGuideTab } from "./types";
import NavigateBackButton from "app/components/NavigateBackButton";
import Team360ResultsModal from "app/components/Team360Assessment/Team360ResultsModal/Team360ResultsModal";
import { TTeam360Factor } from "app/components/Team360Assessment/types";
import EntityCard from "./EntityCard";
import ResultsOverviewCard from "app/components/TEAM360InsightsModules/ResultsOverviewCard";
import InsightReportHeader from "app/components/TEAM360InsightsModules/InsightReportHeader";
import EmptyTeam360ResultsCard from "app/components/Team360Assessment/EmptyTeam360ResultsCard";
import { setShowModal as setShowOnboardingModal } from "app/components/Onboarding/slice";
import { NON_ADMIN_USER_IDs } from "utils/constants";
import Team360ScoreBreakdownModal from "app/components/Team360Assessment/Team360ScoreBreakdownModal";
import InviteUsersModal from "app/components/Modals/InviteUsersModal";
import useTrackPage from "utils/hooks/useTrackPage";
import Analytics from "../AdminConsole/Analytics";
import NoGuideYet from "app/components/GuideComponents/NoGuideYet";

// Organizational Guide is the same as Company Guide.
export default function CompanyGuide() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // ------------ App Selectors ---------------
  const departments = useAppSelector(selectDepartments);
  const usersInfoById = useAppSelector(selectAllCompanyUsersById);
  const companyInfo = useAppSelector(selectCompanyInfo);
  const teamInfoById = useAppSelector(selectTeamsByTeamId);
  const currentUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const moduleTemplates = useAppSelector(selectCompanyModuleTemplates);
  const getModuleTemplatesStatus = useAppSelector(
    selectGetModuleTemplatesStatus
  );
  const analyticsText = useAppSelector(selectAnalyticsText);
  const currentCompanyGuide = useAppSelector(selectCompanyGuide);
  const companyUsedModules = useAppSelector(selectUsedCompanyTemplateIds);
  const deletingStatus = useAppSelector(selectDeletingStatus);
  const updatingGuidePhotoStatus = useAppSelector(
    selectUpdatingGuidePhotoStatus
  );
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const deletingGuidePhotoStatus = useAppSelector(
    selectDeletingGuidePhotoStatus
  );
  const allTeam360Scores = useAppSelector(selectAllTeam360Results());
  const allTeam360ScoresStatus = useAppSelector(
    selectGettingAllTeam360AssessmentResultsStatus
  );
  const getAnalyticsTextStatus = useAppSelector(selectGetAnalyticsTextStatus);

  const teamScores = allTeam360Scores?.teamScores;
  const teamScoresWithOverallField = Object.values(teamScores || {}).filter(
    (teamScore) => teamScore?.overall !== null
  );

  const hasEnoughResults = teamScoresWithOverallField.length >= 3;

  const filteredDepartments = Object.fromEntries(
    Object.entries(departments).filter(
      ([departmentId, department]) => department.companyAccountId !== 0
    )
  );

  // ---------------- Modal States ----------------
  const [photoModalShowing, setPhotoModalShowing] = useState<
    "profilePicture" | "coverPhoto" | null
  >(null);
  const [deleting, setDeleting] = useState<null | {
    type: "Module" | "Section";
    id: number | string;
    moduleType?: string;
    moduleTemplateId?: number;
  }>(null);
  const [hasEditAccess, setHasEditAccess] = useState<boolean>(true);
  const [activeHeaderTab, setActiveHeaderTab] = useState<OrgGuideTab>("About");
  const [showTeam360ResultsModal, setShowTeam360ResultsModal] =
    useState<null | TTeam360Factor>(null);
  const [showScoreBreakdownModal, setShowScoreBreakdownModal] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  // This hook fetches for the getCompanyGuideStatus if it is idle. To prevent multiple calls.
  useEffect(() => {
    if (getModuleTemplatesStatus === "idle") {
      dispatch(getModuleTemplates());
    }
  }, [getModuleTemplatesStatus, dispatch]);

  useEffect(() => {
    // If the query params is set for the tab and the tab is a valid tab then we set the active tab to the query param.
    const tab = searchParams.get("tab");
    if (tab && isOrgGuideTab(tab)) {
      setActiveHeaderTab(tab);
    } else {
      // if no tab is set or the tab is not a valid tab then we set the tab to the about tab.
      setActiveHeaderTab("About");
    }
  }, [searchParams, dispatch]);

  // ----------------------------------- On success hooks ------------------------------------------
  useTrackPage();
  useEffect(() => {
    if (updatingGuidePhotoStatus === "succeeded") {
      setPhotoModalShowing(null);
    }
  }, [updatingGuidePhotoStatus]);

  useEffect(() => {
    if (deletingGuidePhotoStatus === "succeeded") {
      setPhotoModalShowing(null);
    }
  }, [deletingGuidePhotoStatus]);

  useEffect(() => {
    if (deletingStatus === "succeeded") {
      setDeleting(null);
    }
  }, [deletingStatus]);

  useEffect(() => {
    if (getAnalyticsTextStatus === "idle") {
      dispatch(getAnalyticsTextAction());
    }
  }, [dispatch, getAnalyticsTextStatus]);

  useEffect(() => {
    if (allTeam360ScoresStatus === "idle") {
      dispatch(getTeam360Scores());
    }
  }, [dispatch, allTeam360ScoresStatus]);

  useEffect(() => {
    setHasEditAccess(
      !!(
        currentUserAccountId &&
        usersInfoById[currentUserAccountId]?.tmgRoleId === 1
      )
    );
  }, [currentUserAccountId, usersInfoById]);

  const getDepartmentDescription = (
    teamLength?: number,
    teamMemberLength?: number
  ) => {
    const teamLengthSpan = teamLength ? (
      <span>
        {teamLength} team{teamLength > 1 ? "s" : ""}
      </span>
    ) : null;
    const teamMemberLengthSpan = teamMemberLength ? (
      <span>
        {teamMemberLength} team member{teamMemberLength > 1 ? "s" : ""}
      </span>
    ) : null;

    if (teamLengthSpan && teamMemberLengthSpan) {
      return (
        <div className="section-body-row-description">
          <span>{teamLengthSpan}</span>
          <FontAwesomeIcon
            icon="circle"
            className="mx-2"
            style={{ fontSize: "4px" }}
          />
          <span>{teamMemberLengthSpan}</span>
        </div>
      );
    }
    return (
      <div className="section-body-row-description">
        <span>{teamLengthSpan || teamMemberLengthSpan || null}</span>
      </div>
    );
  };

  const getDepartmentCard = () => {
    // Sort the departments by team count
    const departmentRows = Object.values(filteredDepartments)
      .sort((a, b) => {
        if (a?.teams?.length && b?.teams?.length === undefined) {
          return -1;
        }
        if (a?.teams?.length === undefined && b?.teams?.length) {
          return 1;
        }

        if (a?.teams?.length && b?.teams?.length) {
          return b.teams.length - a.teams.length;
        }
        return 0;
      })
      .slice(0, 12)
      .map((department) => {
        const leadId =
          typeof department.leader === "number"
            ? department.leader
            : department.leader?.userAccountId;
        if (department.teamMembers?.length === 0) return null;
        return (
          <div
            className="section-body-row"
            key={department.departmentId}
            onClick={() =>
              navigate(`/Search/Teams?departmentId=${department.departmentId}`)
            }
          >
            <div>
              <span className="section-body-row-name">{department.name}</span>
              <div className="section-body-name-and-description">
                {getDepartmentDescription(
                  department.teams?.length,
                  department.teamMembers?.length
                )}
              </div>
            </div>
            {leadId ? (
              <div>
                <AvatarCircle userAccountId={leadId} />
              </div>
            ) : (
              <div>
                <AvatarCircle name={department.name} />
              </div>
            )}
          </div>
        );
      });
    const departmentCount = Object.values(filteredDepartments).length;

    return (
      <EntityCard
        entity="Department"
        title="Departments"
        createButtonText="Create a department"
        zeroStateTitle="No Departments Created"
        zeroStateText="Departments created will be shown here"
        rows={departmentRows}
        isAdmin={isAdmin as boolean}
        count={departmentCount}
      />
    );
  };

  const getTeamsCard = () => {
    // Only show the top 5 teams sorted by the number of members.
    const teamRows = [...Object.values(teamInfoById)]
      .sort((a, b) => {
        if (
          a?.teamMemberIds?.length &&
          b?.teamMemberIds?.length === undefined
        ) {
          return -1;
        }
        if (
          a?.teamMemberIds?.length === undefined &&
          b?.teamMemberIds?.length
        ) {
          return 1;
        }

        if (
          a?.teamMemberIds?.length !== undefined &&
          b?.teamMemberIds?.length !== undefined
        ) {
          return a?.teamMemberIds?.length > b?.teamMemberIds?.length ? -1 : 1;
        }
        return a.teamName > b.teamName ? 1 : -1;
      })
      .slice(0, 5)
      .map((teamInfo) => {
        const acceptedTeamMembers = teamInfo?.teamMemberIds?.length || 0;
        const pendingTeamMembers = teamInfo?.pendingTeamMemberIds?.length || 0;
        const totalMembers = acceptedTeamMembers + pendingTeamMembers;
        return (
          <div
            className="section-body-row"
            key={teamInfo.teamId}
            role="button"
            onClick={() => navigate(`/TeamGuide/${teamInfo.teamId}`)}
          >
            <div className="section-body-name-and-description">
              <span className="section-body-row-name">{teamInfo.teamName}</span>
              <span className="section-body-row-description">
                {totalMembers || 1} Member{totalMembers > 1 ? "s" : ""}
              </span>
            </div>
          </div>
        );
      });
    const teamCount = Object.values(teamInfoById).length;

    return (
      <EntityCard
        entity="Team"
        title="Teams"
        createButtonText="Create a team"
        zeroStateTitle="No Teams Created"
        zeroStateText="Teams created will be shown here"
        rows={teamRows}
        isAdmin={isAdmin as boolean}
        count={teamCount}
      />
    );
  };

  const onDropdownSelect = (e: string | null) => {
    switch (e) {
      case "add":
        return dispatch(
          showAddModuleModal({
            section: "Connect",
            guideType: "company",
            userIdOrTeamIdOrCompanyId: Number(companyAccountId),
          })
        );
    }
  };

  const getContactCard = () => {
    const teamAdmins = Object.values(usersInfoById)
      .filter((user) => {
        if (user.tmgRoleId !== 1) {
          return false;
        }

        // The following logic is only temporary, will remove once we move into production.
        if (NON_ADMIN_USER_IDs.includes(user.userAccountId)) {
          return false;
        }

        return true;
      })
      .map((user) => {
        const { firstName, lastName, emailAddress, jobTitle, userAccountId } =
          user;
        return (
          <div key={userAccountId} className="leader-info">
            <div
              role="button"
              onClick={() => navigate(`/UserGuide/${userAccountId}`)}
            >
              <AvatarCircle
                name={`${firstName} ${lastName}`}
                userAccountId={userAccountId}
              />
              <div>
                <p className="member-name">
                  {firstName} {lastName}
                </p>
                <span className="member-role">
                  {jobTitle || "No Job Title"}
                </span>
              </div>
            </div>
            <div>
              <div className="contact-button" role="button">
                <a href={`mailto:${emailAddress}`} style={{ color: "white" }}>
                  <FontAwesomeIcon icon="envelope" />
                </a>
              </div>
            </div>
          </div>
        );
      });

    return (
      <Card className="section">
        <div className="section-header">
          <h2>Connect</h2>
          <Dropdown onSelect={(e) => onDropdownSelect(e)}>
            <Dropdown.Toggle
              variant="outline-primary"
              id="dropdown-basic"
              className="no-caret"
            >
              <FontAwesomeIcon icon="ellipsis" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="add">
                <FontAwesomeIcon icon="plus" /> Add To Section
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {teamAdmins.length > 0 ? (
          <div className="team-leads">
            <Card className="team-lead">
              <h3>Team Admin{teamAdmins.length > 1 ? "s" : ""}</h3>
              <div className="leaders-container">{teamAdmins}</div>
            </Card>
          </div>
        ) : null}
      </Card>
    );
  };

  const getTeamMembersCard = () => {
    const teamMembers: ReactElement[] = [];

    const teamMemberIds = [...Object.values(usersInfoById)]?.sort((a, b) => {
      // Temporarily sorting by job title, should change in the future.
      if (!a.jobTitle && b.jobTitle) return 1;
      if (a.jobTitle && !b.jobTitle) return -1;
      if (a.jobTitle && b.jobTitle) {
        return a.jobTitle > b.jobTitle ? 1 : -1;
      }
      return 0;
    });

    let memberCount = 0;
    teamMemberIds?.forEach(({ userAccountId }, idx) => {
      // If the user does not exist inside of usersInfoById then we can assume that the team member no longer exists.
      if (!usersInfoById[userAccountId]) return;
      memberCount += 1;

      // Only show 3 team members
      if (idx > 3) return;
      const { firstName, lastName, profilePicture, jobTitle } =
        usersInfoById[userAccountId];

      teamMembers.push(
        <Card
          key={userAccountId}
          role="button"
          className="team-member-card-no-button"
          onClick={() => navigate(`/UserGuide/${userAccountId}`)}
        >
          <AvatarCircle
            name={`${firstName} ${lastName}`}
            profilePicture={profilePicture}
            userAccountId={userAccountId}
            size="large"
          />
          <span className="name">
            {firstName} {lastName}
          </span>
          <span className="role">{jobTitle}</span>
        </Card>
      );
    });
    return (
      <EntityCard
        entity="User"
        title="All Members"
        createButtonText="Add people"
        zeroStateTitle="No Members Joined"
        zeroStateText="Members who join your organization will be shown here"
        rows={teamMembers}
        isAdmin={isAdmin as boolean}
        count={memberCount}
        onOpenInvitePeopleModal={() => setIsInviteModalOpen(true)}
      />
    );
  };

  const getGuideSections = () => {
    if (!currentCompanyGuide || !companyAccountId) {
      return null;
    }

    if (
      Object.values(currentCompanyGuide.content).length === 0 &&
      !hasEditAccess
    ) {
      return (
        <NoGuideYet name={companyInfo?.companyName || ""} type="company" />
      );
    }

    return Object.values(currentCompanyGuide.content).map(
      ({ sectionId, title, modules, moduleOrder }) => {
        return (
          <Section
            guideType="company"
            userIdOrTeamIdOrCompanyId={companyAccountId}
            moduleOrder={getModuleOrder(moduleOrder)}
            sectionId={sectionId}
            title={title}
            modules={modules}
            key={sectionId}
            setDeleting={setDeleting}
            hasEditAccess={hasEditAccess}
          />
        );
      }
    );
  };

  const getModuleOrder = (moduleOrderString: string) => {
    try {
      return JSON.parse(moduleOrderString) || [];
    } catch (e) {
      return [];
    }
  };

  const getAddOrgGuideModuleBanner = () => {
    if (!hasEditAccess || !companyAccountId) {
      return null;
    }

    return (
      <div className="warning-banner blue add-module-banner">
        <p>
          Add / Edit your organization profile: Add organization or
          company-related information to this profile or edit any existing
          information.
        </p>
        <Button
          onClick={() =>
            dispatch(
              showAddModuleModal({
                guideType: "company",
                userIdOrTeamIdOrCompanyId: companyAccountId,
              })
            )
          }
        >
          <FontAwesomeIcon icon="plus" className="me-2" />
          Add to profile
        </Button>
      </div>
    );
  };

  // Returns two module templates, of each type.
  const getBuildYourUserGuide = () => {
    if (
      !companyAccountId ||
      (currentCompanyGuide &&
        Object.keys(currentCompanyGuide.content).length > 2) ||
      moduleTemplates === null
    ) {
      return [];
    }

    const returnModules = [];
    const freeTextModule = Object.entries(moduleTemplates["Free Text"]).find(
      ([moduleTemplateId]) =>
        !currentCompanyUsedModules?.["Free Text"].includes(
          Number(moduleTemplateId)
        )
    );

    if (freeTextModule) {
      returnModules.push({ ...freeTextModule[1], moduleType: "Free Text" });
    }
    const listModule = Object.entries(moduleTemplates["List"]).find(
      ([moduleTemplateId]) =>
        !currentCompanyUsedModules?.["List"].includes(Number(moduleTemplateId))
    );
    if (listModule) {
      returnModules.push({ ...listModule[1], moduleType: "List" });
    }

    return returnModules;
  };

  const getTabContent = () => {
    if (activeHeaderTab === "About" && companyAccountId) {
      return (
        <>
          {getAddOrgGuideModuleBanner()}
          <div className="sections">
            {getGuideSections()}
            {hasEditAccess && currentCompanyGuide ? (
              <BuildYourUserGuideCard
                onClickHandler={() =>
                  dispatch(
                    showAddModuleModal({
                      guideType: "company",
                      userIdOrTeamIdOrCompanyId: companyAccountId,
                    })
                  )
                }
                guideType="company"
                modules={getBuildYourUserGuide()}
                userIdOrTeamIdOrCompanyId={companyAccountId}
              />
            ) : null}
          </div>
          <div className="side-bar">
            <div>{getContactCard()}</div>
            <div>{getDepartmentCard()}</div>
            <div>{getTeamsCard()}</div>
            <div>{getTeamMembersCard()}</div>
          </div>
        </>
      );
    }

    if (activeHeaderTab === "TEAMscan" && allTeam360Scores) {
      if (isAdmin) {
        return (
          <div className="company-guide-full-teamscan-report">
            <Analytics wrapAnalyticsInCard />
          </div>
        );
      }

      return (
        <div style={{ gridColumn: "2 span" }}>
          {hasEnoughResults ? (
            <Card
              style={{ padding: "20px", height: "fit-content" }}
              className="column-gap-24px"
            >
              <InsightReportHeader
                completionInfo={analyticsText?.completionInfo}
              />
              <ResultsOverviewCard
                overviewText={analyticsText?.overview?.overviewText ?? ""}
                companyScores={allTeam360Scores?.companyScores}
                setShowTeam360ResultsModal={setShowTeam360ResultsModal}
                type="organization"
                title="How Is Your Organization Doing Overall?"
                footerButtons={
                  <>
                    {isAdmin ? (
                      <Button
                        onClick={() => {
                          navigate("/AdminConsole/Analytics");
                        }}
                      >
                        See full insights report
                      </Button>
                    ) : null}
                    <Button
                      variant="secondary-blue"
                      onClick={() => {
                        setSearchParams((searchParams) => {
                          searchParams.set("showScoreBreakdownModal", "true");
                          return searchParams;
                        });
                        setShowScoreBreakdownModal(true);
                      }}
                    >
                      See score breakdown
                    </Button>
                  </>
                }
                hideActionButtons
              />
            </Card>
          ) : (
            <EmptyTeam360ResultsCard
              completionInfo={analyticsText?.completionInfo}
              entityType="organization"
              hasEditAccess={isAdmin || false}
              onScheduleAssessment={() =>
                dispatch(showScheduleAssessmentModalForOrganization())
              }
              onShowLeaderOnboarding={() => {
                dispatch(
                  setShowOnboardingModal({
                    eventType: "team360LeadViewNotScheduledYet",
                  })
                );
              }}
            />
          )}
        </div>
      );
    }
    return null;
  };

  const onCompanyGuideDeleteClick = () => {
    if (deleting?.id === undefined || companyAccountId === undefined) {
      return;
    }

    if (deleting?.type === "Module") {
      dispatch(
        deleteCompanyModuleById({
          talentInsightsModuleId: Number(deleting.id),
          companyAccountId,
        })
      );
    } else if (deleting?.type === "Section") {
      dispatch(
        deleteSectionById({
          sectionId: Number(deleting.id),
          companyAccountId,
        })
      );
    }
  };

  const onTabSelect = (tab: string) => {
    if (tab === ORG_TABS.ORG_SETTINGS) {
      return navigate(`/AdminConsole/Settings`);
    }
    setSearchParams({ tab });
    setActiveHeaderTab(tab as OrgGuideTab);
  };

  const getHeaderTabs = () => {
    const tabs: string[] = [ORG_TABS.ABOUT, ORG_TABS.TEAMSCAN];
    if (isAdmin) {
      tabs.push(ORG_TABS.ORG_SETTINGS);
    }
    return tabs;
  };

  const loggedInUser =
    (currentUserAccountId && usersInfoById[currentUserAccountId]) || undefined;
  const companyAccountId = loggedInUser?.companyAccountId;

  if (!companyAccountId)
    return <ClipLoader color="#36d7b7" speedMultiplier={0.5} />;

  const currentCompanyUsedModules =
    companyUsedModules?.[companyAccountId] || null;
  const BackButton = <NavigateBackButton />;

  const disabledTabs = hasEnoughResults ? [] : ["TEAMscan"];
  return (
    <div className="page guide">
      {showTeam360ResultsModal && allTeam360Scores ? (
        <Team360ResultsModal
          show={!!showTeam360ResultsModal}
          hideModal={() => setShowTeam360ResultsModal(null)}
          companyScores={allTeam360Scores?.companyScores}
          activeFactor={showTeam360ResultsModal}
        />
      ) : null}
      <Team360ScoreBreakdownModal
        show={showScoreBreakdownModal}
        onHide={() => setShowScoreBreakdownModal(false)}
        modalSearchParam="showScoreBreakdownModal"
      />
      <UploadPictureModal
        modalShowing={photoModalShowing}
        closeModal={() => setPhotoModalShowing(null)}
        picture={
          photoModalShowing === "profilePicture"
            ? currentCompanyGuide?.profilePicture?.base64
            : currentCompanyGuide?.coverPhoto?.base64
        }
        isLoading={
          deletingGuidePhotoStatus === "loading" ||
          updatingGuidePhotoStatus === "loading"
        }
        updateGuidePhoto={(photo, imgSrc, imageName) => {
          if (photoModalShowing === null) {
            return;
          }
          dispatch(
            updateGuidePhoto({
              photo,
              imgSrc,
              photoType: photoModalShowing,
              companyAccountId,
              imageName,
            })
          );
        }}
        deleteGuidePhoto={() => {
          if (photoModalShowing === null) {
            return;
          }

          dispatch(
            deleteGuidePhoto({
              companyAccountId,
              photoType: photoModalShowing,
            })
          );
        }}
      />
      <InviteUsersModal
        showing={isInviteModalOpen}
        hideModal={() => {
          setIsInviteModalOpen(false);
        }}
        onInviteSuccess={() => {
          setIsInviteModalOpen(false);
        }}
      />
      <ModulesModal />
      {deleting !== null && (
        <WarningModal
          modalTitle={`Delete ${
            deleting.type === "Section" ? "Section" : "Module"
          }`}
          warningTitle={`Are you sure you want to delete this ${deleting?.type.toLocaleLowerCase()}`}
          warningMessage={
            deleting.type === "Section"
              ? "All modules inside this section will be deleted as well. This action cannot be undone"
              : "This action cannot be undone"
          }
          hideModal={() => setDeleting(null)}
          onConfirmClick={onCompanyGuideDeleteClick}
          isOpen={deleting !== null}
          isInProgress={deletingStatus === "loading"}
        />
      )}
      {BackButton ? <div className="header-buttons">{BackButton}</div> : null}
      <Header
        name={companyInfo?.companyName || ""}
        coverPhoto={currentCompanyGuide?.coverPhoto?.picture || undefined}
        profilePicture={
          currentCompanyGuide?.profilePicture?.picture || undefined
        }
        tabs={getHeaderTabs()}
        disabledTabs={disabledTabs}
        activeKey={activeHeaderTab}
        setTab={onTabSelect}
        setPhotoModalShowing={setPhotoModalShowing}
        hasEditAccess={hasEditAccess}
        totalMembers={Object.keys(usersInfoById).length}
      />
      <div className="guide-body">{getTabContent()}</div>
    </div>
  );
}
