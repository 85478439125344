import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import AvatarCircle from "app/components/AvatarCircle";
import Button from "app/storybookComponents/Button";
import Robot from "resources/images/robot.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  selectAllCompanyUsersById,
  selectGetAllUserStatus,
} from "app/containers/Global/slice";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  getCandidateProfile,
  selectConnectedCandidate,
} from "app/containers/UserGuide/slice";
import { Link } from "react-router-dom";
import { selectAiFeaturesEnabled } from "app/containers/AdminConsole/slice";
import {
  resetChatbotState,
  setCoachBotPrompt,
  setIsCoachbotOpen,
} from "app/containers/Chatbot/slice";
import { getWelcomeChatPrompt } from "app/containers/UserGuide/helpers";
import {
  getWorkPlaceInsightsReportByUserId,
  selectWorkPlaceInsightsReportsById,
} from "app/components/WorkplaceInsightsReport/slice";

interface NewDirectReportModalContentProps {
  onCloseModal: () => void;
  userAccountId: number;
}

const NewDirectReportModalContent = ({
  onCloseModal,
  userAccountId,
}: Readonly<NewDirectReportModalContentProps>) => {
  const dispatch = useAppDispatch();
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const connectedCandidate = useAppSelector(
    selectConnectedCandidate(userAccountId)
  );
  const workPlaceInsightReport = useAppSelector(
    selectWorkPlaceInsightsReportsById(userAccountId)
  );
  const areAIFeaturesEnabled = useAppSelector(selectAiFeaturesEnabled);
  const getAllUsersStatus = useAppSelector(selectGetAllUserStatus);

  const [wasInviteSent, setWasInviteSent] = useState(false);
  const userInfo = usersById[userAccountId];
  const fullName = `${userInfo?.firstName} ${userInfo?.lastName}`;
  const USER_GUIDE_PATH = `/UserGuide/${userAccountId}`;

  useEffect(() => {
    if (!userAccountId) {
      return;
    }
    dispatch(getCandidateProfile({ userAccountId }));
    dispatch(getWorkPlaceInsightsReportByUserId(userAccountId));
  }, [userAccountId, dispatch]);

  const getHeader = () => {
    return (
      <Card className="row-gap-24px padding-20px align-items-center">
        <div>
          <AvatarCircle size="large" userAccountId={userAccountId} />
        </div>
        <div className="column-gap-8px">
          <h1>{fullName}</h1>
          {connectedCandidate?.candidateStartDate ? (
            <p>Start Date: {connectedCandidate?.candidateStartDate}</p>
          ) : null}
        </div>
      </Card>
    );
  };

  const getContent = () => {
    return (
      <div className="column-gap-20px">
        {getHeader()}
        {getManagerNoteSection()}
        {getReportSection()}
        {getAttachmentSection()}
        {getInviteSection()}
        {getCoachBoSection()}
      </div>
    );
  };

  const getSharedLink = (label: string, pathName: string) => (
    <div className="row-gap-16px align-items-center">
      <FontAwesomeIcon
        icon="circle-check"
        style={{
          color: "#001f4e",
        }}
      />
      <Link
        to={pathName}
        onClick={onCloseModal}
        style={{
          fontSize: "14px",
        }}
      >
        {label}
      </Link>
    </div>
  );

  const getManagerNoteSection = () => {
    if (!connectedCandidate?.noteToManager) {
      return null;
    }
    return (
      <React.Fragment>
        <p>Here is what has been shared by the hiring manager:</p>
        <div className="column-gap-8px">
          <p>
            <b>Notes</b>
          </p>
          <p>{connectedCandidate.noteToManager}</p>
        </div>
      </React.Fragment>
    );
  };

  const getReportSection = () => {
    if (!workPlaceInsightReport) {
      return null;
    }

    return (
      <div className="column-gap-8px">
        <p>
          <b>Reports</b>
        </p>
        <div className="column-gap-4px">
          {getSharedLink(
            "Manager Guide",
            `${USER_GUIDE_PATH}?tab=Manager Guide`
          )}
          {getSharedLink(
            "Workplace Insights Report",
            `${USER_GUIDE_PATH}?tab=Workplace Insights`
          )}
        </div>
      </div>
    );
  };

  const getAttachmentSection = () => {
    if (
      !connectedCandidate?.shareResume &&
      !connectedCandidate?.shareCoverLetter
    ) {
      return null;
    }
    const pathName = `${USER_GUIDE_PATH}?tab=Job Candidate Profile`;

    return (
      <div className="column-gap-8px">
        <p>
          <b>Attachments</b>
        </p>
        <div className="column-gap-4px">
          {connectedCandidate?.shareResume
            ? getSharedLink("Resume", pathName)
            : null}
          {connectedCandidate?.shareCoverLetter
            ? getSharedLink("Cover Letter", pathName)
            : null}
        </div>
      </div>
    );
  };

  const getInviteSection = () => {
    if (userInfo.tmgInvited || getAllUsersStatus !== "succeeded") {
      return null;
    }
    return (
      <div className="warning-banner light-blue d-flex justify-content-between align-items-center">
        <p
          style={{
            color: "black",
          }}
        >
          {fullName}, has not been invited to join Develop yet. Invite them now?
        </p>
        <div>
          {!wasInviteSent ? (
            <Button
              variant="secondary-blue"
              onClick={() => setWasInviteSent(true)}
            >
              Send invite
            </Button>
          ) : (
            <div
              className="row-gap-8px"
              style={{
                color: "#425CC7",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              <FontAwesomeIcon icon="check-circle" />
              <p>Invited</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  const onCoachBoClick = () => {
    onCloseModal();
    const firstName = userInfo?.firstName ?? "your new hire";
    dispatch(resetChatbotState());
    dispatch(setCoachBotPrompt(getWelcomeChatPrompt(firstName, userAccountId)));
    dispatch(setIsCoachbotOpen(true));
  };

  const getCoachBoSection = () => {
    if (!areAIFeaturesEnabled) {
      return null;
    }
    return (
      <div className="row-gap-20px align-items-center justify-content-between">
        <div className="column-gap-8px">
          <div className="row-gap-8px align-items-center">
            <img
              src={Robot}
              alt="Criteria"
              style={{
                width: "32px",
              }}
            />
            <p className="fw-bold">
              Ready to start onboarding {userInfo?.firstName}?
            </p>
          </div>
          <p>
            Chat with Coach Bo to learn the best ways set up{" "}
            {userInfo?.firstName} for success.
          </p>
        </div>
        <div>
          <Button
            onClick={onCoachBoClick}
            style={{
              whiteSpace: "nowrap",
            }}
          >
            Chat with Coach Bo
          </Button>
        </div>
      </div>
    );
  };

  return userInfo ? getContent() : null;
};

export default NewDirectReportModalContent;
