import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";

interface Props {
  hasPendingNotifications?: boolean;
  numberCount?: number | null;
  color?: string;
  className?: string;
}

export default function NotificationBellWithNumber({
  hasPendingNotifications,
  numberCount = null,
  color,
  className,
}: Props) {
  const componentClassName = useMemo(() => {
    const classNameArray = ["notification-bell-with-number"];
    if (className) {
      classNameArray.push(className);
    }
    return classNameArray.join(" ");
  }, [className]);

  return (
    <div className={componentClassName}>
      {hasPendingNotifications ? (
        <span className="notification-count">
          {numberCount && numberCount > 10 ? `9+` : numberCount}
        </span>
      ) : null}
      <FontAwesomeIcon icon={["far", "bell"]} color={color} />
    </div>
  );
}
