import { memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AvatarCircle from "app/components/AvatarCircle";
import { Card, Nav } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import ClickableElement from "app/storybookComponents/Button/ClickableElement";
import { getS } from "utils/helperFunctions";
import AutomaticTeamPopover from "app/containers/AutomationSettings/Popovers/AutomaticTeamPopover";
import OverflowContainer from "app/storybookComponents/OverflowContainer/OverflowContainer";

interface Props<TTab extends string> {
  name: string;
  onEditClickHandler?: () => void;
  setPhotoModalShowing: (modalToShow: "profilePicture" | "coverPhoto") => void;
  userAccountId?: number;
  headerDescription?: string;
  profilePicture?: string;
  avatarColor?: string;
  coverPhoto?: string;
  hasEditAccess?: boolean;
  totalMembers?: number;
  pendingTeamMemberCount?: number;
  companyHeaderPhoto?: string | null;
  setTab?: (tab: TTab) => void;
  activeKey?: TTab;
  tabs?: readonly TTab[];
  disabledTabs?: readonly TTab[];
  onInvite?: () => void;
  isPrivateAndNoAccess?: boolean;
  isAutomatic?: 0 | 1;
  isAdmin?: boolean;
}

const Header = memo(
  <TTab extends string>({
    name,
    headerDescription,
    profilePicture,
    userAccountId,
    avatarColor,
    setPhotoModalShowing,
    coverPhoto,
    hasEditAccess,
    totalMembers,
    onEditClickHandler,
    companyHeaderPhoto,
    activeKey,
    tabs,
    disabledTabs,
    setTab,
    onInvite,
    isPrivateAndNoAccess,
    pendingTeamMemberCount,
    isAutomatic,
    isAdmin,
  }: Props<TTab>) => {
    const withTabs = !!(activeKey && tabs);

    const getTotalMembersText = () => {
      const totalMembersText = totalMembers
        ? `${totalMembers} Member${getS(totalMembers)}`
        : "";

      if (pendingTeamMemberCount) {
        if (totalMembersText) {
          return `${totalMembersText}, ${pendingTeamMemberCount} Pending`;
        }

        return `${pendingTeamMemberCount} Pending Member${getS(
          pendingTeamMemberCount
        )}`;
      }

      return totalMembersText;
    };

    const getTabs = () => {
      if (!withTabs) {
        return null;
      }
      return (
        <OverflowContainer className="header-guide-nav-container">
          <Nav
            className={`simple-nav${isPrivateAndNoAccess ? " disabled" : ""}`}
            activeKey={activeKey}
            onSelect={(selectedKey) => {
              if (
                setTab &&
                isPrivateAndNoAccess !== true &&
                selectedKey !== activeKey
              ) {
                setTab(selectedKey as TTab);
              }
            }}
            aria-disabled={isPrivateAndNoAccess}
          >
            {tabs.map((tab) => (
              <Nav.Item key={tab}>
                <Nav.Link
                  eventKey={tab}
                  disabled={isPrivateAndNoAccess || disabledTabs?.includes(tab)}
                >
                  {tab}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </OverflowContainer>
      );
    };

    const totalMembersText = getTotalMembersText();

    return (
      <Card className={`guide-header ${withTabs ? "with-tabs" : ""}`}>
        <div
          className="cover-photo-placeholder"
          style={{
            backgroundImage:
              coverPhoto || companyHeaderPhoto
                ? `url(${coverPhoto || companyHeaderPhoto})`
                : undefined,
          }}
        />
        {hasEditAccess ? (
          <ClickableElement
            className="edit-circle"
            onClick={() => setPhotoModalShowing("coverPhoto")}
            style={{ margin: "10px" }}
          >
            <FontAwesomeIcon icon="edit" style={{ fontSize: "16px" }} />
          </ClickableElement>
        ) : null}
        <div className="guide-header-chin">
          <div className="column-gap-20px">
            <div>
              <AvatarCircle
                name={name}
                avatarColor={avatarColor}
                userAccountId={userAccountId}
                profilePicture={profilePicture}
                hasAddPictureIcon={hasEditAccess}
                onAddPicture={() => setPhotoModalShowing("profilePicture")}
                size="extra-large"
              />
            </div>
            <div className="profile-information">
              <div>
                <h1>{name}</h1>
                <div className="header-description">
                  <AutomaticTeamPopover
                    children={
                      isAutomatic ? (
                        <span className="automatic-tag">Automatic Team</span>
                      ) : null
                    }
                    isAdmin={isAdmin}
                  />
                  {totalMembersText ? (
                    <span style={{ fontSize: "14px" }}>{totalMembersText}</span>
                  ) : null}
                  {totalMembersText && headerDescription && (
                    <FontAwesomeIcon
                      icon="circle"
                      className="mx-2"
                      style={{ fontSize: "4px" }}
                    />
                  )}
                  <span style={{ height: "21.5px" }}>
                    {headerDescription || null}
                  </span>
                </div>
              </div>

              {hasEditAccess && onEditClickHandler ? (
                <div
                  className="edit-circle"
                  role="button"
                  onClick={() => onEditClickHandler()}
                  style={{ margin: "10px" }}
                >
                  <FontAwesomeIcon icon="edit" style={{ fontSize: "16px" }} />
                </div>
              ) : null}
            </div>
            {getTabs()}
          </div>

          <div className="engagement-buttons">
            {onInvite ? (
              <Button
                variant="secondary-blue"
                className="me-2"
                onClick={() => onInvite()}
              >
                <FontAwesomeIcon icon="user-plus" className="me-1" /> Invite
              </Button>
            ) : null}
            {/* <Button variant="outline-primary">
              <FontAwesomeIcon icon="share" className="me-1" /> Share
            </Button> */}
          </div>
        </div>
      </Card>
    );
  }
);

export default Header;
