import Button from "app/storybookComponents/Button";
import { Card, Collapse } from "react-bootstrap";
import { TeamReflectionSummary } from "app/containers/AdminConsole/types";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DynamicStackBarChart from "app/storybookComponents/Charts/DynamicStackBarChart";

interface Props {
  title?: string;
  teamReflectionSummary: TeamReflectionSummary | null;
  entityType?: "department" | "organization" | "team";
  isCardCollapsed?: boolean;
  isCollapseByDefault?: boolean;
  isCollapsed?: boolean;
}

export default function TeamReflectionCard({
  title = "What Else Did Your Team Say?",
  teamReflectionSummary,
  isCollapseByDefault = false,
}: Props) {
  // -------------------------- States --------------------------
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(isCollapseByDefault);

  if (!teamReflectionSummary) return null;

  const KEY_MAP = {
    teamEffectivenessRating: {
      questionAsked: "How would you rate the overall performance of this team?",
      values: {
        "Very Poor": {
          color: "#FAE5EE",
          label: "Very Poor",
          labelColor: "#d50057",
        },
        Poor: {
          color: "#FFDED7",
          label: "Poor",
          labelColor: "#ff5c39",
        },
        Acceptable: {
          color: "#FFF7CC",
          label: "Acceptable",
          labelColor: "#ffd600",
        },
        Good: {
          color: "#CCF2E1",
          label: "Good",
          labelColor: "#00c067",
        },
        Excellent: {
          color: "#c6ceee",
          label: "Very Good",
          labelColor: "#425cc7",
        },
      },
    },
    likelihoodToLeaveThisTeam: {
      questionAsked:
        "How likely are you to look for chances to leave this team in the next 6-12 months?",
      values: {
        "Very Likely": {
          color: "#FAE5EE",
          label: "Very Likely",
          labelColor: "#d50057",
        },
        Likely: {
          color: "#FFDED7",
          label: "Likely",
          labelColor: "#ff5c39",
        },
        Neutral: {
          color: "#FFF7CC",
          label: "Neutral",
          labelColor: "#ffd600",
        },
        Unlikely: {
          color: "#CCF2E1",
          label: "Unlikely",
          labelColor: "#00c067",
        },
        "Very Unlikely": {
          color: "#c6ceee",
          label: "Very Unlikely",
          labelColor: "#425cc7",
        },
      },
    },
    primaryWorkStyle: {
      questionAsked: "How do you primarily work on this team?",
      values: {
        "Fully In-Person": {
          color: "#D5F5F6",
          label: "Fully In-Person",
          labelColor: "#2dccd3",
        },
        "Mostly In-Person": {
          color: "#FFEDCC",
          label: "Mostly In-Person",
          labelColor: "#ffa300",
        },
        Hybrid: {
          color: "#D9DEF4",
          label: "Hybrid",
          labelColor: "#425cc7",
        },
        "Mostly Remote": {
          color: "#FFDED7",
          label: "Mostly Remote",
          labelColor: "#ff5c39",
        },
        "Fully Remote": {
          color: "#CCD2DC",
          label: "Fully Remote",
          labelColor: "#001f4e",
        },
      },
    },
  } as {
    [key: string]: {
      questionAsked: string;
      values: {
        [key: string]: {
          color: string;
          label: string;
          labelColor: string;
        };
      };
    };
  };

  const getStepKey = () => {
    switch (selectedQuestion) {
      case 0:
        return "teamEffectivenessRating";
      case 1:
        return "likelihoodToLeaveThisTeam";
      case 2:
        return "primaryWorkStyle";
      default:
        return "teamEffectivenessRating";
    }
  };

  const stepKey = getStepKey();
  const getBarsAndKey = () => {
    const step = teamReflectionSummary[stepKey]?.answerDetails;
    let bars: {
      value: number;
      color: string;
      borderColor?: string;
    }[] = [];

    let keys: React.JSX.Element[] = [];

    Object.values(KEY_MAP[stepKey].values).forEach((stepValue) => {
      const value = step?.[stepValue.label]?.count || 0;
      const percentage = step?.[stepValue.label]?.percentage || 0;
      const color = stepValue.color;
      const labelColor = stepValue.labelColor;

      bars.push({
        value,
        color,
        borderColor: labelColor,
      });

      keys.push(
        <div key={stepValue.label}>
          <FontAwesomeIcon
            icon="circle"
            style={{ fontSize: "8px", color: labelColor }}
            className="mt-1"
          />
          <div>
            <h4 className="breakdown-key">{stepValue.label}</h4>
            <p>{percentage}% of team members</p>
          </div>
        </div>
      );
    });

    return { bars, keys };
  };

  const getQuestionAsked = () => {
    const stepKey = getStepKey();
    return KEY_MAP[stepKey]?.questionAsked;
  };

  const { bars, keys } = getBarsAndKey();
  const teamReflectionInsightText =
    teamReflectionSummary[stepKey]?.teamReflectionInsightText;

  return (
    <Card
      style={{ padding: "20px" }}
      className="column-gap-20px"
      role={isCollapsed ? "button" : undefined}
      onClick={() => {
        if (!isCollapsed) return;
        setIsCollapsed(false);
      }}
    >
      <div className="d-flex justify-content-between align-items-start">
        <div className="column-gap-8px">
          <h2>{title}</h2>
        </div>
        <div>
          <Button
            onClick={() => {
              setIsCollapsed(!isCollapsed);
            }}
            variant="secondary-blue"
            className="border-0"
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {isCollapsed ? "Expand" : "Collapse"}
            <FontAwesomeIcon
              icon={`caret-${isCollapsed ? "down" : "up"}`}
              className="ms-2"
            />
          </Button>
        </div>
      </div>
      <Collapse in={!isCollapsed}>
        <div>
          <p>
            <b>Question asked: {getQuestionAsked()}</b>
          </p>
          {teamReflectionInsightText ? (
            <p>{teamReflectionInsightText}</p>
          ) : null}
        </div>
      </Collapse>
      <Collapse in={!isCollapsed}>
        <div className="column-gap-20px">
          <DynamicStackBarChart bars={bars} />
          <div className="summary-chart-key team-reflection">{keys}</div>
          <hr className="m-0" />
          <div className="d-flex justify-content-between align-items-center">
            <div className="row-gap-8px">
              {selectedQuestion !== 0 ? (
                <Button
                  variant="secondary-blue"
                  onClick={() => setSelectedQuestion(selectedQuestion - 1)}
                >
                  Back
                </Button>
              ) : null}
              {selectedQuestion < 2 ? (
                <Button
                  variant="secondary-blue"
                  onClick={() => setSelectedQuestion(selectedQuestion + 1)}
                >
                  Next question
                  <FontAwesomeIcon icon="arrow-right" className="ms-2" />
                </Button>
              ) : null}
            </div>
            <p style={{ color: "#53565a" }}>
              <b>Question {selectedQuestion + 1} of 3</b> from the reflection
              portion of the survey
            </p>
          </div>
        </div>
      </Collapse>
    </Card>
  );
}
