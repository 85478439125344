import { selectAllCompanyUsersById } from "app/containers/Global/slice";
import SimpleModal from "./SimpleModal";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import Select from "react-select";
import { useCallback, useMemo, useState } from "react";
import Button from "app/storybookComponents/Button";
import { UserInfo } from "app/containers/Global/types";
import { Form } from "react-bootstrap";
import { getSelectProps } from "utils/helperFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendReminder } from "app/containers/Dashboard/slice";
import MultiCardCollapsibleContainer from "app/storybookComponents/CollapsibleCards/MultiCardCollapsibleContainer";
import { inviteCreatedUser } from "app/containers/AdminConsole/slice";

interface Props {
  onHide: () => void;
  show?: boolean;
}

export default function PendingUsersModal({ onHide, show }: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const [remindedMembers, setRemindedMembers] = useState<Set<number>>(
    new Set()
  );

  const [searchInput, setSearchInput] = useState("");

  const getPendingElementButton = useCallback(
    (user: UserInfo) => {
      const userAccountId = user.userAccountId;
      if (!user.tmgInvited) {
        return (
          <Button
            className="nowrap"
            variant="secondary-blue"
            onClick={() => {
              dispatch(inviteCreatedUser(userAccountId));
            }}
            style={{ width: "72px" }}
          >
            Invite
          </Button>
        );
      }
      const isReminded = remindedMembers.has(userAccountId);
      if (isReminded) {
        return <span className="label-tag grey nowrap">Reminder sent</span>;
      }

      return (
        <Button
          className="nowrap"
          variant="secondary-blue"
          onClick={() => {
            setRemindedMembers((prevIds) =>
              new Set(prevIds).add(userAccountId)
            );
            dispatch(
              sendReminder({
                userAccountId,
                reminderType: "invitationReminder",
              })
            );
          }}
          style={{ width: "72px" }}
        >
          Remind
        </Button>
      );
    },
    [dispatch, remindedMembers]
  );

  const getPendingElement = useCallback(
    (user: UserInfo) => {
      const { emailAddress, userAccountId, tmgInvited } = user;
      const emailTag = <span className="name">{emailAddress}</span>;
      const statusText = tmgInvited ? (
        <span className="pending-member-row">Invite sent to: {emailTag}</span>
      ) : (
        <span className="pending-member-row">Added: {emailTag}</span>
      );

      return (
        <div className="section-body-row" key={userAccountId}>
          <div
            className="d-flex gap-2 align-items-center"
            style={{ color: "#53565a" }}
          >
            <div
              style={{
                width: "30px",
                textAlign: "center",
                fontSize: "24px",
              }}
            >
              <FontAwesomeIcon icon={["fal", "clock"]} />
            </div>
            <div className="section-body-name-and-description">
              <span className="section-body-row-name">{statusText}</span>
            </div>
          </div>
          <div className="d-flex gap-2 align-items-center">
            {getPendingElementButton(user)}
          </div>
        </div>
      );
    },
    [getPendingElementButton]
  );

  const pendingElements = useMemo(() => {
    const pendingElements: JSX.Element[] = [];

    // We will iterate through all of the users and if the user has the firstTimeLogin as null or falsy then we will add them to the pendingElements array
    Object.values(usersById).forEach((user) => {
      if (user.firstTeamsLogin) {
        return;
      }
      // If no search input no need to check if the user's name or email address includes the search input
      if (!searchInput) {
        return pendingElements.push(getPendingElement(user));
      }

      // Check if the user's name or email address includes the search input
      const { firstName, lastName, emailAddress } = user;
      const name = `${firstName} ${lastName}`.trim();
      if (
        name.toLowerCase().includes(searchInput.toLowerCase()) ||
        emailAddress.toLowerCase().includes(searchInput.toLowerCase())
      ) {
        pendingElements.push(getPendingElement(user));
      }
    });

    return pendingElements;
  }, [usersById, getPendingElement, searchInput]);

  const getEmptyCard = () => {
    if (pendingElements.length === 0) {
      return (
        <div
          className="empty-card"
          style={{
            padding: "32px",
          }}
        >
          <div className="column-gap-12px">
            <span>
              <b>
                {searchInput
                  ? `No pending user found matching "${searchInput}"`
                  : `No pending users at this time.`}
              </b>
            </span>
            <p>
              {searchInput
                ? "Try searching by another user name or email address"
                : null}
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  const { selectStyles, components } = getSelectProps("search");
  return (
    <SimpleModal title="Pending Users" show={show} onHide={onHide}>
      <Form.Group className="w-100">
        <Select
          placeholder={"Search by name or email..."}
          isClearable={true}
          isSearchable={true}
          components={components}
          inputValue={searchInput}
          styles={selectStyles}
          menuIsOpen={false}
          onInputChange={(e, actionMeta) => {
            if (actionMeta.action === "input-change") {
              setSearchInput(e);
            }
          }}
        />
      </Form.Group>
      <MultiCardCollapsibleContainer
        items={pendingElements}
        collapsedText={"See all pending members"}
      />
      {getEmptyCard()}
    </SimpleModal>
  );
}
