import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Team360AssessmentResult,
  TTeam360Factor,
  Team360StoredResponses,
  SurveyKeepAssessmentOpenOption,
  AgreeablenessResult,
  AgreementState,
} from "./types";
import { getEntries, getPeopleOrPerson } from "utils/helperFunctions";
import TeamAssessmentIcon from "app/components/Team360Assessment/TeamAssessmentIcon";
import { TEAM_360_DIMENSION_DESCRIPTIONS } from "./constants";
import { Team } from "app/containers/Global/types";
import { Department } from "app/containers/AdminConsole/types";
import { OverlayTrigger, Popover } from "react-bootstrap";

export const getVerbalTag = (score?: number): React.JSX.Element | null => {
  if (score === undefined) return null;
  if (score > 80) {
    return <span className="verbal-tag super">Superpower</span>;
  } else if (score > 60) {
    return <span className="verbal-tag high">Strength</span>;
  } else if (score > 40) {
    return <span className="verbal-tag medium">Building Block</span>;
  } else if (score > 20) {
    return <span className="verbal-tag low">Development Area</span>;
  } else {
    return <span className="verbal-tag lowest">Limiting Factor</span>;
  }
};

export const getTopOrButtonFactors = (
  onFactorClick: (factor: TTeam360Factor, highOrLow?: "high" | "low") => void,
  factor?: TTeam360Factor,
  highOrLow?: "high" | "low"
): React.JSX.Element | null => {
  if (!factor) return null;
  return (
    <div
      className="top-and-bottom-factors-button"
      role="button"
      onClick={() => {
        onFactorClick(factor, highOrLow);
      }}
    >
      <div className="assessment-icon-holder">
        {factor ? <TeamAssessmentIcon name={factor} color="navy" /> : null}
      </div>
      <span>{factor}</span>
      <FontAwesomeIcon icon="arrow-right" className="ms-2" />
    </div>
  );
};

export const getHighAndLowFactors = (myScore?: Team360AssessmentResult) => {
  const returnVal: {
    low?: TTeam360Factor;
    high?: TTeam360Factor;
  } = {};
  if (myScore?.factors) {
    getEntries(myScore.factors).forEach(([factor, score]) => {
      if (!returnVal.low || score < (myScore.factors?.[returnVal.low] ?? 0)) {
        returnVal.low = factor as TTeam360Factor;
      }
      if (!returnVal.high || score > (myScore.factors?.[returnVal.high] ?? 0)) {
        returnVal.high = factor as TTeam360Factor;
      }
    });
  }
  return returnVal;
};

export const constructPayload = (
  rawData: Team360StoredResponses
): {
  [itemId: number]: { answer: number; timeTaken: number };
} => {
  const payload: {
    [itemId: number]: { answer: number; timeTaken: number };
  } = {};
  getEntries(rawData).forEach(([factor, responses]) => {
    getEntries(responses).forEach(([itemId, response]) => {
      payload[itemId] = {
        answer: response.answer,
        timeTaken: response.timeTaken,
      };
    });
  });
  return payload;
};

export const getTeam360EndDate = (
  startDate: Date,
  openForTime: SurveyKeepAssessmentOpenOption
): Date => {
  const oneDay = 24 * 60 * 60 * 1000;
  switch (openForTime) {
    case "1 day":
      return new Date(startDate.getTime() + oneDay);
    case "1 week":
      return new Date(startDate.getTime() + 7 * oneDay);
    case "2 weeks":
      return new Date(startDate.getTime() + 14 * oneDay);
    case "1 month":
      return new Date(startDate.getTime() + 30 * oneDay);
  }
};

export const getTeam360KeepOpenForText = (
  openForTime: SurveyKeepAssessmentOpenOption
): string => {
  switch (openForTime) {
    case "1 day":
      return "one day";
    case "1 week":
      return "one week";
    case "2 weeks":
      return "two weeks";
    case "1 month":
      return "one month";
  }
};

export const getTeam360EndDateForRepeatingEvent = (
  frequency: string,
  startDate: Date | string
) => {
  if (!startDate) return null;

  let copyDate = startDate;
  if (typeof copyDate === "string") {
    copyDate = new Date(copyDate);
  }
  const now = new Date();

  const oneDay = 24 * 60 * 60 * 1000;
  switch (frequency) {
    case "daily":
      return new Date(now.getTime() + oneDay);
    case "weekly": {
      // If weekly, then we check the day of the week and add 7 days
      const dayOfWeek = copyDate.getDay();
      const currentDayOfWeek = now.getDay();
      const diffDays = (dayOfWeek - currentDayOfWeek + 7) % 7;
      now.setDate(now.getDate() + diffDays);
      return now;
    }
    case "monthly": {
      // If monthly, then we check the day of the month and add a month
      const dayOfMonth = copyDate.getDate();
      const currentMonth = now.getMonth();
      const nextMonth = (currentMonth + 1) % 12;
      const currentYear = now.getFullYear();
      return new Date(currentYear, nextMonth, dayOfMonth);
    }
    case "quarterly": {
      const currentDate = new Date();
      const currentQuarter = Math.ceil((currentDate.getMonth() + 1) / 3);
      const dueDate = new Date(
        currentDate.getFullYear(),
        (currentQuarter - 1) * 3,
        1
      );
      if (currentDate > dueDate) {
        dueDate.setMonth(dueDate.getMonth() + 3);
      }
      return dueDate;
    }
    case "annually": {
      copyDate.setFullYear(copyDate.getFullYear() + 1);
      // If the start date plus a year is greater than today then use that as the due date
      if (copyDate > now) {
        return copyDate;
      }

      // If the start date month and day is less than today then we need to add a year
      return new Date(
        now.getFullYear() + now.getMonth() < copyDate.getMonth() &&
        now.getDate() < copyDate.getDate()
          ? 0
          : 1,
        copyDate.getMonth(),
        copyDate.getDate()
      );
    }
    default:
      return null;
  }
};

const getTotalInvitesWarningIcon = (
  peopleInvited: number = 0,
  totalSurveySentCount: number = 0
) => {
  // if people invited is the same or greater than the total survey sent count return null
  if (peopleInvited >= totalSurveySentCount) {
    return null;
  }
  const overlay = (
    <Popover className="team-360-popover">
      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <strong>
          Why is the number for “total survey invites” greater than “people
          invited”?
        </strong>
        <p>
          Total survey invites is the number of TEAMscan invitations sent to all
          people in your organization. People belonging to multiple teams that
          are included in the TEAMscan instance will receive a survey invitation
          for each of those teams, meaning a single person may receive more than
          one invitation. If an individual receives multiple invitations to take
          the TEAMscan during the aggregation period—whether for different teams
          or repeated surveys—each invitation will contribute to the overall
          count of 'total survey invites’.
        </p>
      </div>
    </Popover>
  );

  return (
    <OverlayTrigger rootClose placement="bottom" overlay={overlay}>
      <FontAwesomeIcon
        icon={["far", "exclamation-triangle"]}
        className="ms-2"
      />
    </OverlayTrigger>
  );
};

export const getCompletedTeam360AssessmentText = ({
  completionRate = 0,
  totalInvited = 0,
  totalCompleted = 0,
  totalPeopleInvited = 0,
}: {
  completionRate?: number;
  totalInvited?: number;
  totalCompleted?: number;
  totalPeopleInvited?: number;
}) => (
  <div className="team-360-completion-rate">
    <div className="team-360-completion-rate__info-box">
      <span className="count">{totalPeopleInvited}</span>
      <span className="label">
        {getPeopleOrPerson(totalPeopleInvited)} invited
      </span>
    </div>
    <div className="vertical-line" />
    <div className="team-360-completion-rate__info-box">
      <div>
        <span className="count">
          {totalCompleted}/{totalInvited}
        </span>
        {getTotalInvitesWarningIcon(totalPeopleInvited, totalInvited)}
      </div>
      <span className="label">completed surveys / total survey invites</span>
    </div>
    <div className="vertical-line" />
    <div className="team-360-completion-rate__info-box">
      <span className="count">{completionRate}%</span>
      <span className="label">completion rate</span>
    </div>
  </div>
);

export const getTeam360Score = (score: number) => {
  // Previous logic
  // return score * 20;
  // New logic
  const returnVal = (score - 1) * 25;

  if (returnVal < 1) {
    // 1 is the lowest score
    return 1;
  } else if (returnVal > 100) {
    // 100 is the highest score
    return 100;
  } else {
    return Math.round(returnVal);
  }
};

export const getTeam360FactorOrDimensionDescription = (type: string) => {
  if (TEAM_360_DIMENSION_DESCRIPTIONS[type]) {
    return TEAM_360_DIMENSION_DESCRIPTIONS[type];
  }

  switch (type) {
    case "Target":
      return "The Target factor involves a team's capability to define and align around clear, impactful, and achievable goals.";
    case "Empower":
      return "The Empower factor reflects a team's ability to create an environment where every member feels safe, supported, and motivated.";
    case "Align":
      return "Align refers to a team's effectiveness in maintaining clear communication and roles, managing conflict, making good decisions and fostering mutual understanding among team members.";
    case "Monitor":
    default:
      return "Monitor refers to a team’s effectiveness in tracking goal progress, team states, and the internal and external environment for threats and opportunities.";
  }
};

// We will iterate through all of the dimensions (That means we need to input the constants) and sort them by highest to lowest and split them into 3 evenly sections.
// Options on how to split the dimensions by 'low', 'medium', and 'high' agreement: (Given that the incoming array is already sorted)
// 1.) Evenly split the array into three sections.
// 2.) We will have a set range for each sections so that we have the possiblity that all of the dimensions could be all low or all high
//    a.) If users have a difference of more than 50 points, then that will be LOW AGREEMENT
//    b.) If users have a difference of less than 50 points, but more than 10 points, then that will be MEDIUM AGREEMENT
//    c.) if users have a difference of less than 10 points then will be HIGH AGREEMENT
// Might offload this to have the backend to do this for us.
export const getSplitDimensionByAgreement = (
  agreeablenessResults: AgreeablenessResult[]
): AgreementState => {
  const initialState: AgreementState = {
    "Low Agreement": [],
    "Medium Agreement": [],
    "High Agreement": [],
  };
  agreeablenessResults
    .sort((a, b) => {
      const differenceA = Math.abs(a.comparedScore - a.userScore);
      const differenceB = Math.abs(b.comparedScore - b.userScore);
      return differenceB - differenceA;
    })
    .forEach((agreeablenessResult) => {
      const difference = Math.abs(
        agreeablenessResult.comparedScore - agreeablenessResult.userScore
      );
      if (difference > 30) {
        initialState["Low Agreement"]?.push(agreeablenessResult);
      } else if (difference > 10) {
        initialState["Medium Agreement"]?.push(agreeablenessResult);
      } else {
        initialState["High Agreement"]?.push(agreeablenessResult);
      }
    });

  return initialState;
};

export const getAgreementTagColor = (value: string) => {
  switch (value) {
    case "High Agreement":
      return "green";
    case "Medium Agreement":
      return "yellow";
    case "Low Agreement":
    default:
      return "red";
  }
};

export const getMainAccordionValue = (
  teamValue?: number,
  userValue?: number,
  departmentValue?: number,
  companyValue?: number
) => (teamValue || userValue || departmentValue || companyValue) ?? 0;

// This function should return back an array of team leaders for a given departmentId
export const getTeamLeadersFromDepartmentId = (
  departmentId: number,
  teamsById: { [teamId: string | number]: Team },
  departmentsById: { [departmentId: string | number]: Department }
): {
  userAccountId: number;
  teamId: number;
}[] => {
  // We will iterate through all the teams in the department then if that team has a team leader, then we will add that team leader to the array
  const teamIds = departmentsById[departmentId]?.teams ?? [];
  const teamLeaders: {
    userAccountId: number;
    teamId: number;
  }[] = [];

  teamIds.forEach((teamId) => {
    const teamLeadIds = teamsById[teamId]?.teamLeadUserAccountIds ?? [];
    if (!teamLeadIds?.length) {
      return;
    }
    teamLeadIds.forEach((id) => {
      teamLeaders.push({
        userAccountId: id,
        teamId,
      });
    });
  });

  return teamLeaders;
};

export const getHowEntityComparesStarValue = (teamValue?: string | null) => {
  switch (teamValue) {
    case "high-performing":
      return "high";
    case "healthy":
      return "medium";
    case "needs-help":
      return "low";
    default:
      return undefined;
  }
};
