import { MemberScore, WhatTeamMembersBringToThisTeam } from "./types";
import { Card, Dropdown } from "react-bootstrap";
import SectionHeader from "./SectionHeader";
import { useState, useEffect } from "react";
import EmptyTeamPersonalityIllustration from "resources/images/team-personality-empty.jpg";
import PersonalitySimilaritySection from "./PersonalitySimilaritySection";

interface Props {
  readonly teamMembers: MemberScore[];
  readonly whatTeamMembersBringToThisTeam: WhatTeamMembersBringToThisTeam;
}

export default function WhatTeamMembersBringToThisTeamReport({
  teamMembers,
  whatTeamMembersBringToThisTeam,
}: Props) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selectedUserAccountId, setSelectedUserAccountId] = useState<
    number | null
  >(null);

  const toggleIsCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (!selectedUserAccountId && teamMembers.length > 0) {
      setSelectedUserAccountId(teamMembers[0].userAccountId);
    }
  }, [teamMembers, selectedUserAccountId]);

  if (teamMembers.length === 0) {
    return null;
  }

  if (isCollapsed) {
    return (
      <Card>
        <SectionHeader
          toggleIsCollapsed={toggleIsCollapsed}
          title="What Team Members Bring To This Team"
          isCollapsed={isCollapsed}
        />
      </Card>
    );
  }

  if (teamMembers.length < 2) {
    return (
      <Card>
        <SectionHeader
          toggleIsCollapsed={toggleIsCollapsed}
          title="What Team Members Bring To This Team"
          isCollapsed={isCollapsed}
        />
        <div className="column-gap-20px align-items-center">
          <img
            src={EmptyTeamPersonalityIllustration}
            alt="empty team average illustration"
            style={{
              width: "200px",
              height: "200px",
            }}
          />
          <p>
            Once at least two of your team members have taken their personality
            assessment, their results will appear here.
          </p>
        </div>
      </Card>
    );
  }

  const selectedTeamMember = teamMembers.find(
    (teamMember) => teamMember.userAccountId === selectedUserAccountId
  );

  if (!selectedTeamMember || !selectedUserAccountId) {
    return null;
  }

  const bringsToThisTeam =
    whatTeamMembersBringToThisTeam[selectedUserAccountId];

  const getTagColor = (score: number) => {
    if (score > 90) {
      return "blue";
    } else if (score > 80) {
      return "green";
    } else if (score > 70) {
      return "yellow";
    } else if (score > 60) {
      return "orange";
    } else {
      return "red";
    }
  };

  const totalSimilarityTagClass = `${getTagColor(
    bringsToThisTeam.totalSimilarityScore
  )} label-tag m-0`;

  let teamMemberCount = 0;

  return (
    <Card>
      <SectionHeader
        toggleIsCollapsed={toggleIsCollapsed}
        title="What Team Members Bring To This Team"
        isCollapsed={isCollapsed}
      />
      <Dropdown>
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          {selectedTeamMember.firstName} {selectedTeamMember.lastName}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {teamMembers.map((teamMember) => {
            teamMemberCount += 1;
            return (
              <Dropdown.Item
                onClick={() => {
                  setSelectedUserAccountId(teamMember.userAccountId);
                }}
                key={teamMemberCount}
              >
                {teamMember.firstName} {teamMember.lastName}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
      <div
        style={{ marginBottom: "10px", color: "#53565A", fontWeight: "bold" }}
      >
        {selectedTeamMember.firstName}'s similarity score to this team:{" "}
        <span className={totalSimilarityTagClass}>
          {Math.round(bringsToThisTeam.totalSimilarityScore)}%
        </span>
      </div>
      <div className="responsive-two-column-container">
        <PersonalitySimilaritySection
          leftStripColor="cyan"
          title="Attitudes & Outlook"
          similarityScore={bringsToThisTeam.attitudesAndOutlook}
          tagColor={getTagColor(
            bringsToThisTeam.attitudesAndOutlook.similarityScore
          )}
        />
        <PersonalitySimilaritySection
          leftStripColor="dark-blue"
          title="Work Habits"
          similarityScore={bringsToThisTeam.workHabits}
          tagColor={getTagColor(bringsToThisTeam.workHabits.similarityScore)}
        />

        <PersonalitySimilaritySection
          leftStripColor="poppy"
          title="Temperament"
          similarityScore={bringsToThisTeam.temperament}
          tagColor={getTagColor(bringsToThisTeam.temperament.similarityScore)}
        />
        <PersonalitySimilaritySection
          leftStripColor="orange"
          title="Interaction Style"
          similarityScore={bringsToThisTeam.interactionStyle}
          tagColor={getTagColor(
            bringsToThisTeam.interactionStyle.similarityScore
          )}
        />
      </div>
    </Card>
  );
}
