import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SortableTable from "app/components/SortableTable";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "app/storybookComponents/Button";
import { selectAllCompanyUsersById } from "app/containers/Global/slice";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { useEffect, useState } from "react";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import { openEditDepartmentModal } from "app/components/Modals/slice";

import {
  deleteDepartmentById,
  selectDeleteDepartmentStatus,
  selectDepartmentTypes,
  selectGetDepartmentsStatus,
  selectDepartments,
} from "../slice";
import Loading from "app/storybookComponents/Loading";
import { getOrganizationDepartments } from "../helpers";

interface Props {
  filteredDepartmentIds?: number[];
  searchTerm?: string;
  isLoading?: boolean; // This should be true if we are loading the departments or if the search
}

export default function DepartmentTable({
  filteredDepartmentIds,
  searchTerm,
  isLoading,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();

  // ------------------------- App selectors ----------------------------------//
  const departments = useAppSelector(selectDepartments);
  const departmentTypes = useAppSelector(selectDepartmentTypes); // Department Types is different in that we use department types for analytics while departments are what the user sees.
  const usersInfoById = useAppSelector(selectAllCompanyUsersById);
  const deleteDepartmentStatus = useAppSelector(selectDeleteDepartmentStatus);
  const gettingDepartmentStatus = useAppSelector(selectGetDepartmentsStatus);

  // ------------------------- States ----------------------------------//
  const [showDeleteDepartmentById, setShowDeleteDepartmentById] = useState<
    false | number
  >(false);

  // ------------------------- Effects ----------------------------------//
  useEffect(() => {
    if (deleteDepartmentStatus === "succeeded") {
      setShowDeleteDepartmentById(false);
    }
  }, [deleteDepartmentStatus]);

  // ------------------------- Local variables ----------------------------------//
  const organizationDepartments = getOrganizationDepartments(departments);
  const departmentIds =
    filteredDepartmentIds ?? Object.keys(organizationDepartments);
  const totalDepartmentInOrganization = Object.keys(
    organizationDepartments
  ).length;

  // ------------------------- Functions ----------------------------------//
  const onDepartmentTableDropdownSelect = (e: string | null, id: number) => {
    switch (e) {
      case "editDepartmentInformation": {
        dispatch(openEditDepartmentModal({ departmentId: id }));
        return;
      }
      case "deleteDepartment": {
        return setShowDeleteDepartmentById(id);
      }
    }
  };

  const getNumberOfX = (
    departmentId: number,
    X: "People" | "Teams" = "People",
    numberOfX?: number
  ) => {
    // If no numberOfTeams then return 0
    if (!numberOfX) return 0;
    return {
      displayValue: (
        <Link to={`/Search/${X}?departmentId=${departmentId}`}>
          {numberOfX}
        </Link>
      ),
      sortValue: numberOfX,
    };
    // Else return the number of teams as the sort value and the display value be the link to the search page with the departmentId filter as the departmentId
  };

  const getActionDropdown = (departmentId: number) => (
    <Dropdown
      style={{ marginLeft: "auto" }}
      onSelect={(e) => onDepartmentTableDropdownSelect(e, Number(departmentId))}
    >
      <Dropdown.Toggle
        variant="outline-primary"
        id="dropdown-basic"
        className="no-caret"
      >
        <FontAwesomeIcon icon="ellipsis" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="editDepartmentInformation">
          Edit department information
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="deleteDepartment" className="danger">
          Delete department
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const rows = departmentIds.map((departmentId) => {
    const { name, leader, teamMembers, teams, departmentTypeId } =
      departments[Number(departmentId)];

    let leaderName = "";
    let leaderEmail = "";
    const leaderId =
      typeof leader === "number" ? leader : leader?.userAccountId;
    if (leaderId) {
      // First we should check if we have the data inside of usersInfoById, if so we can use that
      const user = usersInfoById[leaderId] || leader;
      leaderName = `${user.firstName || ""} ${user.lastName || ""}`;
      leaderEmail = user.emailAddress;
    }

    return {
      name: {
        displayValue: (
          <Link to={`/DepartmentGuide/${departmentId}`}>{name}</Link>
        ),
        sortValue: name ?? "",
      },
      type:
        departmentTypeId && departmentTypes[departmentTypeId]
          ? departmentTypes[departmentTypeId]?.name
          : "",
      numberOfTeams: getNumberOfX(Number(departmentId), "Teams", teams?.length),
      numberOfMembers: getNumberOfX(
        Number(departmentId),
        "People",
        teamMembers?.length
      ),
      leaderName: {
        displayValue: <Link to={`/UserGuide/${leaderId}`}>{leaderName}</Link>,
        sortValue: leaderName,
      },
      leaderEmail: {
        displayValue: (
          <div className="invitee-cell">
            <p>{leaderEmail}</p>
            {getActionDropdown(Number(departmentId))}
          </div>
        ),
        sortValue: leaderEmail,
      },
    };
  });

  const getEmptyCard = () => {
    if (totalDepartmentInOrganization === 0) {
      return (
        <div className="empty-card">
          <div className="column-gap-12px align-items-center">
            <span>
              <b>No departments have been created yet.</b>
            </span>
            <p>Departments created will be shown here</p>
            <Button onClick={() => dispatch(openEditDepartmentModal())}>
              <FontAwesomeIcon icon="plus" className="me-2" /> Create a
              department
            </Button>
          </div>
        </div>
      );
    }

    // if the length of rows is 0 then return the empty card
    if (rows.length === 0) {
      return (
        <div className="empty-card">
          <div className="column-gap-12px">
            <span>
              <b>{`No department found matching "${searchTerm}"`}</b>
            </span>
            <p>Try searching by another department name</p>
          </div>
        </div>
      );
    }

    return null;
  };

  if (isLoading || gettingDepartmentStatus === "loading") {
    return <Loading />;
  }

  return (
    <>
      <WarningModal
        modalTitle="Delete Department"
        warningTitle="Are you sure you want to delete this department?"
        warningMessage="Department will no longer be able to access admin settings, change access permissions, and configure any global organization settings."
        isOpen={showDeleteDepartmentById !== false}
        onConfirmClick={() => {
          if (typeof showDeleteDepartmentById !== "number") return;
          dispatch(deleteDepartmentById(showDeleteDepartmentById));
        }}
        hideModal={() => setShowDeleteDepartmentById(false)}
        isInProgress={deleteDepartmentStatus === "loading"}
      />
      <div className="admin-console-table-container">
        <SortableTable
          rows={rows}
          tableClassName="admin-console-table"
          columnHeaders={[
            {
              key: "name",
              label: "Department Name",
            },
            {
              key: "type",
              label: "Type",
            },
            {
              key: "numberOfTeams",
              label: "# of Teams",
              sortInverse: true,
            },
            {
              key: "numberOfMembers",
              label: "# of Members",
              sortInverse: true,
            },
            {
              key: "leaderName",
              label: "Leader",
            },
            {
              key: "leaderEmail",
              label: "Leader Email",
            },
          ]}
        />
      </div>
      {getEmptyCard()}
    </>
  );
}
