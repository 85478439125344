interface Props {
  headerTitle?: string;
  headerDescription?: string;
  bannerTitle: string;
  bannerDescription: string;
  iconImageSrc: string;
  iconImageAlt: string;
  actionButton?: React.ReactNode;
  additionalHeaderContent?: React.ReactNode;
}

export default function AdminTablesHeader({
  headerTitle,
  headerDescription,
  bannerTitle,
  actionButton,
  iconImageSrc,
  iconImageAlt,
  bannerDescription,
  additionalHeaderContent,
}: Props) {
  const getHeader = () => {
    if (!headerTitle && !headerDescription) return null;
    return (
      <div className="column-gap-8px">
        {headerTitle ? <h2>{headerTitle}</h2> : null}
        {headerDescription ? <p>{headerDescription}</p> : null}
        {additionalHeaderContent}
      </div>
    );
  };
  return (
    <div className="column-gap-16px">
      {getHeader()}
      <div className="admin-banner">
        <div className="column-gap-12px">
          <div className="column-gap-4px">
            <h3>{bannerTitle}</h3>
            <p>{bannerDescription}</p>
          </div>
          <div>{actionButton}</div>
        </div>
        <div>
          <img
            src={iconImageSrc}
            alt={iconImageAlt}
            style={{
              width: "100px",
              height: "100px",
            }}
          />
        </div>
      </div>
    </div>
  );
}
