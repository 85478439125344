export interface InviteMemberInfoText {
  modalDescription?: string;
  modalDescriptionForWhenNotSendingEmails?: string;
  inviteMemberInfoMessage?: string;
  addMemberInfoMessage?: string;
}

const addMemberInfoMessage =
  "Simply type in the email addresses of the people you want to add.";
const modalDescriptionForWhenNotSendingEmails =
  "By adding users, you're bringing your team members into the system, enabling them to join teams, take part in TEAMscan surveys, and receive personalized insights and reports.";

export const INVITE_PEOPLE_INTO_ORGANIZATION_TEXT: InviteMemberInfoText = {
  modalDescription:
    "By adding people, you're enabling them to join teams, take part in TEAMscan surveys, and receive personalized insights and reports.",
  inviteMemberInfoMessage:
    "Simply type in the email addresses of the people you want to invite. They'll receive an invitation to join the platform.",
  addMemberInfoMessage,
  modalDescriptionForWhenNotSendingEmails,
};

export const INVITE_MEMBER_INTO_TEAM_TEXT: InviteMemberInfoText = {
  modalDescription:
    "By inviting team members to this team, they will be able to take teamwork surveys (TEAMscans) and receive insight reports for this team.",
  inviteMemberInfoMessage:
    "Simply type in the name or email addresses of the people you want to add to this team. They will receive an invitation to join this team.",
  addMemberInfoMessage,
  modalDescriptionForWhenNotSendingEmails,
};

export const INVITE_PEOPLE_TEXT: {
  Team: InviteMemberInfoText;
  Organization: InviteMemberInfoText;
} = {
  Team: INVITE_MEMBER_INTO_TEAM_TEXT,
  Organization: INVITE_PEOPLE_INTO_ORGANIZATION_TEXT,
};
