import Button from "app/storybookComponents/Button";
import { AssessmentMap } from "app/containers/Assessment/constants";
import { useEffect, useState } from "react";
import {
  SurveyEventFrequency,
  SurveyEventTypes,
  SurveyKeepAssessmentOpenOption,
} from "app/components/Team360Assessment/types";
import { getTeam360EndDate } from "app/components/Team360Assessment/helpers";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  scheduleTEAM360,
  selectScheduleTEAM360Status,
  selectDepartments,
  getAssessmentInformation,
  selectAssessmentInformation,
} from "app/containers/AdminConsole/slice";
import {
  selectIsCurrentUserAdmin,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import LaunchAssessmentOverview from "./LaunchAssessmentOverview";
import { LaunchAssessmentState, LaunchAssessmentInviteType } from "./types";
import LaunchAssessmentInviteBody from "./LaunchAssessmentInviteBody";
import LaunchAssessmentConfigBody from "./LaunchAssessmentConfigBody";
import LaunchAssessmentPreviewBody from "./LaunchAssessmentPreviewBody";
import LaunchAssessmentWarningPreviewBody from "./LaunchAssessmentWarningPreviewBody";
import { DEFAULT_CUSTOM_MESSAGE } from "./constants";

interface Props {
  onSuccessfulLaunch?: () => void;
  isInModal?: boolean;
  leftButton?: {
    text: string;
    onClick: () => void;
  };
  onHide?: (isTemporary?: boolean) => void;
  teamId?: number | null;
  departmentId?: number | null;
  editingAssessmentId?: number | null;
  isEntireOrganizationDefault?: boolean;
}

export default function LaunchAssessmentComponent({
  onSuccessfulLaunch,
  isInModal,
  leftButton,
  onHide,
  teamId,
  departmentId,
  isEntireOrganizationDefault,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const schedulingTeam360Status = useAppSelector(selectScheduleTEAM360Status);
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const departments = useAppSelector(selectDepartments);
  const assessmentInformation = useAppSelector(selectAssessmentInformation);
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);

  // -------------------------------- STATE -------------------------------- //
  const [state, setState] = useState<LaunchAssessmentState>("Overview");
  const [inviteType, setInviteType] =
    useState<LaunchAssessmentInviteType>("Select Team(s)");
  const [eventType, setEventType] =
    useState<SurveyEventTypes>("One-time event");
  const [frequency, setFrequency] = useState<SurveyEventFrequency>("Weekly");
  const [customMessage, setCustomMessage] = useState<false | string>(
    DEFAULT_CUSTOM_MESSAGE
  );
  const [dates, setDates] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [keepOpenFor, setKeepOpenFor] =
    useState<SurveyKeepAssessmentOpenOption>("1 month");
  const [selectedId, setSelectedId] = useState<number[]>([]);
  const [advancedOpened, setAdvancedOpened] = useState(false);
  const [inviteeCount, setInviteeCount] = useState(0); // This state will show the number of invitees inside of the last warning screen.
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [sendEmails, setSendEmails] = useState(false);

  // -------------------------------- USE EFFECTS -------------------------------- //

  useEffect(() => {
    // Might need to add guard clause if surveyId is passed in then we check if the survey data is available.
    // if so use that data to populuate the state. and exit early. Otherwise we can continue with the rest of the logic.

    if (teamId) {
      setSelectedId([teamId]);
      setInviteType("Select Team(s)");
      return;
    }

    if (departmentId) {
      setSelectedId([departmentId]);
      setInviteType("Specific Department(s)");
      return;
    }

    if (isEntireOrganizationDefault) {
      setSelectedId([]);
      setInviteType("Entire Organization");
      return;
    }

    // else set the selectedIds to empty array and inviteType to teams
    setSelectedId([]);
    setInviteType("Select Team(s)");
  }, [departmentId, teamId, isEntireOrganizationDefault]);

  useEffect(() => {
    dispatch(getAssessmentInformation());
  }, [dispatch]);

  // -------------------------------- EVENT HANDLERS -------------------------------- //

  const addTeamMembersToSet = (
    existingSet: Set<number>,
    teamId: number
  ): Set<number> => {
    // If that team already has an active assessment then we don't want to add the team members to the set
    if (assessmentInformation?.teams?.[teamId]?.activeAssessment) {
      return new Set(existingSet);
    }

    const returnSet = new Set(existingSet);

    teamsById[teamId]?.teamMemberIds?.forEach((userId) => {
      returnSet.add(userId);
    });

    teamsById[teamId]?.pendingTeamMemberIds?.forEach((userId) => {
      returnSet.add(userId);
    });

    return returnSet;
  };

  const onGetInviteeCount = () => {
    let userIdSet = new Set<number>();
    switch (inviteType) {
      case "Select Team(s)":
        // Add the selected teams to invitedTeams
        selectedId.forEach((teamId) => {
          userIdSet = addTeamMembersToSet(userIdSet, teamId);
        });
        break;
      case "Specific Department(s)":
        // iterate through all of the selected departments and then check the team
        selectedId.forEach((departmentId) => {
          const department = departments[departmentId];
          if (!department) return;
          department.teams?.forEach((teamId) => {
            userIdSet = addTeamMembersToSet(userIdSet, teamId);
          });
        });
        break;
      case "Entire Organization":
        // Add all of the users in the company to the userIdSet
        Object.keys(teamsById).forEach((teamId) => {
          userIdSet = addTeamMembersToSet(userIdSet, Number(teamId));
        });
        break;
    }
    setInviteeCount(userIdSet.size);
  };

  const getScheduleBy = () => {
    switch (inviteType) {
      case "Entire Organization":
        return "admin";
      case "Select Team(s)":
        return "teamLeader";
      case "Specific Department(s)":
        return "departmentLeader";
      default:
        return "teamLeader";
    }
  };

  // -------------------------------- RENDERING FUNCTIONS -------------------------------- //
  const getComponentBody = () => {
    const customMessageString = customMessage || "";

    switch (state) {
      case "Overview": // Step 1
        return (
          <LaunchAssessmentOverview
            assessmentId={1}
            onLaunchAssessment={() => {
              setState("Invite");
            }}
            scheduledBy={getScheduleBy()}
            leftButton={leftButton}
            onCloseModal={() => onHide?.(true)}
          />
        );
      case "Invite": // Step 2
        return (
          <LaunchAssessmentInviteBody
            parentInviteType={inviteType}
            parentSelectedIds={selectedId}
            onCancel={() => {
              // Should take you back to preview
              setState("Overview");
            }}
            onSave={(selectedIds, inviteType, sendEmails) => {
              // Should save the invite type and the selected ids
              // Then proceed to the preview page
              setSendEmails(sendEmails);
              setInviteType(inviteType);
              setSelectedId(selectedIds);
              setState("Preview");
            }}
            saveButtonText={"Next"}
          />
        );
      case "Preview": // Step 3
        return (
          <LaunchAssessmentPreviewBody
            inviteType={inviteType}
            selectedId={selectedId}
            customMessage={customMessage}
            dates={dates}
            setState={setState}
            setAdvancedOpened={setAdvancedOpened}
            addCustomMessage={() => setCustomMessage(customMessageString)}
            assessmentId={1}
            keepOpenFor={keepOpenFor}
            schedulingTeam360Status={schedulingTeam360Status}
            onCancel={() => {
              // Should take you back to the invite page
              setState("Invite");
            }}
            onLaunch={() => {
              onGetInviteeCount();
              if (isInModal) {
                setState("Preview Warning");
              } else {
                setWarningModalOpen(true);
              }
            }}
            sendEmails={sendEmails}
          />
        );
      case "Configure": // Alternate Step 4 - This is the advanced configuration page
        return (
          <LaunchAssessmentConfigBody
            parentDates={dates}
            parentEventType={eventType}
            parentFrequency={frequency}
            parentCustomMessage={customMessage}
            parentKeepOpenFor={keepOpenFor}
            setAdvancedOpened={setAdvancedOpened}
            advancedOpened={advancedOpened}
            onCancel={() => {
              // Should take you back to preview
              setState("Invite");
            }}
            onSave={({
              eventType,
              frequency,
              customMessage,
              keepOpenFor,
              dates,
            }) => {
              // Should save the dates, event type, frequency, custom message, and keep open for
              // Then proceed to the preview page
              setEventType(eventType);
              setFrequency(frequency);
              setCustomMessage(customMessage);
              setKeepOpenFor(keepOpenFor);
              setDates(dates);
              setState("Preview");
            }}
          />
        );
      case "Preview Warning": {
        const teamIds = inviteType === "Select Team(s)" ? selectedId : [];
        const departmentIds =
          inviteType === "Specific Department(s)" ? selectedId : [];
        return (
          <LaunchAssessmentWarningPreviewBody
            totalInvites={inviteeCount}
            schedulingTeam360Status={schedulingTeam360Status}
            inviteType={inviteType}
            onLaunch={() => {
              dispatch(
                scheduleTEAM360({
                  testId: 1, // This is temporarily hardcoded because we currently only have one survey
                  inviteOrg: inviteType === "Entire Organization",
                  teamIds,
                  userAccountIds: [],
                  departmentIds,
                  // If the event type is recurring then we send the frequency, otherwise we send once
                  frequency:
                    eventType === "Recurring event"
                      ? (frequency.toLocaleLowerCase() as Lowercase<SurveyEventFrequency>)
                      : "once",
                  customMessage: customMessageString,
                  startDate: dates.startDate.toISOString(),
                  // If the event type is recurring then the end date should be the start date plus the keep open for time
                  endDate:
                    eventType === "Recurring event"
                      ? dates.endDate.toISOString()
                      : getTeam360EndDate(
                          dates.startDate,
                          keepOpenFor
                        ).toISOString(),
                  sendInvitations: sendEmails ? 1 : 0,
                })
              );
            }}
            onCancel={() => {
              // Should take you back to the preview page
              setState("Preview");
            }}
            surveyId={1}
            sendEmails={sendEmails}
            isAdmin={!!isAdmin}
          />
        );
      }
      default:
        return null;
    }
  };

  const getInvitationType = () => {
    if (inviteType === "Entire Organization") return "organization";
    if (inviteType === "Select Team(s)") return "team";
    if (inviteType === "Specific Department(s)") return "department";
  };
  const invitationType = getInvitationType();
  const warningMessageStem = inviteeCount
    ? `${inviteeCount} ${inviteeCount === 1 ? "person" : "people"}`
    : `Once team members join your ${invitationType}, they`;

  return (
    <>
      <WarningModal
        customButtonText="Launch"
        isDanger={false}
        isOpen={warningModalOpen}
        hideModal={() => setWarningModalOpen(false)}
        modalTitle="Launch TEAMscan"
        warningTitle={`Are you sure you want to send this TEAMscan to everyone in your ${invitationType}?`}
        warningMessage={`${warningMessageStem} will receive an invitation to take this survey.`}
        onConfirmClick={async () => {
          await dispatch(
            scheduleTEAM360({
              testId: 1, // This is temporarily hardcoded because we currently only have one survey
              inviteOrg: inviteType === "Entire Organization",
              teamIds: inviteType === "Select Team(s)" ? selectedId : [],
              userAccountIds: [],
              departmentIds:
                inviteType === "Specific Department(s)" ? selectedId : [],
              // If the event type is recurring then we send the frequency, otherwise we send once
              frequency:
                eventType === "Recurring event"
                  ? (frequency.toLocaleLowerCase() as Lowercase<SurveyEventFrequency>)
                  : "once",
              customMessage: customMessage || "",
              startDate: dates.startDate.toISOString(),
              // If the event type is recurring then the end date should be the start date plus the keep open for time
              endDate:
                eventType === "Recurring event"
                  ? dates.endDate.toISOString()
                  : getTeam360EndDate(
                      dates.startDate,
                      keepOpenFor
                    ).toISOString(),
              sendInvitations: sendEmails ? 0 : 1,
            })
          );
          onSuccessfulLaunch?.();
        }}
      />
      <div className="launch-survey-container">
        <div className="modal-title-row">
          <div className="row-gap-8px align-items-center">
            <img
              alt={AssessmentMap[1].name}
              src={AssessmentMap[1].assessmentIcon}
            />
            <h2>Launch TEAMscan</h2>
          </div>
          {onHide ? (
            <Button
              onClick={() => onHide()}
              variant={"secondary-blue"}
              style={{ border: "none", width: "auto" }}
              xIcon
            />
          ) : null}
        </div>
        {getComponentBody()}
      </div>
    </>
  );
}
