import { Card, Collapse, Dropdown } from "react-bootstrap";
import { ConversationPartnerType } from "app/containers/DepartmentInsightReport/types";
import React, { useCallback, useMemo, useState } from "react";
import Team360ConversationDeck from "./Team360ConversationDeck";
import {
  selectSampleTeamsByTeamId,
  selectTeamsByTeamId,
  selectSampleUsersInfoById,
  selectAllCompanyUsersById,
} from "app/containers/Global/slice";
import Button from "app/storybookComponents/Button";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import { getConversationPartners } from "app/containers/DepartmentInsightReport/slice";
import Loading from "app/storybookComponents/Loading";
import { StartAndEndDateOrInstance } from "app/components/SurveyDataInstances/types";
import { ConversationPartner } from "app/containers/TeamGuide/types";
import {
  selectCompanySettings,
  selectDepartments,
} from "app/containers/AdminConsole/slice";
import { getDepartmentLeadId } from "app/containers/AdminConsole/helpers";
import { getTeamLeadersFromDepartmentId } from "./helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  departmentId: number;
  title?: string;
  comparedByOptionSelected?: {
    userAccountId: number;
    teamId?: number | null;
  } | null;
  comparedToOptionSelected?: {
    compareTo: number | ConversationPartnerType;
    teamId?: number | null;
  } | null;
  storedConversationPartners?: ConversationPartner[] | null;
  isLoading?: boolean;
  dateRangeInstance?: StartAndEndDateOrInstance;
  stepsText?: string[];
  isCollapseByDefault?: boolean;
}

const listArray = [
  "Select a team leader or a team member from this team to suggest conversations for.",
  "Match a team leader with another team leader in this department.",
  "Match a team member with another team member on this team.",
];

export default function DepartmentTeam360WhatConversationsCanYouSuggest({
  title = "What Conversations Can You Suggest?",
  storedConversationPartners,
  isLoading,
  comparedByOptionSelected,
  comparedToOptionSelected,
  dateRangeInstance = {},
  stepsText = listArray,
  departmentId,
  isCollapseByDefault = false,
}: Props) {
  // -------------------------- Redux selectors --------------------------
  const dispatch = useAppDispatch();
  const departments = useAppSelector(selectDepartments);
  const sampleTeamsById = useAppSelector(selectSampleTeamsByTeamId);
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const sampleUsersById = useAppSelector(selectSampleUsersInfoById);
  const companyUsersById = useAppSelector(selectAllCompanyUsersById);
  const companySettings = useAppSelector(selectCompanySettings);
  const usersInfoById = useMemo(
    () => ({ ...sampleUsersById, ...companyUsersById }),
    [sampleUsersById, companyUsersById]
  );
  const teamInfoById = useMemo(
    () => ({ ...teamsById, ...sampleTeamsById }),
    [teamsById, sampleTeamsById]
  );

  // -------------------------- States --------------------------
  const [isCollapsed, setIsCollapsed] = useState(isCollapseByDefault);

  // -------------------------- Helper functions --------------------------
  const departmentLeaderId = useMemo(
    () => getDepartmentLeadId(departmentId, departments),
    [departments, departmentId]
  );

  const teamLeadersOfDepartment = useMemo(
    () =>
      getTeamLeadersFromDepartmentId(departmentId, teamInfoById, departments),
    [departments, departmentId, teamInfoById]
  );

  const getUserNameById = useCallback(
    (userId: number) => {
      return `${usersInfoById[userId]?.firstName ?? ""} ${
        usersInfoById[userId]?.lastName ?? ""
      }`;
    },
    [usersInfoById]
  );
  const otherDepartmentLeaders = useMemo(() => {
    const departmentLeaders: {
      userAccountId: number;
      departmentId: number;
    }[] = [];

    Object.keys(departments).forEach((departmentId) => {
      const leaderId = getDepartmentLeadId(Number(departmentId), departments);
      if (leaderId) {
        departmentLeaders.push({
          userAccountId: leaderId,
          departmentId: parseInt(departmentId),
        });
      }
    });

    return departmentLeaders;
  }, [departments]);

  const getUserOption = useCallback(
    ({
      userId,
      teamId,
      showDivider = false,
      isSelected = false,
      departmentId,
    }: {
      userId: number;
      teamId?: number;
      showDivider?: boolean;
      isSelected?: boolean;
      departmentId?: number;
    }) => {
      if (showDivider) {
        return (
          <React.Fragment key={`${userId}_${teamId}`}>
            <Dropdown.Item
              eventKey={userId}
              className={isSelected ? "dropdown-selected" : undefined}
            >
              <div className="column-gap-4px">
                <p className="dropdown-bigger-text">
                  {getUserNameById(userId)}
                </p>
                {teamId ? (
                  <span className="dropdown-smaller-text">
                    {teamInfoById[teamId]?.teamName}
                  </span>
                ) : null}
                {departmentId ? (
                  <span className="dropdown-smaller-text">
                    {departments[departmentId]?.name}
                  </span>
                ) : null}
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
          </React.Fragment>
        );
      }
      return (
        <Dropdown.Item
          eventKey={userId}
          key={userId}
          className={isSelected ? "dropdown-selected" : undefined}
        >
          <div className="column-gap-4px">
            <p className="dropdown-bigger-text">{getUserNameById(userId)}</p>
            {teamId ? (
              <span className="dropdown-smaller-text">
                {teamInfoById[teamId]?.teamName}
              </span>
            ) : null}
            {departmentId ? (
              <span className="dropdown-smaller-text">
                {departments[departmentId]?.name}
              </span>
            ) : null}
          </div>
        </Dropdown.Item>
      );
    },
    [getUserNameById, teamInfoById, departments]
  );

  const getDropdownOptionSelected = useCallback(
    (userId?: number | null | ConversationPartnerType | "departmentLeader") => {
      switch (userId) {
        case "teamLeaderInThisDepartment":
          return "Another team leader on this department";
        case "departmentLeader":
          return "Another department leader in this organization";
        case null:
        case undefined:
          return null;
        default:
          return getUserNameById(userId);
      }
    },
    [getUserNameById]
  );

  // We have this function so that we don't have to send in both the userAccountId and the conversationPartnerId, it shall pick the missing one from the state
  const onGetConversationData = useCallback(
    ({
      userAccountId,
      conversationPartner,
      teamId,
    }: {
      userAccountId?: number | null;
      conversationPartner?: number | null | ConversationPartnerType;
      teamId?: number | null;
    }) => {
      const trueUserAccountId =
        userAccountId ?? comparedByOptionSelected?.userAccountId; // If the userAccountId is not provided then we will use the one from the state
      const trueConversationPartner =
        conversationPartner ?? comparedToOptionSelected?.compareTo; // If the conversationPartner is not provided then we will use the one from the state
      if (!trueUserAccountId || !trueConversationPartner || !departmentId)
        return;
      const trueTeamId =
        teamId ?? comparedByOptionSelected?.teamId ?? undefined;

      if (trueConversationPartner === "teamLeaderInThisDepartment") {
        if (!trueTeamId) return;
        return dispatch(
          getConversationPartners({
            departmentId,
            userAccountId: trueUserAccountId,
            conversationPartnerType: trueConversationPartner,
            teamId: trueTeamId,
            ...dateRangeInstance,
          })
        );
      }

      if (trueConversationPartner === "departmentLeader") {
        return dispatch(
          getConversationPartners({
            departmentId,
            userAccountId: trueUserAccountId,
            conversationPartnerType: trueConversationPartner,
            teamId: trueTeamId,
            ...dateRangeInstance,
          })
        );
      }

      dispatch(
        getConversationPartners({
          departmentId,
          userAccountId: trueUserAccountId,
          conversationPartner: trueConversationPartner,
          teamId: trueTeamId,
          ...dateRangeInstance,
        })
      );
    },
    [
      departmentId,
      dateRangeInstance,
      dispatch,
      comparedByOptionSelected,
      comparedToOptionSelected,
    ]
  );

  const onCompareByDropdownSelect = (e: string | null) => {
    if (!e) return;
    const selectedUserId = parseInt(e);
    // First we will check if the chosen one is the department leader, if so then we will need to look through the team leaders and find the team leader.
    // Once we found it then we will need to send that value to the onGetConversationData function
    if (selectedUserId !== departmentLeaderId) {
      const teamLeader = teamLeadersOfDepartment.find(
        ({ userAccountId }) => userAccountId === selectedUserId
      );
      return onGetConversationData({
        userAccountId: selectedUserId,
        teamId: teamLeader?.teamId,
      });
    }

    onGetConversationData({ userAccountId: parseInt(e) });
  };

  const onCompareToDropdownSelect = (e: string | null) => {
    if (!e) return;
    if (e === "teamLeaderInThisDepartment" || e === "departmentLeader") {
      return onGetConversationData({ conversationPartner: e });
    }
    onGetConversationData({ conversationPartner: parseInt(e) });
  };

  const getFirstDropdown = () => {
    const filteredMemberIds = teamLeadersOfDepartment.filter(
      ({ userAccountId }) =>
        userAccountId !== departmentLeaderId &&
        usersInfoById[userAccountId]?.firstName
    );

    const leaderDropdowns = departmentLeaderId ? (
      <>
        <span className="dropdown-label">Department Leader</span>
        {getUserOption({ userId: departmentLeaderId, showDivider: true })}
      </>
    ) : null;

    const memberDropdowns = filteredMemberIds.map(
      ({ userAccountId, teamId }, idx) => {
        const isSelected =
          userAccountId === comparedByOptionSelected?.userAccountId &&
          (!comparedByOptionSelected?.teamId ||
            teamId === comparedByOptionSelected?.teamId);
        const showDivider = idx === filteredMemberIds.length - 1;
        return getUserOption({
          userId: userAccountId,
          showDivider,
          isSelected,
          teamId,
        });
      }
    );

    return (
      <Dropdown
        onSelect={onCompareByDropdownSelect}
        style={{
          opacity: isLoading ? 0.5 : 1,
        }}
      >
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          className="dropdown-menu-240px"
          disabled={!!isLoading}
        >
          {getDropdownOptionSelected(comparedByOptionSelected?.userAccountId)}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-240px">
          {leaderDropdowns}
          <span className="dropdown-label">Team Leaders</span>
          {memberDropdowns}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const getSecondDropdownOptions = useCallback(() => {
    // If the comparedByOptionSelected?.userAccountId is the department leader then the dropdown should be leaders of the other departments in the organization
    if (comparedByOptionSelected?.userAccountId === departmentLeaderId) {
      const isSampleUser = sampleUsersById[departmentLeaderId];
      const filteredMemberIds = otherDepartmentLeaders.filter(
        ({ userAccountId }) =>
          userAccountId !== departmentLeaderId &&
          userAccountId !== comparedByOptionSelected?.userAccountId &&
          usersInfoById[userAccountId]?.firstName &&
          (isSampleUser
            ? sampleUsersById[userAccountId]
            : companyUsersById[userAccountId])
      );
      const memberDropdowns = filteredMemberIds.map(
        ({ userAccountId, departmentId }, idx) =>
          getUserOption({
            userId: userAccountId,
            departmentId,
            showDivider: idx === filteredMemberIds.length - 1,
          })
      );

      return {
        memberDropdowns,
      };
    }

    // If the comparedByOptionSelected?.userAccountId is just a team leader then the dropdown should show the rest of the team leaders of this department excluding the one selected
    const filteredMemberIds = teamLeadersOfDepartment.filter(
      ({ userAccountId }) =>
        userAccountId !== departmentLeaderId &&
        userAccountId !== comparedByOptionSelected?.userAccountId &&
        usersInfoById[userAccountId]?.firstName
    );

    const memberDropdowns = filteredMemberIds.map(
      ({ userAccountId, teamId }, idx) =>
        getUserOption({
          userId: userAccountId,
          teamId,
          showDivider: idx === filteredMemberIds.length - 1,
        })
    );

    return {
      memberDropdowns,
    };
  }, [
    comparedByOptionSelected?.userAccountId,
    getUserOption,
    usersInfoById,
    departmentLeaderId,
    teamLeadersOfDepartment,
    otherDepartmentLeaders,
    sampleUsersById,
    companyUsersById,
  ]);

  const getSecondDropdown = () => {
    const { memberDropdowns } = getSecondDropdownOptions();

    return (
      <Dropdown
        onSelect={onCompareToDropdownSelect}
        style={{
          opacity: isLoading ? 0.5 : 1,
        }}
      >
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          disabled={!!isLoading}
        >
          {getDropdownOptionSelected(comparedToOptionSelected?.compareTo)}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <span className="dropdown-label">Randomly select</span>
          {comparedByOptionSelected?.userAccountId === departmentLeaderId ? (
            <Dropdown.Item
              eventKey={"departmentLeader"}
              className={
                comparedToOptionSelected?.compareTo === "departmentLeader"
                  ? "dropdown-selected"
                  : undefined
              }
            >
              {getDropdownOptionSelected("departmentLeader")}
            </Dropdown.Item>
          ) : (
            <Dropdown.Item
              eventKey={"teamLeaderInThisDepartment"}
              className={
                comparedToOptionSelected?.compareTo ===
                "teamLeaderInThisDepartment"
                  ? "dropdown-selected"
                  : undefined
              }
            >
              {getDropdownOptionSelected("teamLeaderInThisDepartment")}
            </Dropdown.Item>
          )}
          <Dropdown.Divider />
          <span className="dropdown-label">
            Manually select a{" "}
            {comparedToOptionSelected?.compareTo === departmentLeaderId
              ? "department"
              : "team"}{" "}
            leader
          </span>
          {memberDropdowns}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  if (!companySettings?.hasSuggestedConversations) {
    return null;
  }

  return (
    <Card
      className="column-gap-20px"
      style={{
        padding: "20px",
      }}
      role={isCollapsed ? "button" : undefined}
      onClick={() => {
        if (!isCollapsed) return;
        setIsCollapsed(false);
      }}
    >
      <div className="column-gap-8px">
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <h2>{title}</h2>
          </div>
          <div>
            <Button
              onClick={() => {
                setIsCollapsed(!isCollapsed);
              }}
              variant="secondary-blue"
              className="border-0"
            >
              {isCollapsed ? "Expand" : "Collapse"}
              <FontAwesomeIcon
                icon={`caret-${isCollapsed ? "down" : "up"}`}
                className="ms-2"
              />
            </Button>
          </div>
        </div>
        <Collapse in={!isCollapsed}>
          <ol
            style={{
              paddingLeft: "20px",
              margin: 0,
            }}
          >
            {stepsText.map((item, idx) => (
              <li key={idx}>
                <p>{item}</p>
              </li>
            ))}
          </ol>
        </Collapse>
      </div>

      <Collapse in={!isCollapsed}>
        {!isLoading ? (
          <div className="column-gap-20px">
            <div className="conversation-to-pick-selector-container">
              <p>Match:</p>
              {getFirstDropdown()}
              <p>with</p>
              {getSecondDropdown()}
            </div>
            <Team360ConversationDeck
              conversationPartners={storedConversationPartners}
              deckLabelElement={
                <p style={{ marginBottom: "8px" }}>
                  <b>
                    Two conversations you might suggest for{" "}
                    {comparedByOptionSelected?.userAccountId
                      ? getUserNameById(comparedByOptionSelected?.userAccountId)
                      : null}
                    :
                  </b>
                </p>
              }
              comparingUserAccountId={
                comparedByOptionSelected?.userAccountId ?? null
              }
            />
          </div>
        ) : (
          <Loading />
        )}
      </Collapse>
    </Card>
  );
}
