import { ReactNode } from "react";
import { Card, Collapse } from "react-bootstrap";

import Button from "app/storybookComponents/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  title: string;
  setIsOpen: (val: boolean) => void;
  isOpen?: boolean;
  children?: ReactNode;
  className?: string;
}

export default function SimpleCollapsibleCard({
  children,
  setIsOpen,
  isOpen,
  className = "",
  title,
}: Props) {
  const isCollapsed = !isOpen;
  return (
    <Card
      className={`simple-collapsible-card ${className}`}
      role={isCollapsed ? "button" : undefined}
      onClick={() => {
        if (isCollapsed) {
          setIsOpen(true);
        }
      }}
    >
      <div
        className="simple-collapsible-card__title"
        role={!isCollapsed ? "button" : undefined}
        onClick={() => {
          if (!isCollapsed) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <h2>{title}</h2>
        <div>
          <Button variant="secondary-blue" className="border-0">
            {isCollapsed ? "Expand" : "Collapse"}
            <FontAwesomeIcon
              icon={`caret-${isCollapsed ? "down" : "up"}`}
              className="ms-2"
            />
          </Button>
        </div>
      </div>
      <Collapse in={isOpen}>
        <div>
          <div className="collapsed-container">{children}</div>
        </div>
      </Collapse>
    </Card>
  );
}
