import Header from "app/storybookComponents/GuideHeaderCard";
import Button from "app/storybookComponents/Button";
import React, { useLayoutEffect, useMemo, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectAssessmentInformation,
  selectCompanySettings,
  selectDepartments,
} from "app/containers/AdminConsole/slice";
import {
  selectAllCompanyUsersById,
  selectCurrentUserAccountId,
  selectIsCurrentUserAdmin,
} from "app/containers/Global/slice";
import { getS } from "utils/helperFunctions";
import InfoCardV2 from "app/components/InfoCard/InfoCardV2";
import { selectDepartmentTeamLeadersByDepartmentId } from "app/containers/Dashboard/slice";
import { Card, Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QuickActionCard from "app/components/QuickActions/QuickActionCard";
import { AssessmentMap } from "app/containers/Assessment/constants";
import { showScheduleAssessmentModalForDepartmentId } from "app/components/LaunchAssessmentModal/slice";
import { setShowModal } from "app/components/Onboarding/slice";
import SurveyResultsModal from "app/components/Modals/SurveyResultsModal";
import DepartmentInsightReport from "app/containers/DepartmentInsightReport";
import {
  openCreateTeamModal,
  openEditDepartmentModal,
  openInviteTeamLeaderModal,
} from "app/components/Modals/slice";
import { selectCompanyGuide } from "app/containers/CompanyGuide/slice";
import { selectUserIsAbleToCreateTeams } from "app/containers/UserGuide/slice";
import DepartmentSettingsPage from "./DepartmentSettingsPage";
import PendingTeamsModal from "app/components/Modals/PendingTeamsModal";
import NavigateBackButton from "app/components/NavigateBackButton";
import { trackDepartmentGuideTabChange } from "utils/trackingFunctions";

interface DepartmentGuideProps {
  // Define your prop types here
}

const DepartmentGuide: React.FC<DepartmentGuideProps> = (props) => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const departmentId = Number(urlParams.departmentId);
  const departments = useAppSelector(selectDepartments);
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const loggedInUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const assessmentInformation = useAppSelector(selectAssessmentInformation);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const departmentTeamLeaders = useAppSelector(
    selectDepartmentTeamLeadersByDepartmentId(departmentId)
  );
  const companySettings = useAppSelector(selectCompanySettings);
  const currentCompanyGuide = useAppSelector(selectCompanyGuide);
  const canUserCreateTeams = useAppSelector(selectUserIsAbleToCreateTeams);
  const companyHeaderPhoto =
    companySettings?.globalHeader ?? currentCompanyGuide?.coverPhoto?.picture;

  // ------------------------ States ------------------------
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [activeHeaderTab, setActiveHeaderTab] = useState("Dashboard");
  const [isUnderStandTeamworkCollapsed, setIsUnderStandTeamworkCollapsed] =
    useState(false);
  const [showPendingTeamsModal, setShowPendingTeamsModal] = useState(false);

  useLayoutEffect(() => {
    const tab = searchParams.get("tab");
    setActiveHeaderTab(tab ?? "Dashboard");
  }, [searchParams, departmentId]);

  const currentDepartment = useMemo(
    () => departments[departmentId],
    [departmentId, departments]
  );

  const isCurrentUserDepartmentLeader = useMemo(() => {
    if (!currentDepartment) {
      return false;
    }
    const leaderId =
      typeof currentDepartment.leader === "number"
        ? currentDepartment.leader
        : currentDepartment.leader?.userAccountId;
    return leaderId === loggedInUserAccountId;
  }, [currentDepartment, loggedInUserAccountId]);

  const getHeaderDescription = () => {
    if (!currentDepartment) {
      return undefined;
    }
    const totalTeams = currentDepartment?.teams?.length ?? 0;

    return `${totalTeams} Team${getS(totalTeams)}`;
  };

  const onSetTab = (tab: string) => {
    setActiveHeaderTab(tab);
    setSearchParams({ tab });
    trackDepartmentGuideTabChange(tab, isCurrentUserDepartmentLeader);
    if (tab !== "TEAMscan") {
      return;
    }
  };

  const onShowInviteTeamLeaderModal = () => {
    dispatch(
      openInviteTeamLeaderModal({
        departmentId,
      })
    );
  };

  const getTeamsInDepartmentActions = () => {
    const actions: { onClick: () => void; text: string; iconName: "plus" }[] =
      [];
    // if the user is a department leader or admin then we show the invite team leader button
    if (isCurrentUserDepartmentLeader || isAdmin) {
      actions.push({
        onClick: () => {
          onShowInviteTeamLeaderModal();
        },
        text: "Invite team leader",
        iconName: "plus",
      });
    }
    // then we check if the user has access to create a team, if they do then we show the create team button
    if (canUserCreateTeams) {
      actions.push({
        onClick: () => {
          dispatch(openCreateTeamModal());
        },
        text: "Create a team",
        iconName: "plus",
      });
    }
    return actions;
  };

  const getDashboardContent = () => {
    if (!currentDepartment) {
      return false;
    }
    const pendingMembersForDepartment =
      currentDepartment?.teamMembers?.filter((userAccountId) => {
        const user = usersById[userAccountId];
        if (!user) return false;
        const { firstTeamsLogin, lastName, firstName, jobTitle } = user;
        return !(firstTeamsLogin || firstName || lastName || jobTitle);
      })?.length ?? 0;
    const totalTeams = currentDepartment?.teams?.length ?? 0;
    const totalMembersInDepartment =
      currentDepartment?.teamMembers?.length ?? 0;
    const totalAcceptedMembersInDepartment =
      totalMembersInDepartment - pendingMembersForDepartment;

    const isLaunchTeam360Disabled = !!(
      departmentId &&
      assessmentInformation?.departments?.[departmentId]?.activeAssessment
    );

    return (
      <div className="column-gap-20px">
        <div className="responsive-two-column-container">
          <InfoCardV2
            title="Teams In This Department"
            body={totalTeams}
            actions={getTeamsInDepartmentActions()}
            onPendingTextClick={() => {
              if (departmentTeamLeaders?.length) {
                return setShowPendingTeamsModal(true);
              }
              onShowInviteTeamLeaderModal();
            }}
            pendingText={
              departmentTeamLeaders?.length
                ? `${departmentTeamLeaders?.length} pending team leaders`
                : "No pending team leaders"
            }
            onCardClick={() =>
              navigate(
                `/Search/Teams?departmentId=${currentDepartment.departmentId}`
              )
            }
            className="clickable"
          />
          <InfoCardV2
            title="People In This Department"
            body={totalAcceptedMembersInDepartment}
            actions={[
              {
                onClick: () => {
                  navigate(
                    `/Search/People?departmentId=${currentDepartment.departmentId}`
                  );
                },
                text: "View people",
                iconName: "arrow-right",
              },
            ]}
            pendingText={
              pendingMembersForDepartment
                ? `${pendingMembersForDepartment} invited but haven't joined yet`
                : "No pending invitations"
            }
            onCardClick={
              totalMembersInDepartment
                ? () => {
                    navigate(
                      `/Search/People?departmentId=${currentDepartment.departmentId}`
                    );
                  }
                : undefined
            }
            className="clickable"
          />
        </div>

        <Card className="column-gap-20px" style={{ padding: "20px" }}>
          <div className="dashboard-container-header">
            <div className="dashboard-container-header__info">
              <div className="small-square-icon">
                <FontAwesomeIcon
                  icon={["fas", "poll-people"]}
                  className="icon"
                />
              </div>
              <div>
                <h3>
                  Launch and manage the TEAMscan teamwork survey and view
                  results
                </h3>
                <p>
                  Understand teamwork and empower higher-performing managers.{" "}
                  <Link
                    to="#"
                    onClick={() => {
                      dispatch(
                        setShowModal({
                          eventType: "generalTeam360Information",
                        })
                      );
                    }}
                  >
                    What is the TEAMscan?
                  </Link>
                </p>
              </div>
            </div>
            <div className="row-gap-16px">
              <Button
                onClick={() => {
                  setIsUnderStandTeamworkCollapsed(
                    !isUnderStandTeamworkCollapsed
                  );
                }}
              >
                {isUnderStandTeamworkCollapsed ? "See" : "Hide"} actions
              </Button>
            </div>
          </div>
          <Collapse in={!isUnderStandTeamworkCollapsed}>
            <div className="three-card-container responsive">
              <QuickActionCard
                title="Launch TEAMscan survey"
                description="Launch the survey for one or more teams or your entire department."
                actionButton={{
                  onClick: () => {
                    dispatch(
                      showScheduleAssessmentModalForDepartmentId(departmentId)
                    );
                  },
                  text: "Launch survey",
                }}
                iconName="rocket-launch"
                isWholeCardDisabled={isLaunchTeam360Disabled}
                cornerTag={
                  isLaunchTeam360Disabled ? (
                    <div className="label-tag green">Already active</div>
                  ) : null
                }
                imageIcon={
                  <img
                    alt={AssessmentMap[1].name}
                    src={AssessmentMap[1].assessmentIcon}
                  />
                }
              />
              <QuickActionCard
                title="View TEAMscan results"
                description="View results from past surveys for teams in this department."
                actionButton={{
                  onClick: () => {
                    setShowResultsModal(true);
                  },
                  text: "View results",
                }}
                iconName="square-poll-vertical"
              />
              <QuickActionCard
                title="Manage TEAMscan Surveys"
                description="View 1-time and recurring surveys. Adjust survey period, reminders, and more."
                actionButton={{
                  onClick: () => {
                    navigate(
                      `/DepartmentGuide/${departmentId}?tab=Manage+Surveys`
                    );
                  },
                  text: "Manage surveys",
                }}
                iconName="wrench"
              />
            </div>
          </Collapse>
        </Card>
      </div>
    );
  };

  const getPageContent = () => {
    switch (activeHeaderTab) {
      case "Dashboard":
        return getDashboardContent();
      case "TEAMscan":
        return <DepartmentInsightReport />;
      case "Manage Surveys":
        return (
          <DepartmentSettingsPage
            departmentId={departmentId}
            isLeader={isCurrentUserDepartmentLeader || !!isAdmin}
          />
        );
    }
  };

  const getHeaderTabs = () => {
    const tabs = ["Dashboard", "TEAMscan"];
    if (isCurrentUserDepartmentLeader || isAdmin) {
      tabs.push("Manage Surveys");
    }
    return tabs;
  };

  const getHeader = () => {
    switch (activeHeaderTab) {
      case "Dashboard":
      case "TEAMscan":
        return (
          <Header
            name={currentDepartment?.name || ""}
            setPhotoModalShowing={() => {}}
            onEditClickHandler={() => {
              dispatch(openEditDepartmentModal({ departmentId }));
            }}
            totalMembers={currentDepartment?.teamMembers?.length}
            headerDescription={getHeaderDescription()}
            tabs={getHeaderTabs()}
            activeKey={activeHeaderTab}
            setTab={onSetTab}
            hasEditAccess={isCurrentUserDepartmentLeader || !!isAdmin}
            companyHeaderPhoto={companyHeaderPhoto}
          />
        );
      default:
        return null;
    }
  };

  const getSurveyModalEmptyCard = () => (
    <div className="empty-card">
      <span>No TEAMscan Results In This Department</span>
      <p>
        No teams have an active TEAMscan currently. Launch a TEAMscan for your
        department or one or more teams now.
      </p>
      <div className="action-buttons">
        <Button
          onClick={() => {
            if (departmentId) {
              dispatch(
                showScheduleAssessmentModalForDepartmentId(departmentId)
              );
              setShowResultsModal(false);
            }
          }}
        >
          Launch TEAMscan
        </Button>
        <Button
          variant="secondary-blue"
          onClick={() => {
            dispatch(
              setShowModal({
                eventType: "generalTeam360Information",
                hideActionButton: true,
              })
            );
            setShowResultsModal(false);
          }}
        >
          See details
        </Button>
      </div>
    </div>
  );

  const navigateBackButton = <NavigateBackButton />;

  return (
    <>
      <SurveyResultsModal
        onHide={() => setShowResultsModal(false)}
        show={showResultsModal}
        teamIds={currentDepartment?.teams ?? []}
        showManageSurveysButton
        getEmptyCard={getSurveyModalEmptyCard}
      />
      <PendingTeamsModal
        onHide={() => setShowPendingTeamsModal(false)}
        show={showPendingTeamsModal}
        onShowInviteTeamLeaderModal={() => {
          setShowPendingTeamsModal(false);
          onShowInviteTeamLeaderModal();
        }}
        pendingTeamLeaders={departmentTeamLeaders ?? []}
      />
      <div className="page guide">
        {navigateBackButton ? (
          <div className="header-buttons">
            <div className="mb-0">{navigateBackButton}</div>
          </div>
        ) : null}
        {getHeader()}
        {getPageContent()}
      </div>
    </>
  );
};

export default DepartmentGuide;
