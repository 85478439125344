import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";

interface VisibilityBannerProps {
  children?: React.ReactNode;
  button?: {
    onClick: () => void;
    text: string;
    hideArrow?: boolean;
  };
  style?: React.CSSProperties;
}

export default function VisibilityBanner({
  children,
  button,
  style,
}: Readonly<VisibilityBannerProps>) {
  return (
    <div className="warning-banner blue visibility-banner" style={style}>
      <div className="row-gap-8px align-items-center">
        <FontAwesomeIcon icon={["far", "eye"]} />
        {children}
      </div>
      {button ? (
        <Button
          onClick={button.onClick}
          variant="secondary-blue"
          style={{
            whiteSpace: "nowrap",
            border: 0,
          }}
        >
          {button.text}
          {!button.hideArrow ? (
            <FontAwesomeIcon icon={["fas", "arrow-right"]} className="ms-2" />
          ) : null}
        </Button>
      ) : null}
    </div>
  );
}
