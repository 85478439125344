import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InstanceShowing } from "./types";
import Button from "app/storybookComponents/Button";
import { getStringDate } from "./helpers";

interface Props {
  instanceShowing: InstanceShowing | null;
  onConfigureResults: () => void;
  hasPreviousInstances?: boolean;
  isLatestInstance?: boolean;
  hasEnoughResults?: boolean;
  isCurrentInstanceStillActive?: boolean;
}

export default function InstanceWarningBanner({
  instanceShowing,
  hasEnoughResults,
  onConfigureResults,
  isLatestInstance,
  hasPreviousInstances,
  isCurrentInstanceStillActive,
}: Readonly<Props>) {
  const getLatestInstanceMessage = () =>
    "This report includes all responses from the most recent instance of the TEAMscan.";

  const getNotEnoughResultsMessage = () => {
    let baseMessage = "";

    if (isCurrentInstanceStillActive) {
      baseMessage = "The report for this instance is still pending.";
    }

    if (hasPreviousInstances) {
      baseMessage +=
        " You have past instances with TEAMscan results for this team.";
    }

    if (!baseMessage && isLatestInstance) {
      baseMessage = getLatestInstanceMessage();
    }

    return baseMessage.trim();
  };

  const getBannerText = () => {
    if (
      instanceShowing?.startDate &&
      instanceShowing?.endDate &&
      !isLatestInstance
    ) {
      const startDateString = getStringDate(
        new Date(instanceShowing.startDate)
      );
      const endDateString = getStringDate(new Date(instanceShowing.endDate));

      return `This report is for the period ${startDateString} to ${endDateString}.`;
    }

    if (!hasEnoughResults) {
      return getNotEnoughResultsMessage();
    }

    if (isLatestInstance) {
      return getLatestInstanceMessage();
    }

    return null;
  };

  return (
    <div
      className={`warning-banner${
        isLatestInstance && hasEnoughResults ? " blue " : " "
      }d-flex align-items-center justify-content-between`}
      style={{
        padding: "12px 16px",
      }}
    >
      <p>
        <b>{getBannerText()}</b>
      </p>
      <Button
        variant="secondary-blue"
        className="border-0"
        onClick={() => {
          onConfigureResults();
        }}
      >
        View past results{" "}
        <FontAwesomeIcon icon="arrow-right" className="ms-1" />
      </Button>
    </div>
  );
}
