import { Card, Collapse, Dropdown } from "react-bootstrap";
import {
  ConversationPartner,
  ConversationPartnerType,
} from "app/containers/TeamGuide/types";
import Button from "app/storybookComponents/Button";
import React, { useCallback, useMemo } from "react";
import Team360ConversationDeck from "./Team360ConversationDeck";
import {
  selectAllCompanyUsersById,
  selectSampleUsersInfoById,
} from "app/containers/Global/slice";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import { getConversationPartners } from "app/containers/TeamGuide/slice";
import Loading from "app/storybookComponents/Loading";
import { StartAndEndDateOrInstance } from "app/components/SurveyDataInstances/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectCompanySettings } from "app/containers/AdminConsole/slice";

interface Props {
  teamId: number;
  title?: string;
  teamLeaderIds?: number[];
  teamMemberIds?: number[];
  firstDropdownSelectedOption?: number | null;
  secondDropdownSelectedOption?: number | null | ConversationPartnerType;
  storedConversationPartners?: ConversationPartner[] | null;
  isLoading?: boolean;
  dateRangeInstance?: StartAndEndDateOrInstance;
  stepsText?: string[];
  isExpanded?: boolean;
  setIsExpanded?: (isExpanded: boolean) => void;
}

const listArray = [
  "Select a team leader or a team member from this team to suggest conversations for.",
  "Match a team leader with another team leader in this department.",
  "Match a team member with another team member on this team.",
];

export default function Team360WhatConversationsCanYouSuggest({
  title = "What Conversations Can You Suggest?",
  teamId,
  teamLeaderIds = [],
  teamMemberIds = [],
  firstDropdownSelectedOption,
  secondDropdownSelectedOption,
  storedConversationPartners,
  isLoading,
  dateRangeInstance = {},
  stepsText = listArray,
  isExpanded = false,
  setIsExpanded,
}: Props) {
  const dispatch = useAppDispatch();
  // -------------------------- Redux selectors --------------------------
  const sampleUsersById = useAppSelector(selectSampleUsersInfoById);
  const companyUsersById = useAppSelector(selectAllCompanyUsersById);
  const companySettings = useAppSelector(selectCompanySettings);
  const usersInfoById = useMemo(
    () => ({ ...sampleUsersById, ...companyUsersById }),
    [sampleUsersById, companyUsersById]
  );

  // -------------------------- Helper functions --------------------------
  const getUserNameById = useCallback(
    (userId?: number | null) => {
      if (!userId) return "";
      return `${usersInfoById[userId]?.firstName ?? ""} ${
        usersInfoById[userId]?.lastName ?? ""
      }`;
    },
    [usersInfoById]
  );

  const getUserOption = useCallback(
    (userId: number, isLastElm: boolean, isSelected?: boolean) => {
      if (!isLastElm) {
        return (
          <React.Fragment key={userId}>
            <Dropdown.Item
              eventKey={userId}
              className={isSelected ? "dropdown-selected" : undefined}
            >
              {getUserNameById(userId)}
            </Dropdown.Item>
            <Dropdown.Divider />
          </React.Fragment>
        );
      }
      return (
        <Dropdown.Item
          eventKey={userId}
          key={userId}
          className={isSelected ? "dropdown-selected" : undefined}
        >
          {getUserNameById(userId)}
        </Dropdown.Item>
      );
    },
    [getUserNameById]
  );

  const getDropdownOptionSelected = useCallback(
    (userId?: number | null | ConversationPartnerType) => {
      switch (userId) {
        case "teamLeaderInThisDepartment":
          return "Another team leader on this department";
        case "teamMemberOnThisTeam":
          return "Another team member on this team";
        case null:
        case undefined:
          return null;
        default:
          return getUserNameById(userId);
      }
    },
    [getUserNameById]
  );

  const onGetConversationData = useCallback(
    ({
      userAccountId,
      conversationPartner,
    }: {
      userAccountId?: number | null;
      conversationPartner?: number | null | ConversationPartnerType;
    }) => {
      const trueUserAccountId = userAccountId ?? firstDropdownSelectedOption;
      const trueConversationPartner =
        conversationPartner ?? secondDropdownSelectedOption;
      if (!trueUserAccountId || !trueConversationPartner) return;
      if (typeof trueConversationPartner === "number") {
        dispatch(
          getConversationPartners({
            teamId,
            userAccountId: trueUserAccountId,
            conversationPartner: trueConversationPartner,
            ...dateRangeInstance,
          })
        );
      } else if (typeof trueConversationPartner === "string") {
        dispatch(
          getConversationPartners({
            teamId,
            userAccountId: trueUserAccountId,
            conversationPartnerType: trueConversationPartner,
            ...dateRangeInstance,
          })
        );
      }
    },
    [
      firstDropdownSelectedOption,
      secondDropdownSelectedOption,
      dispatch,
      teamId,
      dateRangeInstance,
    ]
  );

  const firstDropdown = useMemo(() => {
    const onDropdownSelect = (e: string | null) => {
      if (!e) return;
      onGetConversationData({ userAccountId: parseInt(e) });
    };
    const filteredMemberIds = teamMemberIds.filter(
      (id) =>
        !teamLeaderIds.includes(id) &&
        id !== secondDropdownSelectedOption &&
        usersInfoById[id]?.firstName
    );

    const memberDropdowns = filteredMemberIds.map((id, idx) =>
      getUserOption(
        id,
        idx === filteredMemberIds.length - 1,
        id === firstDropdownSelectedOption
      )
    );
    const leaderDropdowns = teamLeaderIds.map((id, idx) =>
      getUserOption(
        id,
        idx === teamLeaderIds.length - 1,
        id === firstDropdownSelectedOption
      )
    );

    return (
      <Dropdown
        onSelect={onDropdownSelect}
        style={{
          opacity: isLoading ? 0.5 : 1,
        }}
      >
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          className="dropdown-menu-240px"
          disabled={!!isLoading}
        >
          {getDropdownOptionSelected(firstDropdownSelectedOption)}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-240px">
          <span className="dropdown-label">Team Leaders</span>
          {leaderDropdowns}
          <Dropdown.Divider />
          <span className="dropdown-label">Team Members</span>
          {memberDropdowns}
        </Dropdown.Menu>
      </Dropdown>
    );
  }, [
    teamLeaderIds,
    teamMemberIds,
    firstDropdownSelectedOption,
    getUserOption,
    getDropdownOptionSelected,
    onGetConversationData,
    isLoading,
    secondDropdownSelectedOption,
    usersInfoById,
  ]);

  const secondDropdown = useMemo(() => {
    const onDropdownSelect = (e: string | null) => {
      if (!e) return;
      if (e === "teamLeaderInThisDepartment" || e === "teamMemberOnThisTeam") {
        return onGetConversationData({ conversationPartner: e });
      }
      onGetConversationData({ conversationPartner: parseInt(e) });
    };
    const filteredMemberIds = teamMemberIds.filter(
      (id) => id !== firstDropdownSelectedOption && usersInfoById[id]?.firstName
    );
    const memberDropdowns = filteredMemberIds.map((id, idx) =>
      getUserOption(
        id,
        idx === filteredMemberIds.length - 1,
        id === secondDropdownSelectedOption
      )
    );

    return (
      <Dropdown
        onSelect={onDropdownSelect}
        style={{
          opacity: isLoading ? 0.5 : 1,
        }}
      >
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          disabled={!!isLoading}
        >
          {getDropdownOptionSelected(secondDropdownSelectedOption)}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <span className="dropdown-label">Randomly select</span>
          <Dropdown.Item
            eventKey={"teamLeaderInThisDepartment"}
            className={
              secondDropdownSelectedOption === "teamLeaderInThisDepartment"
                ? "dropdown-selected"
                : undefined
            }
          >
            {getDropdownOptionSelected("teamLeaderInThisDepartment")}
          </Dropdown.Item>
          <Dropdown.Divider />

          <Dropdown.Item
            eventKey={"teamMemberOnThisTeam"}
            className={
              secondDropdownSelectedOption === "teamMemberOnThisTeam"
                ? "dropdown-selected"
                : undefined
            }
          >
            {getDropdownOptionSelected("teamMemberOnThisTeam")}
          </Dropdown.Item>
          <Dropdown.Divider />
          <span className="dropdown-label">Manually select</span>
          {memberDropdowns}
        </Dropdown.Menu>
      </Dropdown>
    );
  }, [
    teamMemberIds,
    secondDropdownSelectedOption,
    getDropdownOptionSelected,
    getUserOption,
    onGetConversationData,
    isLoading,
    firstDropdownSelectedOption,
    usersInfoById,
  ]);

  if (!companySettings?.hasSuggestedConversations) {
    return null;
  }

  return (
    <Card
      className="column-gap-20px"
      style={{
        padding: "20px",
      }}
      role={isExpanded && setIsExpanded ? undefined : "button"}
      onClick={() => {
        if (isExpanded) return;
        setIsExpanded?.(true);
      }}
    >
      <div className="column-gap-8px">
        <div className="d-flex justify-content-between align-items-start">
          <h2>{title}</h2>
          {setIsExpanded ? (
            <div>
              <Button
                onClick={() => {
                  setIsExpanded?.(!isExpanded);
                }}
                variant="secondary-blue"
                className="border-0"
              >
                {!isExpanded ? "Expand" : "Collapse"}
                <FontAwesomeIcon
                  icon={`caret-${isExpanded ? "up" : "down"}`}
                  className="ms-2"
                />
              </Button>
            </div>
          ) : null}
        </div>
        <Collapse in={isExpanded}>
          <ol
            style={{
              paddingLeft: "20px",
              margin: 0,
            }}
          >
            {stepsText.map((item, idx) => (
              <li key={idx}>
                <p>{item}</p>
              </li>
            ))}
          </ol>
        </Collapse>
      </div>
      <Collapse in={isExpanded}>
        {!isLoading ? (
          <div className="column-gap-20px">
            <div className="conversation-to-pick-selector-container">
              <p>Match:</p>
              {firstDropdown}
              <p>with</p>
              {secondDropdown}
            </div>
            <Team360ConversationDeck
              conversationPartners={storedConversationPartners}
              deckLabelElement={
                <p style={{ marginBottom: "8px" }}>
                  <b>
                    Two conversations you might suggest for{" "}
                    {firstDropdownSelectedOption
                      ? getUserNameById(firstDropdownSelectedOption)
                      : null}
                    :
                  </b>
                </p>
              }
              comparingUserAccountId={firstDropdownSelectedOption ?? null}
            />
          </div>
        ) : (
          <Loading />
        )}
      </Collapse>
    </Card>
  );
}
