import { Card } from "react-bootstrap";
import { useState, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  getUserVisibilitySettings,
  selectGetUserSettingsStatus,
  selectLoggedInUsersVisibilitySettings,
  updateUserVisibilitySettings,
} from "./slice";
import {
  selectAllCompanyUsersById,
  selectCurrentUserAccountId,
  updateUserInfo,
  updateUserInfoById,
} from "app/containers/Global/slice";
import Loading from "app/storybookComponents/Loading";
import Button from "app/storybookComponents/Button";
import DeactivateUserModal from "../AdminConsole/Modals/DeactivateUserModal";
import NavigateBackButton from "app/components/NavigateBackButton";
import { useDebounceCallback } from "utils/hooks";
import Toggle from "app/components/Toggle";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import {
  removeChatBoConversationsFromState,
  resetCoachingPlan,
} from "app/containers/Chatbot/slice";
import {
  selectCompanySettings,
  selectHasCoachBoConversationHistoryEnabled,
} from "app/containers/AdminConsole/slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChecklistDropdown from "app/components/Dropdowns/ChecklistDropdown";
import { UserVisibilitySetting, UserVisibilitySettings } from "./types";
import { UserVisibilitySettingsLabelMap } from "./constant";

export default function UserSettings() {
  const dispatch = useAppDispatch();

  const users = useAppSelector(selectAllCompanyUsersById);
  const loggedInUserId = useAppSelector(selectCurrentUserAccountId);
  const userSettingsStatus = useAppSelector(selectGetUserSettingsStatus);
  const userVisibilitySettings = useAppSelector(
    selectLoggedInUsersVisibilitySettings
  );
  const loggedInUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const isConversationHistoryEnabledCompanyWide = useAppSelector(
    selectHasCoachBoConversationHistoryEnabled
  );
  const companySettings = useAppSelector(selectCompanySettings);
  const [showDeactivateUserModal, setShowDeactivateUserModal] = useState(false);
  const [showTurnOffConversationWarning, setShowTurnOffConversationWarning] =
    useState(false);
  const [
    workPlaceInsightsVisibilitySetting,
    setWorkPlaceInsightsVisibilitySetting,
  ] = useState<UserVisibilitySetting>({});
  const [
    collaborationGuideVisibilitySetting,
    setCollaborationGuideVisibilitySetting,
  ] = useState<UserVisibilitySetting>({});
  const [teamReportsVisibilitySetting, setTeamReportsVisibilitySetting] =
    useState<UserVisibilitySetting>({});

  const userInfo = useMemo(
    () => (loggedInUserId ? users[loggedInUserId] : null),
    [users, loggedInUserId]
  );

  useEffect(() => {
    if (loggedInUserAccountId && userSettingsStatus === "idle") {
      dispatch(
        getUserVisibilitySettings({
          userAccountId: loggedInUserAccountId,
        })
      );
    }
  }, [dispatch, loggedInUserAccountId, userSettingsStatus]);

  useEffect(() => {
    if (!userVisibilitySettings) return;
    setWorkPlaceInsightsVisibilitySetting(
      userVisibilitySettings["Workplace Insights"]
    );
    setCollaborationGuideVisibilitySetting(
      userVisibilitySettings["Collaboration Guide"]
    );
    setTeamReportsVisibilitySetting(
      userVisibilitySettings["Team Personality Continuum"]
    );
  }, [userVisibilitySettings]);

  // The debounce function is used to prevent the API call from being made too frequently.
  const onSaveAiEmailsSetting = useDebounceCallback(
    (hasCoachBoEmailsEnabled: 0 | 1) => {
      dispatch(updateUserInfo({ hasCoachBoEmailsEnabled }));
    },
    1000
  );

  const onSaveWorkplaceInsightsVisibility = useDebounceCallback(
    (payload: UserVisibilitySettings["Workplace Insights"]) => {
      if (!loggedInUserId) {
        return null;
      }
      dispatch(
        updateUserVisibilitySettings({
          userAccountId: loggedInUserId,
          payload: {
            "Workplace Insights": payload,
          },
        })
      );
    },
    1000
  );

  const onSaveCollaborationGuideVisibility = useDebounceCallback(
    (payload: UserVisibilitySettings["Collaboration Guide"]) => {
      if (!loggedInUserId) {
        return null;
      }
      dispatch(
        updateUserVisibilitySettings({
          userAccountId: loggedInUserId,
          payload: {
            "Collaboration Guide": payload,
          },
        })
      );
    },
    1000
  );

  const onSaveTeamReportsVisibility = useDebounceCallback(
    (payload: UserVisibilitySettings["Team Personality Continuum"]) => {
      if (!loggedInUserId) {
        return null;
      }
      dispatch(
        updateUserVisibilitySettings({
          userAccountId: loggedInUserId,
          payload: {
            "Team Personality Continuum": payload,
          },
        })
      );
    },
    1000
  );

  const getVisibilitySettingValue = (setting: UserVisibilitySetting) => {
    if (setting["Organization Member"]) {
      return "Everyone in this organization";
    }
    const keys: string[] = [];
    Object.entries(setting).forEach(([key, value]) => {
      if (value) {
        keys.push(UserVisibilitySettingsLabelMap[key] ?? key);
      }
    });

    if (keys.length !== 1) {
      return keys.join(", ");
    }

    if (setting["Manager"]) {
      return "Only my manager";
    }

    if (setting["Self"]) {
      return "Only me";
    }
  };

  const onVisibilityChecklistDropdownSelect = (
    selectedItems: UserVisibilitySetting,
    setter: (value: UserVisibilitySetting) => void,
    selectedKey?: string | number,
    alwaysOnSettings?: UserVisibilitySetting
  ) => {
    // If the user selects "Organization Member" and it is selected then we should select all the other options
    if (selectedKey === "Organization Member" && selectedItems[selectedKey]) {
      return setter({
        "Organization Member": 1,
        Admin: 1,
        "Team Leader": 1,
        "Team Member": 1,
        Self: 1,
        Manager: 1,
        ...alwaysOnSettings,
      });
    }

    // If the user deselects any of the other options then we should deselect "Organization Member"
    if (
      (selectedKey === "Team Member" ||
        selectedKey === "Team Leader" ||
        selectedKey === "Manager") &&
      !selectedItems[selectedKey]
    ) {
      return setter({
        ...selectedItems,
        [selectedKey]: selectedItems[selectedKey],
        "Organization Member": 0,
      });
    }

    setter(selectedItems);
  };

  const getPrivacyAndVisibilitySection = () => {
    if (!companySettings) return <Loading />;

    const isOverrideDisabled =
      !companySettings?.allowVisibilityPermissionOverride;
    const lockIcon = isOverrideDisabled ? (
      <FontAwesomeIcon
        icon={["far", "lock-keyhole"]}
        style={{ color: "#805100" }}
      />
    ) : null;

    return (
      <>
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Who can see your Workplace Insights?</h3>
            <p>
              Specify which users can see your personality results taken from
              the EPP.
            </p>
          </div>
          <div className="setting-row-action">
            {lockIcon}
            <ChecklistDropdown
              checklistItems={[
                { id: "Admin", label: "Admin", disabled: true },
                { id: "Manager", label: "My Manager" },
                { id: "Team Member", label: "Everyone on my team(s)" },
                { id: "Team Leader", label: "My team leader(s)" },
                {
                  id: "Organization Member",
                  label: "Everyone in this organization (including above)",
                },
              ]}
              selectedItems={workPlaceInsightsVisibilitySetting}
              setSelectedItems={(si, ck) =>
                onVisibilityChecklistDropdownSelect(
                  si,
                  setWorkPlaceInsightsVisibilitySetting,
                  ck,
                  { Admin: 1 }
                )
              }
              disabled={isOverrideDisabled}
              forcedDropdownText={getVisibilitySettingValue(
                workPlaceInsightsVisibilitySetting
              )}
              onUnFocus={(pl) =>
                onSaveWorkplaceInsightsVisibility(pl as UserVisibilitySetting)
              }
            />
          </div>
        </div>
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Who can see your Collaboration Guide?</h3>
            <p>Specify which users can see your collaboration guide.</p>
          </div>
          <div className="setting-row-action">
            {lockIcon}
            <ChecklistDropdown
              checklistItems={[
                { id: "Admin", label: "Admin", disabled: true },
                { id: "Manager", label: "My Manager" },
                { id: "Team Member", label: "Everyone on my team(s)" },
                { id: "Team Leader", label: "My team leader(s)" },
                {
                  id: "Organization Member",
                  label: "Everyone in this organization (including above)",
                },
              ]}
              selectedItems={{
                Admin: true,
                ...collaborationGuideVisibilitySetting,
              }}
              setSelectedItems={(si, ck) =>
                onVisibilityChecklistDropdownSelect(
                  si,
                  setCollaborationGuideVisibilitySetting,
                  ck,
                  { Admin: 1 }
                )
              }
              disabled={isOverrideDisabled}
              forcedDropdownText={getVisibilitySettingValue({
                Admin: 1,
                ...collaborationGuideVisibilitySetting,
              })}
              onUnFocus={(pl) =>
                onSaveCollaborationGuideVisibility(pl as UserVisibilitySetting)
              }
            />
          </div>
        </div>
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Who can see you on Team Reports?</h3>
            <p>
              Specify which users can see your personality results on teams
              you're on.
            </p>
          </div>
          <div className="setting-row-action">
            {lockIcon}
            <ChecklistDropdown
              checklistItems={[
                { id: "Admin", label: "Admin", disabled: true },
                { id: "Manager", label: "My Manager" },
                { id: "Team Member", label: "Everyone on my team(s)" },
                { id: "Team Leader", label: "My team leader(s)" },
                {
                  id: "Organization Member",
                  label: "Everyone in this organization (including above)",
                },
              ]}
              selectedItems={teamReportsVisibilitySetting}
              setSelectedItems={(si, ck) =>
                onVisibilityChecklistDropdownSelect(
                  si,
                  setTeamReportsVisibilitySetting,
                  ck,
                  { Admin: 1 }
                )
              }
              disabled={isOverrideDisabled}
              forcedDropdownText={getVisibilitySettingValue(
                teamReportsVisibilitySetting
              )}
              onUnFocus={(pl) =>
                onSaveTeamReportsVisibility(pl as UserVisibilitySetting)
              }
            />
          </div>
        </div>
        {isOverrideDisabled ? (
          <div className="warning-banner gold row-gap-8px align-items-center">
            {lockIcon}
            <p>
              An admin has turned off the ability to override visibility
              defaults set for this organization. Please reach out to an admin
              to change visibility settings.
            </p>
          </div>
        ) : null}

        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Enable Coach Bo Session Recaps</h3>
            <p>
              Allow emails summarizing your most recent conversation with Coach
              Bo, including any commitments mentioned.
            </p>
          </div>
          <div className="setting-row-action">
            <Toggle
              isOn={!!userInfo?.hasCoachBoEmailsEnabled}
              handleToggle={() => {
                if (!loggedInUserId) return null;
                const updatedValue = userInfo?.hasCoachBoEmailsEnabled ? 0 : 1;
                onSaveAiEmailsSetting(updatedValue);
                dispatch(
                  updateUserInfoById({
                    userAccountId: loggedInUserId,
                    updatedUserInfo: {
                      hasCoachBoEmailsEnabled: updatedValue,
                    },
                  })
                );
              }}
            />
          </div>
        </div>
        {isConversationHistoryEnabledCompanyWide ? (
          <div className="setting-row">
            <div className="setting-row-description">
              <h3>Save conversation history with Coach Bo?</h3>
              <p>
                Coach Bo will save your conversations for future conversations,
                goal settings, and general insights. Your conversations will{" "}
                <b>not</b> be shared without your consent.
              </p>
            </div>
            <div className="setting-row-action">
              <Toggle
                isOn={!!userInfo?.hasCoachBoConversationHistoryEnabled}
                handleToggle={() => {
                  if (!loggedInUserId) return null;
                  // if the already existing value is set to true then we should open the warning modal
                  // if the already existing value is set to false then we should update the value
                  if (userInfo?.hasCoachBoConversationHistoryEnabled) {
                    setShowTurnOffConversationWarning(true);
                    return;
                  }

                  const hasCoachBoConversationHistoryEnabled = 1;
                  dispatch(
                    updateUserInfo({ hasCoachBoConversationHistoryEnabled })
                  );
                  dispatch(
                    updateUserInfoById({
                      userAccountId: loggedInUserId,
                      updatedUserInfo: {
                        hasCoachBoConversationHistoryEnabled,
                      },
                    })
                  );
                }}
              />
            </div>
          </div>
        ) : null}
      </>
    );
  };
  return (
    <>
      <WarningModal
        modalTitle="Conversation History"
        warningTitle="Are you sure you want to turn off this setting?"
        warningMessage="This will permanently delete all conversations in your chat history with Coach Bo. Note: You can delete individual chats in your chat history."
        isOpen={!!showTurnOffConversationWarning}
        onConfirmClick={async () => {
          if (!loggedInUserId) return null;
          const hasCoachBoConversationHistoryEnabled = 0;
          await dispatch(
            updateUserInfo({ hasCoachBoConversationHistoryEnabled })
          );
          dispatch(removeChatBoConversationsFromState());
          dispatch(
            updateUserInfoById({
              userAccountId: loggedInUserId,
              updatedUserInfo: {
                hasCoachBoConversationHistoryEnabled,
              },
            })
          );
          dispatch(resetCoachingPlan());
          setShowTurnOffConversationWarning(false);
        }}
        hideModal={() => {
          setShowTurnOffConversationWarning(false);
        }}
        customButtonText="Confirm Changes"
        isDanger={false}
      />
      <div className="page guide">
        <div className="mb-2">
          <NavigateBackButton />
        </div>
        <DeactivateUserModal
          show={showDeactivateUserModal}
          onHide={() => setShowDeactivateUserModal(false)}
          userAccountId={loggedInUserAccountId}
        />
        <Card className="column-gap-20px padding-20px">
          <h1>User Settings</h1>
          {getPrivacyAndVisibilitySection()}
          <hr className="m-0" />
          <div className="column-gap-10px">
            <h3>Deactivate Account</h3>
            <p>
              Deactivating your account will remove your access to the platform
              and delete your team memberships.
            </p>
            <div>
              <Button
                variant="secondary-danger"
                onClick={() => {
                  setShowDeactivateUserModal(true);
                }}
              >
                Deactivate account
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}
