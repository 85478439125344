import Button from "app/storybookComponents/Button";

interface Props {
  onConfigureResults?: () => void;
  hideFooter?: boolean;
  bottomRightElement?: React.ReactNode | null;
}

export default function InstanceStickyFooter({
  hideFooter,
  onConfigureResults,
  bottomRightElement,
}: Readonly<Props>) {
  if (hideFooter) {
    return null;
  }

  const getBottomRightElement = () => {
    if (bottomRightElement) {
      return bottomRightElement;
    }
    if (onConfigureResults) {
      return <Button onClick={onConfigureResults}>Configure results</Button>;
    }
    return null;
  };

  return (
    <div className="analytics-sticky-footer">
      <div />
      {getBottomRightElement()}
    </div>
  );
}
