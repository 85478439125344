import SimpleModal from "app/components/Modals/SimpleModal";
import AgreementChart from "app/storybookComponents/Charts/AgreementChart";
import React, { useEffect, useState } from "react";
import {
  ALL_TEAM_360_FACTORS,
  TEAM360_DIMENSIONS_MAP,
  CONSTRUCT_NAME_KEY,
  TEAM_360_DIMENSION_DESCRIPTIONS,
} from "./constants";
import {
  getTeam360Score,
  getSplitDimensionByAgreement,
  getAgreementTagColor,
} from "./helpers";
import HelpPopover from "./HelpPopover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getEntries } from "utils/helperFunctions";
import { Collapse } from "react-bootstrap";
import {
  AgreeablenessResult,
  AgreementState,
  Team360AssessmentResult,
} from "./types";

interface Props {
  show?: boolean;
  onHide: () => void;
  usersScore?: Team360AssessmentResult | null;
  comparedUserScore?: Team360AssessmentResult | null;
  userAvatar?: React.JSX.Element;
  comparedUserAvatar?: React.JSX.Element;
  userCustomLabel?: string;
  comparedUserCustomLabel?: string;
  differenceThreshold?: number;
}

const INITIAL_STATE: AgreementState = {
  "Low Agreement": null,
  "Medium Agreement": null,
  "High Agreement": null,
};

const defaultAvatarCircle = (
  <div
    className="avatar-circle initials small border-white"
    style={{
      backgroundColor: "#202d63",
    }}
  >
    <span>
      <FontAwesomeIcon icon="user" />
    </span>
  </div>
);

export default function Team360AgreementReportModal({
  show,
  onHide,
  usersScore,
  comparedUserScore,
  userAvatar = defaultAvatarCircle,
  comparedUserAvatar = defaultAvatarCircle,
  userCustomLabel = "Your rating for this team",
  comparedUserCustomLabel = "This team's average",
  differenceThreshold,
}: Props) {
  const [agreementState, setAgreementState] = useState(INITIAL_STATE);
  const [openedAccordion, setOpenedAccordion] = useState<number | null>(null);

  useEffect(() => {
    const agreeablenessResults: AgreeablenessResult[] = [];
    ALL_TEAM_360_FACTORS.forEach((factor) => {
      TEAM360_DIMENSIONS_MAP[factor]?.forEach((dimension) => {
        const dimensionName = CONSTRUCT_NAME_KEY[dimension] ?? dimension;
        // check to see if we have a score for the user and the compared user
        const hasUserScore = usersScore?.dimensions?.[dimensionName];
        const hasComparedUserScore =
          comparedUserScore?.dimensions?.[dimensionName];
        if (!hasUserScore || !hasComparedUserScore) {
          return;
        }
        const userScore = getTeam360Score(
          usersScore?.dimensions?.[dimensionName] ?? 0
        );
        const comparedScore = getTeam360Score(
          comparedUserScore?.dimensions?.[dimensionName] ?? 0
        );
        agreeablenessResults.push({
          dimensionName,
          dimension,
          comparedScore,
          userScore,
        });
      });
    });
    setAgreementState(getSplitDimensionByAgreement(agreeablenessResults));
  }, [usersScore, comparedUserScore]);

  const getPopoverText = (value: string) => {
    if (TEAM_360_DIMENSION_DESCRIPTIONS[value]) {
      return TEAM_360_DIMENSION_DESCRIPTIONS[value];
    }

    switch (value) {
      case "High Agreement":
        return "Dimensions with high agreement have less than a 10 point difference between “This team’s average” and “Your rating of this team”.";
      case "Medium Agreement":
        return "Dimensions with medium agreement have a difference of 10 to 30 points between “This team’s average” and “Your rating of this team”.";
      case "Low Agreement":
      default:
        return "Dimensions with low agreement have a difference greater than 30 points between “This team’s average” and “Your rating of this team”.";
    }
  };

  const getAccordion = () => {
    // We will iterate through our state, if any of the values are null then we will not show that section.
    return getEntries(agreementState).map(([agreementLevel, value], index) => {
      const dimensionCount = value?.length
        ? `${value.length} dimension${value.length > 1 ? "s" : ""}`
        : "No dimensions";
      const isExpanded = openedAccordion === index;
      return (
        <React.Fragment key={agreementLevel}>
          <hr className="m-0" />
          <div key={agreementLevel}>
            <div
              className="team-360-accordion-header"
              onClick={() => setOpenedAccordion(isExpanded ? null : index)}
              role="button"
            >
              <div className="row-gap-12px align-items-center">
                <h3>{agreementLevel}</h3>
                <div>
                  <HelpPopover
                    title={agreementLevel}
                    text={getPopoverText(agreementLevel)}
                  />
                </div>
              </div>
              <div className="row-gap-12px align-items-center">
                <div>
                  <p className="small-body-text">{dimensionCount}</p>
                </div>
                <FontAwesomeIcon icon={`caret-${isExpanded ? "up" : "down"}`} />
              </div>
            </div>

            {getAccordionDrawer(isExpanded, agreementLevel, value)}
          </div>
        </React.Fragment>
      );
    });
  };

  const getAccordionDrawer = (
    isExpanded: boolean,
    agreementLevel: string,
    agreeablenessResults: AgreeablenessResult[] | null
  ) => {
    return (
      <Collapse in={isExpanded}>
        <div
          className="column-gap-20px"
          style={{
            marginTop: "20px",
          }}
        >
          {getAgreementDimensions(agreementLevel, agreeablenessResults)}
        </div>
      </Collapse>
    );
  };

  const getNoDimensionsText = (agreementLevel: string) => {
    let text = `There are no dimensions with ${agreementLevel.toLocaleLowerCase()} between`;
    if (userCustomLabel === "Average of team members") {
      text += ` team members and team leaders`;
    } else {
      text += ` your rating and this team's average rating`;
    }
    return text;
  };

  const getAgreementDimensions = (
    agreementLevel: string,
    agreeablenessResults: AgreeablenessResult[] | null
  ) => {
    if (!agreeablenessResults?.length) {
      return (
        <div className="snapshot-box">
          <p>{getNoDimensionsText(agreementLevel)}</p>
        </div>
      );
    }

    return agreeablenessResults?.map((agreeablenessResult) => {
      return (
        <div
          className="d-flex justify-content-between"
          key={agreeablenessResult.dimension}
        >
          <div className="column-gap-8px">
            <div className="row-gap-12px align-items-center pe-2">
              <h4>
                {agreeablenessResult.dimension}
                <div className="d-inline ms-2" style={{ color: "#53565a" }}>
                  <HelpPopover
                    title={agreeablenessResult.dimension}
                    text={getPopoverText(agreeablenessResult.dimension)}
                  />
                </div>
              </h4>
            </div>
            <div>
              <span
                className={`label-tag m-0 ${getAgreementTagColor(
                  agreementLevel
                )}`}
              >
                {agreementLevel}
              </span>
            </div>
          </div>
          <div
            className="d-flex align-items-center"
            style={{ padding: "12px", width: "70%" }}
          >
            <AgreementChart
              pointA={{
                value: agreeablenessResult.comparedScore,
                avatarCircle: comparedUserAvatar,
              }}
              pointB={{
                value: agreeablenessResult.userScore,
                avatarCircle: userAvatar,
              }}
              differenceThreshold={differenceThreshold}
            />
          </div>
        </div>
      );
    });
  };

  const highAgreementLength = agreementState["High Agreement"]?.length ?? 0;
  const mediumAgreementLength = agreementState["Medium Agreement"]?.length ?? 0;
  const lowAgreementLength = agreementState["Low Agreement"]?.length ?? 0;

  const getDescriptionText = () => {
    let stemText =
      "This chart shows your score and the average score given by the team across 17 dimensions. You and your teammates"; // Default text

    if (userCustomLabel === "Average of team members") {
      stemText =
        "This chart shows the average of team member and team leader scores across 17 dimensions. Team members and team leaders";
    }

    return (
      <p>
        {`${stemText} have high agreement on ${highAgreementLength} dimension${
          highAgreementLength !== 1 ? "s" : ""
        }, medium agreement on ${mediumAgreementLength} dimension${
          mediumAgreementLength !== 1 ? "s" : ""
        }, and low agreement on ${lowAgreementLength} dimension${
          lowAgreementLength !== 1 ? "s" : ""
        }.`}
      </p>
    );
  };

  return (
    <SimpleModal show={show} onHide={onHide} title="Agreement Report">
      {getDescriptionText()}
      <div className="row-gap-20px align-items-center">
        <div className="row-gap-8px align-items-center">
          {comparedUserAvatar}
          <p>{comparedUserCustomLabel}</p>
        </div>
        <div className="row-gap-8px align-items-center">
          {userAvatar}
          <p>{userCustomLabel}</p>
        </div>
      </div>

      {getAccordion()}
    </SimpleModal>
  );
}
