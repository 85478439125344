import AvatarCircle from "app/components/AvatarCircle";
import { useAppSelector } from "utils/redux/hooks";
import {
  selectCompanyInfo,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import { selectCompanyGuide } from "app/containers/CompanyGuide/slice";
import { selectDepartments } from "app/containers/AdminConsole/slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SurveyKeepAssessmentOpenOption } from "app/components/Team360Assessment/types";
import CircleEditButton from "app/storybookComponents/Button/CircleEditButton";
import { getTeam360EndDate } from "app/components/Team360Assessment/helpers";
import { getDepartmentPreviewSentTo, getTeamPreviewSentTo } from "./helpers";
import InvitationOverview from "./InvitationOverview";

interface Props {
  startDate: Date;
  assessmentId: number;
  teamIds?: number[];
  departmentIds?: number[];
  repeating?: string;
  postedDate?: string;
  customMessage?: string;
  onEditSendingTo?: () => void;
  onEditAdvancedSettings?: () => void;
  addCustomMessage?: () => void;
  keepOpenFor?: SurveyKeepAssessmentOpenOption;
  sendEmails: boolean;
}

export default function SurveyPreviewCard({
  teamIds,
  departmentIds,
  onEditSendingTo,
  onEditAdvancedSettings,
  keepOpenFor,
  startDate,
  sendEmails,
}: Readonly<Props>) {
  const companyInfo = useAppSelector(selectCompanyInfo);
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const companyGuide = useAppSelector(selectCompanyGuide);
  const departments = useAppSelector(selectDepartments);
  const getHeader = () => {
    // Before only showing 1 we need to check if there are multiple teams if so then we need to show the avatars of the teams
    if (teamIds) {
      const { avatars, description, title } = getTeamPreviewSentTo(
        teamsById,
        teamIds
      );
      return (
        <div className="row-gap-12px">
          <div className="multi-avatar-container">{avatars}</div>
          <div className="column-gap-4px">
            <p>
              <b>{title}</b>
            </p>
            <p className="small-body-text">{description}</p>
          </div>
        </div>
      );
    }
    // If there are departments then we need to show the avatars of the departments
    if (departmentIds) {
      const { avatars, description, title } = getDepartmentPreviewSentTo(
        departments,
        departmentIds
      );
      return (
        <div className="row-gap-12px">
          <div className="multi-avatar-container">{avatars}</div>
          <div className="column-gap-4px">
            <p>
              <b>{title}</b>
            </p>
            <p className="small-body-text">{description}</p>
          </div>
        </div>
      );
    }

    if (companyInfo) {
      const companyProfilePic = companyGuide?.profilePicture?.picture || "";
      return (
        <div className="row-gap-12px">
          <div>
            <AvatarCircle
              name={companyInfo?.companyName}
              profilePicture={companyProfilePic}
              size="small"
            />
          </div>
          <div className="column-gap-4px">
            <p>
              <b>{companyInfo?.companyName}</b>
            </p>
            <p className="small-body-text">
              Sending to the entire organization
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  const startDateText = new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "numeric",
  }).format(startDate);

  const endDateText =
    keepOpenFor &&
    new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
    }).format(getTeam360EndDate(startDate, keepOpenFor));

  const showInvitationOverview = !teamIds || teamIds.length === 0;
  return (
    <div className="survey-card">
      <hr className="m-0" />
      <div className="column-gap-12px">
        <p className="sapphire-150-text fw-bold">
          You are sending this survey to:
        </p>
        <div className="section-header d-flex row-gap-20px justify-content-between">
          {getHeader()}
          <CircleEditButton onClick={() => onEditSendingTo?.()} />
        </div>
      </div>
      {showInvitationOverview && (
        <>
          <hr className="m-0" />
          <InvitationOverview departmentIds={departmentIds ?? []} />
        </>
      )}
      <hr className="m-0" />
      <div className="survey-card-footer">
        <div>
          <div>
            <p className="sapphire-150-text fw-bold">
              This survey period will be open from:
            </p>
            <br></br>
            <div className="row-gap-8px align-items-center">
              <FontAwesomeIcon icon={["fal", "clock"]} />
              <p>
                {startDateText} to {endDateText}
              </p>
            </div>
          </div>
        </div>
        <div>
          <CircleEditButton onClick={() => onEditAdvancedSettings?.()} />
        </div>
      </div>
      <hr className="m-0" />
      {!sendEmails && (
        <div className="warning-banner lighter blue border-0">
          <p>
            No invitation emails will be sent. You can send invitations to
            complete the TEAMscan later on from the Surveys tab in the Admin
            Console.
          </p>
        </div>
      )}
    </div>
  );
}
