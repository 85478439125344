import { useAppSelector } from "utils/redux/hooks";
import Button from "app/storybookComponents/Button";
import { selectAllCompanyUsersById } from "app/containers/Global/slice";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ReactNode, useState } from "react";

interface Props {
  teamLeaderIds?: number[];
}

export default function TeamLeadersPopup({ teamLeaderIds }: Props) {
  const [show, setShow] = useState(false);
  const usersInfoById = useAppSelector(selectAllCompanyUsersById);
  if (!teamLeaderIds) {
    return null;
  }

  const getTeamLeaders = () => {
    const teamLeaders: ReactNode[] = [];
    teamLeaderIds.forEach((id) => {
      const user = usersInfoById[id];
      if (!user) {
        return;
      }
      const { firstName = "", lastName = "", jobTitle = "" } = user;
      teamLeaders.push(
        <div>
          <h3 style={{ fontSize: "14px" }}>
            {firstName} {lastName}
          </h3>
          <p className="small-body-text">{jobTitle}</p>
        </div>
      );
      teamLeaders.push(<hr className="m-0" />);
    });
    teamLeaders.pop();
    return teamLeaders;
  };

  const overlay = (
    <Popover className="team-leader-popover">
      <div className="column-gap-16px">
        <div className="d-flex justify-content-between align-items-center">
          <p>
            <b>Team Leaders</b>
          </p>
          <Button
            xIcon
            variant="secondary-gray"
            className="border-0"
            size="sm"
            onClick={() => setShow(false)}
            style={{ padding: "1px", borderRadius: "2px" }}
          />
        </div>
        {getTeamLeaders()}
      </div>
    </Popover>
  );

  return (
    <OverlayTrigger
      rootClose
      overlay={overlay}
      trigger="click"
      placement="bottom"
      show={show}
      onToggle={setShow}
    >
      <div
        style={{
          cursor: "pointer",
          width: "fit-content",
          color: "#425cc7",
          textDecoration: "underline",
        }}
      >
        {teamLeaderIds.length} leaders
      </div>
    </OverlayTrigger>
  );
}
