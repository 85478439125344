import Paginator from "app/components/SortableTable/Paginator";
import Button from "app/storybookComponents/Button";
import CommitmentModal from "app/components/Modals/CommitmentModal";
import Loading from "app/storybookComponents/Loading";
import { useNavigate } from "react-router-dom";
import {
  deleteAllConversations,
  getCoachBoConversations,
  selectAllChatbotConversations,
  selectDeleteAllConversationsStatus,
  selectGetCoachBoConversationsStatus,
  setCoachBotPrompt,
  setIsCoachbotOpen,
} from "app/containers/Chatbot/slice";
import { useEffect, useMemo, useState } from "react";
import { Card, Dropdown, Form } from "react-bootstrap";
import Select from "react-select";
import { getSelectProps } from "utils/helperFunctions";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import CoachBotConversationCard from "./CoachBotConversationCard";
import { selectCurrentUserInfo } from "app/containers/Global/slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import { selectHasCoachBoConversationHistoryEnabled } from "app/containers/AdminConsole/slice";

const itemsPerPage = 3;
export default function RecentCoachBoChats() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // ------------------ Selectors ------------------
  const savedChatBoConversations = useAppSelector(
    selectAllChatbotConversations
  );
  const getCoachBoConversationsStatus = useAppSelector(
    selectGetCoachBoConversationsStatus
  );
  const userInfo = useAppSelector(selectCurrentUserInfo);
  const deleteAllConversationsStatus = useAppSelector(
    selectDeleteAllConversationsStatus
  );
  const isConversationHistoryEnabledCompanyWide = useAppSelector(
    selectHasCoachBoConversationHistoryEnabled
  );

  // ------------------ State ------------------
  const [displayInput, setDisplayInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [latestThreadId, setLatestThreadId] = useState<null | string>(null);
  const [showCommitmentModal, setShowCommitmentModal] = useState<null | {
    threadId: string;
  }>(null);
  const [
    showDeleteAllConversationsWarningModal,
    setShowDeleteAllConversationsWarningModal,
  ] = useState(false);

  // ------------------ Effects ------------------
  useEffect(() => {
    if (getCoachBoConversationsStatus === "idle") {
      dispatch(getCoachBoConversations());
    }
  }, [dispatch, getCoachBoConversationsStatus]);

  useEffect(() => {
    if (savedChatBoConversations.length === 0) {
      return;
    }

    const sortedConversationsByUpdatedAt = savedChatBoConversations.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );

    setLatestThreadId(sortedConversationsByUpdatedAt[0].threadId);
  }, [savedChatBoConversations]);

  const onStartNewChat = () => {
    const name = userInfo?.firstName ?? "";

    if (userInfo?.hasCoachBoConversationHistoryEnabled) {
      dispatch(
        setCoachBotPrompt({
          chatType: "welcomeChat",
          userPrompts: [
            "Explore how chatting together regularly can help",
            "Envision my ideal future",
            "Create a 1-2 month growth goal",
          ],
          fullBotIntro: `Hi ${name}, I’m looking forward to getting to know you and helping you achieve your goals. What can I help you with today?`,
        })
      );
    }
    dispatch(setIsCoachbotOpen(true));
  };
  const onConfigureChatSettings = () => {
    navigate("/UserSettings");
  };

  const getSearchElement = () => {
    const { selectStyles, components } = getSelectProps();
    return (
      <Form.Group>
        <Select
          placeholder="Search by date, keyword..."
          isClearable={true}
          isSearchable={true}
          components={components}
          inputValue={displayInput}
          styles={selectStyles}
          menuIsOpen={false}
          onInputChange={(e, actionMeta) => {
            if (actionMeta.action === "input-change") {
              setDisplayInput(e);
              setCurrentPage(1);
            }
          }}
        />
      </Form.Group>
    );
  };

  const filteredConversations = useMemo(
    () =>
      savedChatBoConversations.filter((c) => {
        const itemDate = new Date(c.created_at);
        const date = itemDate.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        });
        const dateLowerCased = date.toLowerCase();
        const summaryLowerCased = c.summary?.summary?.toLowerCase();
        const commitmentLowerCased = c.summary?.commitment?.toLowerCase();
        const titleLowerCased = c.summary?.title?.toLowerCase();
        const displayInputLowerCased = displayInput.toLowerCase();
        return (
          dateLowerCased.includes(displayInputLowerCased) ||
          summaryLowerCased?.includes(displayInputLowerCased) ||
          commitmentLowerCased?.includes(displayInputLowerCased) ||
          titleLowerCased?.includes(displayInputLowerCased)
        );
      }),
    [displayInput, savedChatBoConversations]
  );

  const getConversations = () => {
    if (
      getCoachBoConversationsStatus === "loading" ||
      deleteAllConversationsStatus === "loading"
    ) {
      return <Loading />;
    }

    if (!userInfo?.hasCoachBoConversationHistoryEnabled) {
      return (
        <div className="empty-card">
          <span>No saved chats</span>
          <p>
            You have chosen not to save conversations with Coach Bo. You can
            change this in your user settings.
          </p>
          <div className="action-buttons">
            <Button variant="secondary-blue" onClick={onConfigureChatSettings}>
              Configure chat settings
            </Button>
          </div>
        </div>
      );
    }

    if (savedChatBoConversations.length === 0) {
      return (
        <div className="empty-card">
          <span>No recent chats so far</span>
          <p>
            Chats you have with Coach Bo wil be saved here as a summary with any
            key commitments
          </p>
          <div className="action-buttons">
            <Button onClick={onStartNewChat}>Start chat</Button>
            <Button variant="secondary-blue" onClick={onConfigureChatSettings}>
              Configure chat settings
            </Button>
          </div>
        </div>
      );
    }

    if (filteredConversations.length === 0) {
      return (
        <div className="empty-card">
          <span>No recent chats so far "{displayInput}"</span>
        </div>
      );
    }

    const conversationsShowing = filteredConversations.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    return conversationsShowing.map((c) => (
      <CoachBotConversationCard
        chatbotConversation={c}
        current={c.threadId === latestThreadId}
        key={c.id}
        onShowCommitmentModal={() =>
          setShowCommitmentModal({ threadId: c.threadId })
        }
      />
    ));
  };

  const onDropdownSelect = (eventKey: string | null) => {
    if (eventKey === "settings") {
      onConfigureChatSettings();
    } else if (eventKey === "delete") {
      setShowDeleteAllConversationsWarningModal(true);
    }
  };

  const getDropdown = () => (
    <Dropdown
      onSelect={onDropdownSelect}
      placement="auto"
      style={{
        position: "absolute",
        right: "20px",
      }}
    >
      <Dropdown.Toggle
        variant="outline-primary"
        id="dropdown-basic"
        className="no-caret"
      >
        <FontAwesomeIcon icon="ellipsis" size="lg" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="settings">
          Configure chat settings
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="delete" className="danger">
          Delete all chats
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  if (!isConversationHistoryEnabledCompanyWide) {
    return null;
  }

  return (
    <Card className="padding-20px column-gap-16px">
      <CommitmentModal
        show={!!showCommitmentModal?.threadId}
        onHide={() => setShowCommitmentModal(null)}
        threadId={showCommitmentModal?.threadId}
      />
      <WarningModal
        isOpen={!!showDeleteAllConversationsWarningModal}
        hideModal={() => setShowDeleteAllConversationsWarningModal(false)}
        modalTitle="Delete all chats"
        warningTitle="Are you sure you want to delete all chats?"
        warningMessage="This action cannot be undone. All chats will be permanently deleted."
        onConfirmClick={async () => {
          await dispatch(deleteAllConversations());
          setShowDeleteAllConversationsWarningModal(false);
        }}
        isDanger
      />
      {getDropdown()}
      <h2>Recent Chats</h2>
      {getSearchElement()}
      {getConversations()}
      {getCoachBoConversationsStatus === "loading" ||
      deleteAllConversationsStatus === "loading" ? null : (
        <div className="d-flex">
          <Paginator
            pageCount={Math.ceil(filteredConversations.length / itemsPerPage)}
            onPageChange={(selectedItem) => {
              setCurrentPage(selectedItem.selected + 1);
            }}
            isResponsive
          />
        </div>
      )}
    </Card>
  );
}
