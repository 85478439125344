import Button from "app/storybookComponents/Button";
import SurveyIllustration from "resources/images/survey-illustration.png";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DepartmentSurveys from "app/containers/DepartmentGuide/DepartmentSurveys";
import AdminTablesHeader from "app/containers/AdminConsole/AdminTablesHeader";
import { showScheduleAssessmentModalForDepartmentId } from "app/components/LaunchAssessmentModal/slice";
import { useAppDispatch } from "utils/redux/hooks";

interface Props {
  departmentId: number;
  isLeader?: boolean;
}
export default function DepartmentSettingsPage({
  departmentId,
  isLeader,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getSettingsContent = () => {
    return (
      <>
        <AdminTablesHeader
          bannerDescription="Send the TEAMscan survey to your department or teams in your department."
          bannerTitle="Launch the TEAMscan survey"
          iconImageSrc={SurveyIllustration}
          iconImageAlt="Launch TEAMscan"
          actionButton={
            <div className="row-gap-12px">
              <Button
                onClick={() => {
                  dispatch(
                    showScheduleAssessmentModalForDepartmentId(departmentId)
                  );
                }}
              >
                Launch TEAMscan
              </Button>
              <Button
                onClick={() => {
                  navigate("/SkillsGuide");
                }}
                variant="secondary-blue"
                className="white-button"
              >
                See teamwork skills
              </Button>
            </div>
          }
        />

        <DepartmentSurveys departmentId={departmentId} isLeader={isLeader} />
      </>
    );
  };

  return (
    <div className="column-gap-20px">
      <Card className="padding-20px column-gap-20px">
        <div className="d-flex justify-content-between">
          <h1>Manage Surveys</h1>
          <Button
            variant="secondary-blue"
            onClick={() => {
              navigate(`/DepartmentGuide/${departmentId}?tab=Dashboard`);
            }}
          >
            Go to department dashboard
          </Button>
        </div>
      </Card>
      <Card className="padding-20px column-gap-20px">
        {getSettingsContent()}
      </Card>
    </div>
  );
}
