import Button from "app/storybookComponents/Button";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

type ResponsiveSetting = { marginPages: number; pageRange: number };

type ResponsiveSettings = {
  desktop?: ResponsiveSetting;
  tablet?: ResponsiveSetting;
  mobile?: ResponsiveSetting;
};

interface Props {
  pageRangeDisplayed?: number;
  pageCount: number;
  onPageChange?: (selectedItem: { selected: number }) => void;
  forcePage?: number;
  isResponsive?: boolean;
  responsiveSettings?: ResponsiveSettings;
}

// Default responsive settings
const defaultResponsiveSettings: ResponsiveSettings = {
  desktop: { marginPages: 3, pageRange: 6 },
  tablet: { marginPages: 2, pageRange: 3 },
  mobile: { marginPages: 1, pageRange: 1 },
};

export default function Paginator({
  pageRangeDisplayed,
  pageCount,
  onPageChange,
  forcePage,
  isResponsive = false,
  responsiveSettings = {},
}: Readonly<Props>) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Merge default responsive settings with custom settings coming from props
  const mergedSettings: ResponsiveSettings = {
    ...defaultResponsiveSettings,
    ...responsiveSettings,
  };

  useEffect(() => {
    if (!isResponsive) {
      return;
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isResponsive]);

  const getResponsiveSettings = (width: number) => {
    if (width < 480) {
      return (
        mergedSettings.mobile ||
        (defaultResponsiveSettings.mobile as ResponsiveSetting)
      );
    }

    if (width < 768) {
      return (
        mergedSettings.tablet ||
        (defaultResponsiveSettings.tablet as ResponsiveSetting)
      );
    }

    return (
      mergedSettings.desktop ||
      (defaultResponsiveSettings.desktop as ResponsiveSetting)
    );
  };

  const { marginPages, pageRange } = isResponsive
    ? getResponsiveSettings(windowWidth)
    : (mergedSettings.desktop as ResponsiveSetting);

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={
        <Button
          variant="secondary-blue"
          className="border-0"
          disabled={pageCount <= 1}
        >
          <FontAwesomeIcon icon="chevron-right" />
        </Button>
      }
      previousLabel={
        <Button
          variant="secondary-blue"
          className="border-0"
          disabled={pageCount <= 1}
        >
          <FontAwesomeIcon icon="chevron-left" />
        </Button>
      }
      pageLabelBuilder={(page) => (
        <Button variant="secondary-blue" className="border-0">
          {page}
        </Button>
      )}
      onPageChange={onPageChange}
      pageRangeDisplayed={pageRangeDisplayed ?? pageRange}
      pageCount={pageCount}
      renderOnZeroPageCount={null}
      className="simple-pagination"
      pageClassName="page-item"
      forcePage={forcePage}
      marginPagesDisplayed={marginPages}
    />
  );
}
