import { SuperPower } from "./types";
import { Card } from "react-bootstrap";
import SectionHeader from "./SectionHeader";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  readonly superPower: SuperPower | null;
}

export default function TeamSuperPower({ superPower }: Props) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleIsCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  if (!superPower) {
    return null;
  }

  const getStrengthsOrRisksCard = (type: string) => {
    const title =
      type === "strengths"
        ? "Strengths Of This Superpower"
        : "Risks Of This Superpower";

    const bulletPoint =
      type === "strengths" ? (
        <FontAwesomeIcon icon="check" color="#009952" />
      ) : (
        <FontAwesomeIcon icon="times" color="#D50057" />
      );
    const arrayToMap =
      type === "strengths" ? superPower.strengths : superPower.risks;

    let counter = 0;
    return (
      <Card
        style={{ padding: "16px 20px" }}
        className="workplace-insights-simple-card"
      >
        <h3>{title}</h3>
        {arrayToMap.map((item) => {
          counter += 1;
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "10px",
                paddingTop: "10px",
                fontSize: "14px",
              }}
              key={counter}
            >
              {bulletPoint}
              <div style={{ paddingLeft: "10px" }}>{item}</div>
            </div>
          );
        })}
      </Card>
    );
  };

  if (isCollapsed) {
    return (
      <Card>
        <SectionHeader
          toggleIsCollapsed={toggleIsCollapsed}
          title="Team Superpower"
          isCollapsed={isCollapsed}
        />
      </Card>
    );
  }

  return (
    <Card className="column-gap-20px">
      <SectionHeader
        toggleIsCollapsed={toggleIsCollapsed}
        title="Team Superpower"
        isCollapsed={isCollapsed}
      />
      <div className="card-with-left-strip column-gap-8px dark-blue card workplace-insights-simple-card-with-side-strip">
        <div>
          <h3>{superPower.trait}</h3>
          <p>{superPower.summary}</p>
        </div>
      </div>
      <div className="responsive-two-column-container">
        {getStrengthsOrRisksCard("strengths")}
        {getStrengthsOrRisksCard("risks")}
      </div>
    </Card>
  );
}
