import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { request } from "utils/request";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { hideModal } from "app/components/Modules/slice";
import {
  ModuleTemplates,
  ModuleType,
  Guide,
  UsedModuleTemplateIds,
  Section,
  AddModulePayload,
  EditModulePayload,
  EditModuleResponse,
} from "app/components/Modules/types";
import { Team360AssessmentResultsResponse } from "app/components/Team360Assessment/types";
import { toast } from "react-toastify";
import { successNotificationOptions } from "utils/constants";
import { updateTeamImage } from "app/containers/Global/slice";
import { TeamComments } from "app/containers/AdminConsole/types";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import {
  TeamAnalyticsText,
  Team360Status,
  DimensionsAndFactorsTipsAndInterpretiveText,
  ConversationsToSuggestPayload,
  SavedConversationsToSuggest,
  ConversationPartner,
  ConversationPartnerType,
  TeamVisibilitySettings,
} from "./types";
import { TIME_INTERVAL_OPTIONS } from "app/components/InstancePicker/constants";
import { InstanceShowing } from "app/components/InstancePicker/types";
import { getTeam360Scores } from "app/components/SurveyDataInstances/slice";
import { StartAndEndDateOrInstance } from "app/components/SurveyDataInstances/types";
import { getAppendedUrl } from "app/components/InstancePicker/helpers";
import { TeamAssessmentInstance } from "app/containers/Assessment/types";

// ------------------ State Type/Structure ------------------
export interface TeamGuideState {
  moduleTemplates: ModuleTemplates | null;
  teamGuides: { [teamId: number]: Guide };
  teamConnectionSection: { [teamId: number]: Section | undefined };
  usedTeamTemplateIds: { [teamId: number]: UsedModuleTemplateIds }; // used just to keep track of which ones the user has already enabled so we don't show duplicates
  teamAnalyticsText: {
    [teamId: number]: TeamAnalyticsText;
  };
  team360StatusByTeamId: {
    [teamId: number]: Team360Status;
  };
  TipsAndInterpretiveText: {
    [teamId: number]: DimensionsAndFactorsTipsAndInterpretiveText | null;
  };
  userTeam360AssessmentResults: {
    [teamId: number]: Team360AssessmentResultsResponse;
  };
  teamCommentsById: {
    [teamId: number]: TeamComments;
  };
  team360InstancePicked: {
    [teamId: number]: InstanceShowing;
  };
  conversationsToSuggest: {
    [teamId: number]: SavedConversationsToSuggest | null;
  };
  teamSurveyInstances: {
    [teamId: number]: TeamAssessmentInstance[];
  };
  hasTeamChangedDateRangeForSurvey: {
    [teamId: number]: {
      [surveyId: number]: boolean;
    };
  };
  team360CardsExpanded: {
    [teamId: number]: {
      [cardTitle: string]: boolean;
    };
  };
  teamVisibilitySettings: {
    [teamId: number]: TeamVisibilitySettings | null;
  };
  getTeamVisibilityStatus: responseStatus;
  updateTeamVisibilityStatus: responseStatus;
  getConversationToSuggestStatus: responseStatus;
  getTeamAssessmentInstancesStatus: responseStatus;
  getTeamCommentsStatus: responseStatus;
  getIndividualTeam360AssessmentResultsStatus: responseStatus;
  getTipsAndInterpretiveTextStatus: responseStatus;
  getTeam360StatusByTeamIdStatus: responseStatus;
  getTeamAnalyticsTextStatus: responseStatus;
  addModuleToTeamGuideStatus: responseStatus;
  deleting: responseStatus;
  deletingGuidePhotoStatus: responseStatus;
  editTeamGuideModuleStatus: responseStatus;
  getModuleTemplatesStatus: responseStatus;
  getTeamGuideStatus: responseStatus;
  getTEAMScoresStatus: responseStatus;
  removeTeamMemberFromTeamStatus: responseStatus;
  updateTeamMemberByTeamMemberId: responseStatus;
  updatingGuidePhotoStatus: responseStatus;
  updatingTeam360DateRangeStatus: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: TeamGuideState = {
  moduleTemplates: null,
  teamGuides: {},
  teamConnectionSection: {},
  usedTeamTemplateIds: {},
  teamAnalyticsText: {},
  team360StatusByTeamId: {},
  TipsAndInterpretiveText: {},
  userTeam360AssessmentResults: {},
  teamCommentsById: {},
  team360InstancePicked: {},
  conversationsToSuggest: {},
  hasTeamChangedDateRangeForSurvey: {},
  teamSurveyInstances: {},
  team360CardsExpanded: {},
  teamVisibilitySettings: {},
  getTeamVisibilityStatus: "idle",
  updateTeamVisibilityStatus: "idle",
  getConversationToSuggestStatus: "idle",
  getTeamCommentsStatus: "idle",
  getIndividualTeam360AssessmentResultsStatus: "idle",
  getTipsAndInterpretiveTextStatus: "idle",
  getTeamAnalyticsTextStatus: "idle",
  addModuleToTeamGuideStatus: "idle",
  deleting: "idle",
  editTeamGuideModuleStatus: "idle",
  getModuleTemplatesStatus: "idle",
  getTeamGuideStatus: "idle",
  getTEAMScoresStatus: "idle",
  removeTeamMemberFromTeamStatus: "idle",
  updateTeamMemberByTeamMemberId: "idle",
  updatingGuidePhotoStatus: "idle",
  deletingGuidePhotoStatus: "idle",
  updatingTeam360DateRangeStatus: "idle",
  getTeamAssessmentInstancesStatus: "idle",
  getTeam360StatusByTeamIdStatus: "idle",
};

// ------------------ Helper Thunks ------------------
export const updateTeam360DateRange = createAsyncThunk(
  "teamGuide/updateTeam360DateRange",
  async (
    {
      teamId,
      startDate,
      endDate,
      instance,
      instanceType,
      throwErrorIfNoOverview,
    }: {
      teamId: number;
      instanceType?: (typeof TIME_INTERVAL_OPTIONS)[number];
      throwErrorIfNoOverview?: boolean;
    } & StartAndEndDateOrInstance,
    thunkAPI
  ) => {
    const dateRange = { startDate, endDate, instance };
    const resp = (await thunkAPI.dispatch(
      getTeamAnalyticsText({ teamId, ...dateRange, throwErrorIfNoOverview })
    )) as { error?: any };

    if (resp?.error) {
      throw new Error("No overview found");
    }

    thunkAPI.dispatch(updateHasTeamChangedDateRangeForSurvey({ teamId }));
    thunkAPI.dispatch(clearConversationPartnersForTeam(teamId));
    await Promise.all([
      thunkAPI.dispatch(
        getIndividualTeam360AssessmentResults({ teamId, ...dateRange })
      ),
      thunkAPI.dispatch(getTeamDisplayStatusByTeamId({ teamId, ...dateRange })),
      thunkAPI.dispatch(getTipsAndInterpretiveText({ teamId, ...dateRange })),
      thunkAPI.dispatch(getTeamComments({ teamId, ...dateRange })),
      thunkAPI.dispatch(getTeam360Scores({ ...dateRange })),
    ]);
    return { teamId, startDate, endDate, instanceType, instance };
  }
);

// ------------------------------------ GET Requests ------------------------------------
export const getModuleTemplates = createAsyncThunk(
  "teamGuide/getModuleTemplates",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/moduleTemplates/teamGuide`;
    return (await request(requestUrl)) as ModuleTemplates;
  },
  {
    condition: (e, { getState }) => {
      const {
        teamGuide: { getModuleTemplatesStatus, moduleTemplates },
      } = getState() as RootState;

      if (getModuleTemplatesStatus === "loading" || moduleTemplates) {
        return false;
      }
    },
  }
);

export const getTeamGuide = createAsyncThunk(
  "teamGuide/getTeamGuide",
  async (teamId: number, thunkAPI) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/guides/teamGuides/${teamId}`;
    const getTeamGuideResponse = (await request(requestUrl)) as {
      [teamGuideId: number]: Guide;
    };

    thunkAPI.dispatch(
      setTeamGuide({ teamGuideResponse: getTeamGuideResponse, teamId })
    );
    return getTeamGuideResponse;
  },
  {
    condition: (teamId: number, { getState }) => {
      const {
        teamGuide: { teamGuides, getTeamGuideStatus },
      } = getState() as RootState;

      if (getTeamGuideStatus === "loading" || teamGuides[teamId]) {
        return false;
      }
    },
  }
);

export const getTeamAnalyticsText = createAsyncThunk(
  "teamGuide/getTeamAnalyticsText",
  async ({
    teamId,
    startDate,
    endDate,
    instance,
    throwErrorIfNoOverview,
  }: {
    teamId: number;
    throwErrorIfNoOverview?: boolean;
  } & StartAndEndDateOrInstance) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/organizationInsightReport${getAppendedUrl(
      {
        startDate,
        endDate,
        instance,
      }
    )}`;
    const getTeamAnalyticsTextResponse = (await request(
      requestUrl
    )) as TeamAnalyticsText;

    if (
      throwErrorIfNoOverview &&
      (startDate || endDate || instance) &&
      !getTeamAnalyticsTextResponse?.overview &&
      !getTeamAnalyticsTextResponse?.completionInfo?.totalInvited
    ) {
      throw new Error("No overview found");
    }

    return { response: getTeamAnalyticsTextResponse, teamId };
  } // Commenting out because this restricts us from making multiple calls to this endpoint at the same time.
  // {
  //   condition: (e, { getState }) => {
  //     const {
  //       teamGuide: { getTeamAnalyticsTextStatus },
  //     } = getState() as RootState;

  //     if (getTeamAnalyticsTextStatus === "loading") {
  //       return false;
  //     }
  //   },
  // }
);

export const getTeamDisplayStatusByTeamId = createAsyncThunk(
  "teamGuide/getTeamDisplayStatusByTeamId",
  async ({
    teamId,
    startDate,
    endDate,
    instance,
  }: { teamId: number } & StartAndEndDateOrInstance) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/displayStatus${getAppendedUrl(
      {
        startDate,
        endDate,
        instance,
      }
    )}`;
    const response = (await request(requestUrl)) as Team360Status;

    return { response, teamId };
  } // Commenting out because this restricts us from making multiple calls to this endpoint at the same time.
  // {
  //   condition: (e, { getState }) => {
  //     const {
  //       teamGuide: { getTeam360StatusByTeamIdStatus },
  //     } = getState() as RootState;

  //     if (getTeam360StatusByTeamIdStatus === "loading") {
  //       return false;
  //     }
  //   },
  // }
);

export const getTeamHasBeenNudged = createAsyncThunk(
  "teamGuide/getTeamHasBeenNudged",
  async (teamId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/checkIfTeamHasBeenNudged`;
    return (await request(requestUrl)) as {
      alreadyBeenNudged: boolean;
    };
  }
);

export const getNudgeTeamMembersByTeamId = createAsyncThunk(
  "teamGuide/getNudgeTeamMembersByTeamId",
  async (teamId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/nudgeTeamMembers`;
    return (await request(requestUrl)) as {
      usersReminded: number;
    };
  }
);

export const getTipsAndInterpretiveText = createAsyncThunk(
  "teamGuide/getTipsAndInterpretiveText",
  async ({
    teamId,
    startDate,
    endDate,
    instance,
  }: { teamId: number } & StartAndEndDateOrInstance) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/getTipsAndInterpretiveText${getAppendedUrl(
      {
        startDate,
        endDate,
        instance,
      }
    )}`;
    const response = (await request(
      requestUrl
    )) as DimensionsAndFactorsTipsAndInterpretiveText;

    return { response, teamId };
  }
);

// This endpoint will return the users’s scores for this team, and the overall team’s scores
export const getIndividualTeam360AssessmentResults = createAsyncThunk(
  "teamGuide/getIndividualTeam360AssessmentResults",
  async ({
    teamId,
    startDate,
    endDate,
    instance,
  }: { teamId: number } & StartAndEndDateOrInstance) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/teamScores${getAppendedUrl(
      {
        startDate,
        endDate,
        instance,
      }
    )}`;
    const response = (await request(
      requestUrl
    )) as Team360AssessmentResultsResponse;
    return { response, teamId };
  }
);

export const getTeamComments = createAsyncThunk(
  "teamGuide/getTeamComments",
  async ({
    teamId,
    startDate,
    endDate,
    instance,
  }: { teamId: number } & StartAndEndDateOrInstance) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/comments${getAppendedUrl(
      {
        startDate,
        endDate,
        instance,
      }
    )}`;

    const response = (await request(requestUrl)) as TeamComments;

    return { response, teamId };
  }
);

export const getConversationPartners = createAsyncThunk(
  "teamGuide/getConversationPartners",
  async (
    {
      userAccountId,
      teamId,
      conversationPartner,
      conversationPartnerType = "teamMemberOnThisTeam",
      endDate,
      startDate,
      instance,
    }: ConversationsToSuggestPayload & StartAndEndDateOrInstance,
    thunkAPI
  ) => {
    const {
      teamGuide: { conversationsToSuggest },
    } = thunkAPI.getState() as RootState;
    const comparedPartner = conversationPartner ?? conversationPartnerType;

    // If we already have the conversation partners in the state, return them.
    if (
      conversationsToSuggest[teamId]?.conversationToBeHad?.[userAccountId]?.[
        comparedPartner
      ] !== undefined
    ) {
      return {
        response: conversationsToSuggest[teamId]?.conversationToBeHad[
          userAccountId
        ][comparedPartner] as ConversationPartner[],
        teamId,
        userAccountId,
        conversationPartner,
        conversationPartnerType,
        endDate,
        startDate,
        instance,
      };
    }
    let requestUrl = `${REACT_APP_API_URL}/talentInsights/getConversationPartners/users/${userAccountId}/teams/${teamId}`;

    const appendedUrlParams = getAppendedUrl({ startDate, endDate, instance });
    requestUrl += appendedUrlParams;
    requestUrl += appendedUrlParams?.length ? "&" : "?";

    if (conversationPartner) {
      requestUrl += `conversationPartner=${conversationPartner}`;
    } else {
      requestUrl += `conversationPartnerType=${conversationPartnerType}`;
    }

    const response = (await request(requestUrl)) as ConversationPartner[];

    return {
      response,
      teamId,
      userAccountId,
      conversationPartner,
      conversationPartnerType,
      endDate,
      startDate,
      instance,
    };
  }
);

export const getTeamAssessmentInstances = createAsyncThunk(
  "teamGuide/getTeamAssessmentInstances",
  async (payload: { teamId: number; forceRefresh?: boolean }) => {
    const { teamId } = payload;
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/teamAssessmentsInstances`;
    const response = (await request(requestUrl)) as {
      [assessmentInstanceId: number]: TeamAssessmentInstance;
    };

    const responseArray: TeamAssessmentInstance[] = [];
    Object.entries(response).forEach(([assessmentInstanceId, instance]) => {
      responseArray.push({
        ...instance,
        assessmentInstanceId: Number(assessmentInstanceId),
      });
    });
    responseArray.sort((a, b) => {
      const aStartDate = new Date(a.startDate).getTime();
      const bStartDate = new Date(b.startDate).getTime();
      return bStartDate - aStartDate;
    });

    return { response: responseArray, teamId };
  },
  {
    condition: (
      payload: { teamId: number; forceRefresh?: boolean },
      { getState }
    ) => {
      const {
        teamGuide: { teamSurveyInstances, getTeamAssessmentInstancesStatus },
      } = getState() as RootState;
      const { teamId, forceRefresh } = payload;
      if (forceRefresh) {
        return true;
      }

      if (
        getTeamAssessmentInstancesStatus === "loading" ||
        teamSurveyInstances[teamId] // might remove this so that we can refetch the data
      ) {
        return false;
      }
    },
  }
);

export const getTeamVisibility = createAsyncThunk(
  "teamGuide/getTeamVisibility",
  async ({ teamId }: { teamId: number; forceRefresh?: boolean }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/visibility`;
    const response = (await request(requestUrl)) as TeamVisibilitySettings;

    return {
      response,
      teamId,
    };
  },
  {
    condition: ({ teamId, forceRefresh }, { getState }) => {
      const {
        teamGuide: { teamVisibilitySettings },
      } = getState() as RootState;

      if (teamVisibilitySettings[teamId] && !forceRefresh) {
        return false;
      }
    },
  }
);

// ------------------------------------ POSTS Requests ------------------------------------
export const addModuleToTeamGuide = createAsyncThunk(
  "teamGuide/addModuleToTeamGuide",
  async (
    { payload, teamId }: { payload: AddModulePayload; teamId: number },
    thunkAPI
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teamGuides/${teamId}/teamGuideOnBoarding`;
    const addModuleResponse = (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({ modules: [payload] }),
    })) as { [teamGuideId: number]: Guide };

    thunkAPI.dispatch(
      setTeamGuide({ teamGuideResponse: addModuleResponse, teamId })
    );
    thunkAPI.dispatch(hideModal());

    return addModuleResponse;
  }
);

// ------------------------------------ PUT Requests ------------------------------------
export const editTeamGuideModule = createAsyncThunk(
  "teamGuide/editTeamGuideModule",
  async (
    { payload, teamId }: { payload: EditModulePayload; teamId: number },
    thunkAPI
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/modules`;
    const editModuleResponse = (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({ modules: [payload] }),
    })) as EditModuleResponse[];
    thunkAPI.dispatch(hideModal());
    return { response: editModuleResponse, teamId };
  }
);

export const updateGuidePhoto = createAsyncThunk(
  "teamGuide/updateGuidePhoto",
  async (
    {
      photoType,
      photo,
      imageName,
      teamId,
    }: {
      teamId: number;
      imgSrc: string; // used to update the UI
      imageName: string;
      photoType: "coverPhoto" | "profilePicture";
      photo: string;
    },
    thunkAPI
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/guides/teamGuides/${teamId}/photo`;
    const updateGuidePhotoResponse = (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({ imageName, photo, photoType }),
    })) as {
      coverPhotoBase64: string;
      coverPhoto: string;
      profilePicture: string;
      profilePictureBase64: string;
      teamId: number;
    };
    thunkAPI.dispatch(updateTeamImage(updateGuidePhotoResponse));
    return updateGuidePhotoResponse;
  }
);

export const updateTeamVisibility = createAsyncThunk(
  "teamGuide/updateTeamVisibility",
  async (
    {
      teamId,
      visibilitySetting,
    }: {
      teamId: number;
      visibilitySetting: Partial<TeamVisibilitySettings>;
    },
    { getState }
  ) => {
    const {
      teamGuide: { teamVisibilitySettings },
    } = getState() as RootState;
    const selectedTeamVisibilitySettings = teamVisibilitySettings[teamId];
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/visibility`;
    const response = (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({
        ...selectedTeamVisibilitySettings,
        ...visibilitySetting,
      }),
    })) as Partial<TeamVisibilitySettings>;

    return {
      response,
      teamId,
    };
  }
);

// ------------------------------------ DELETE Requests ------------------------------------
export const deleteSectionById = createAsyncThunk(
  "teamGuide/deleteSectionById",
  async (
    { sectionId, teamId }: { sectionId: number; teamId: number },
    thunkAPI
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/sections/${sectionId}`;
    const deleteSectionById = await request(requestUrl, {
      method: "DELETE",
    });

    thunkAPI.dispatch(
      removeSectionFromTeamGuide({
        sectionId,
        teamId,
      })
    );
    return deleteSectionById;
  }
);

export const deleteTeamModuleById = createAsyncThunk(
  "teamGuide/deleteTeamModuleById",
  async (
    {
      talentInsightsModuleId,
      teamId,
    }: {
      talentInsightsModuleId: number;
      teamId: number;
    },
    thunkAPI
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/modules/${talentInsightsModuleId}`;
    const deleteModuleResponse = (await request(requestUrl, {
      method: "DELETE",
    })) as { [teamGuideId: number]: Guide };

    thunkAPI.dispatch(
      setTeamGuide({
        teamGuideResponse: deleteModuleResponse,
        teamId,
      })
    );
    thunkAPI.dispatch(hideModal());
    return deleteModuleResponse;
  }
);

export const deleteGuidePhoto = createAsyncThunk(
  "teamGuide/deleteGuidePhoto",
  async ({
    teamId,
    photoType,
  }: {
    teamId: number;
    photoType: "coverPhoto" | "profilePicture";
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/guides/teamGuides/${teamId}/${photoType}`;

    await request(requestUrl, {
      method: "DELETE",
    });

    return { teamId, photoType };
  }
);

// ------------------ Beginning of Slice Definition ------------------
export const teamGuideSlice = createSlice({
  name: "teamGuide",
  initialState,
  reducers: {
    setTeamGuide(
      state,
      {
        payload: { teamId, teamGuideResponse },
      }: PayloadAction<{
        teamGuideResponse: { [teamGuideId: number]: Guide };
        teamId: number;
      }>
    ) {
      // If the userGuideResponse is empty, return.
      if (Object.keys(teamGuideResponse).length === 0) {
        return;
      }

      const incomingTeamGuide = Object.values(teamGuideResponse)[0];
      const teamGuideContent = incomingTeamGuide.content;
      const teamGuideContentArray = Object.values(teamGuideContent);

      // Pulls out the section with the title 'Connect' and adds it to the teamConnectionSection state.
      const connectSection = teamGuideContentArray.find(
        (sectionObj) => sectionObj.title === "Connect"
      );

      const usedModules = {
        "Free Text": [],
        List: [],
      } as UsedModuleTemplateIds;

      // Goes through all of the user modules and adds the moduleTemplateId, to determine which templates the user has already used.
      teamGuideContentArray.forEach((sectionObj) => {
        if (!sectionObj.modules) {
          return;
        }
        Object.values(sectionObj.modules).forEach(
          ({ moduleType, moduleTemplateId }) => {
            if (moduleType in usedModules) {
              usedModules[moduleType].push(moduleTemplateId);
            }
          }
        );
      });

      if (connectSection !== undefined) {
        delete teamGuideContent[connectSection?.sectionId];
      }

      return {
        ...state,
        teamConnectionSection: {
          ...state.teamConnectionSection,
          [teamId]: connectSection,
        },
        teamGuides: {
          ...state.teamGuides,
          [teamId]: {
            ...state.teamGuides[teamId],
            ...incomingTeamGuide,
            content: teamGuideContent,
          },
        },
        usedTeamTemplateIds: {
          ...state.usedTeamTemplateIds,
          [teamId]: usedModules,
        },
      };
    },
    addModuleTemplateIdToSet(
      state,
      {
        payload: { moduleTemplateId, moduleType, teamId },
      }: PayloadAction<{
        moduleTemplateId: number;
        moduleType: ModuleType;
        teamId: number;
      }>
    ) {
      // If the moduleId already exist lets not have duplicates
      if (
        state.usedTeamTemplateIds[teamId][moduleType].includes(moduleTemplateId)
      ) {
        return;
      }

      return {
        ...state,
        usedTeamTemplateIds: {
          ...state.usedTeamTemplateIds,
          [teamId]: {
            ...state.usedTeamTemplateIds[teamId],
            [moduleType]: [
              ...state.usedTeamTemplateIds[teamId][moduleType],
              moduleTemplateId,
            ],
          },
        },
      };
    },
    removeModuleTemplateIdFromSet(
      state,
      {
        payload: { moduleTemplateId, moduleType, teamId },
      }: PayloadAction<{
        moduleTemplateId: number;
        moduleType: ModuleType;
        teamId: number;
      }>
    ) {
      return {
        ...state,
        usedTeamTemplateIds: {
          ...state.usedTeamTemplateIds,
          [teamId]: {
            ...state.usedTeamTemplateIds[teamId],
            [moduleType]: state.usedTeamTemplateIds[teamId][moduleType].filter(
              (templateId) => templateId !== moduleTemplateId
            ),
          },
        },
      };
    },
    removeSectionFromTeamGuide(
      state,
      {
        payload: { sectionId, teamId },
      }: PayloadAction<{ sectionId: number; teamId: number }>
    ) {
      if (!state.teamGuides?.[teamId]) {
        return;
      }

      const teamGuideContent = Object.assign(
        {},
        state.teamGuides[teamId].content
      );
      const usedTeamTemplateIds = Object.assign(
        {},
        state.usedTeamTemplateIds[teamId]
      );

      Object.values(teamGuideContent[sectionId].modules).forEach(
        ({ moduleTemplateId, moduleType }) => {
          if (moduleType in usedTeamTemplateIds) {
            usedTeamTemplateIds[moduleType] = usedTeamTemplateIds[
              moduleType
            ].filter((templateId) => templateId !== moduleTemplateId);
          }
        }
      );
      delete teamGuideContent[sectionId];

      return {
        ...state,
        teamGuides: {
          ...state.teamGuides,
          [teamId]: {
            ...state.teamGuides[teamId],
            content: teamGuideContent,
          },
        },
        usedTeamTemplateIds: {
          ...state.usedTeamTemplateIds,
          [teamId]: usedTeamTemplateIds,
        },
      };
    },
    clearDeleteStatus(state) {
      state.deleting = "idle";
    },
    resetUpdateTeam360DateRangeStatus(state) {
      state.updatingTeam360DateRangeStatus = "idle";
    },
    updateHasTeamChangedDateRangeForSurvey(
      state,
      {
        payload: { teamId, surveyId, hasChanged },
      }: PayloadAction<{
        teamId: number;
        surveyId?: number;
        hasChanged?: boolean;
      }>
    ) {
      const trueSurveyId = surveyId ?? 1;
      const trueHasChanged = hasChanged ?? true;
      return {
        ...state,
        hasTeamChangedDateRangeForSurvey: {
          ...state.hasTeamChangedDateRangeForSurvey,
          [teamId]: {
            ...state.hasTeamChangedDateRangeForSurvey[teamId], // This value is used to not show the onboarding modal incase the user changes the date range
            [trueSurveyId]: trueHasChanged,
          },
        },
      };
    },
    clearConversationPartnersForTeam(
      state,
      { payload: teamId }: PayloadAction<number>
    ) {
      return {
        ...state,
        conversationsToSuggest: {
          ...state.conversationsToSuggest,
          [teamId]: null,
        },
        getConversationToSuggestStatus: "idle",
      };
    },
    clearGetConversationStatus: (state) => {
      state.getConversationToSuggestStatus = "idle";
    },
    setTeam360CardsExpanded: (
      state,
      {
        payload: { teamId, value, cardTitle },
      }: PayloadAction<{ teamId: number; value: boolean; cardTitle: string }>
    ) => {
      return {
        ...state,
        team360CardsExpanded: {
          ...state.team360CardsExpanded,
          [teamId]: {
            ...state.team360CardsExpanded[teamId],
            [cardTitle]: value,
          },
        },
      };
    },
    clearTeam360ExpandedCards: (
      state,
      { payload: teamId }: PayloadAction<number>
    ) => {
      return {
        ...state,
        team360CardsExpanded: {
          ...state.team360CardsExpanded,
          [teamId]: {},
        },
      };
    },
    updateTeamScanInstanceInformation: (
      state,
      {
        payload: { teamId, updatedInfo, id },
      }: PayloadAction<{
        teamId: number;
        updatedInfo: Partial<TeamAssessmentInstance>;
        id: number;
      }>
    ) => {
      const updatedTeamSurveyInstances = state.teamSurveyInstances[teamId].map(
        (instance) => {
          if (instance.assessmentInstanceId === id) {
            return {
              ...instance,
              ...updatedInfo,
            };
          }
          return instance;
        }
      );
      return {
        ...state,
        teamSurveyInstances: {
          ...state.teamSurveyInstances,
          [teamId]: updatedTeamSurveyInstances,
        },
      };
    },
    clearTeamVisibilitySettings: (state) => {
      return {
        ...state,
        teamVisibilitySettings: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getModuleTemplates.pending, (state) => {
        state.getModuleTemplatesStatus = "loading";
      })
      .addCase(
        getModuleTemplates.fulfilled,
        (state, action: PayloadAction<ModuleTemplates>) => {
          state.moduleTemplates = action.payload;
          state.getModuleTemplatesStatus = "succeeded";
        }
      )
      .addCase(getModuleTemplates.rejected, (state) => {
        state.getModuleTemplatesStatus = "failed";
      })
      .addCase(getTeamGuide.pending, (state) => {
        state.getTeamGuideStatus = "loading";
      })
      .addCase(getTeamGuide.fulfilled, (state) => {
        state.getTeamGuideStatus = "succeeded";
      })
      .addCase(getTeamGuide.rejected, (state) => {
        state.getTeamGuideStatus = "failed";
      })
      .addCase(addModuleToTeamGuide.pending, (state) => {
        state.addModuleToTeamGuideStatus = "loading";
      })
      .addCase(addModuleToTeamGuide.fulfilled, (state) => {
        toast.success("Adding Module Successful!", {
          ...successNotificationOptions,
        });
        state.addModuleToTeamGuideStatus = "succeeded";
      })
      .addCase(addModuleToTeamGuide.rejected, (state) => {
        state.addModuleToTeamGuideStatus = "failed";
      })
      .addCase(getTeamVisibility.pending, (state) => {
        state.getTeamVisibilityStatus = "loading";
      })
      .addCase(
        getTeamVisibility.fulfilled,
        (
          state,
          action: PayloadAction<{
            response: TeamVisibilitySettings;
            teamId: number;
          }>
        ) => ({
          ...state,
          getTeamVisibilityStatus: "succeeded",
          teamVisibilitySettings: {
            ...state.teamVisibilitySettings,
            [action.payload.teamId]: action.payload.response,
          },
        })
      )
      .addCase(getTeamVisibility.rejected, (state) => {
        state.getTeamVisibilityStatus = "failed";
      })
      .addCase(updateTeamVisibility.pending, (state) => {
        state.updateTeamVisibilityStatus = "loading";
      })
      .addCase(
        updateTeamVisibility.fulfilled,
        (
          state,
          {
            payload: { response, teamId },
          }: PayloadAction<{
            response: Partial<TeamVisibilitySettings>;
            teamId: number;
          }>
        ) => {
          toast.success("Team Visibility Update Successful!", {
            ...successNotificationOptions,
          });
          const teamVisibilitySetting = state.teamVisibilitySettings[teamId];
          if (!teamVisibilitySetting) {
            return state;
          }

          return {
            ...state,
            updateTeamVisibilityStatus: "succeeded",
            teamVisibilitySettings: {
              ...state.teamVisibilitySettings,
              [teamId]: {
                ...teamVisibilitySetting,
                ...response,
              },
            },
          };
        }
      )
      .addCase(updateTeamVisibility.rejected, (state) => {
        state.updateTeamVisibilityStatus = "failed";
      })
      .addCase(editTeamGuideModule.pending, (state) => {
        state.editTeamGuideModuleStatus = "loading";
      })
      .addCase(
        editTeamGuideModule.fulfilled,
        (
          state,
          {
            payload: { teamId, response },
          }: PayloadAction<{
            response: EditModuleResponse[];
            teamId: number;
          }>
        ) => {
          // Getting the sectionId from the first module in the response
          const { sectionId } = response[0];
          const modules = Object.assign(
            {},
            state.teamGuides[teamId].content[sectionId].modules
          );
          response.forEach((module) => {
            modules[module.talentInsightsModuleId] = {
              ...modules[module.talentInsightsModuleId],
              ...module,
            };
          });

          return {
            ...state,
            editTeamGuideModuleStatus: "succeeded",
            teamGuides: {
              ...state.teamGuides,
              [teamId]: {
                ...state.teamGuides[teamId],
                content: {
                  ...state.teamGuides[teamId].content,
                  [sectionId]: {
                    ...state.teamGuides[teamId].content[sectionId],
                    modules,
                  },
                },
              },
            },
          };
        }
      )
      .addCase(editTeamGuideModule.rejected, (state) => {
        state.editTeamGuideModuleStatus = "failed";
      })
      .addCase(deleteTeamModuleById.pending, (state) => {
        state.deleting = "loading";
      })
      .addCase(deleteTeamModuleById.fulfilled, (state) => {
        toast.success("Deleting Module Successful!", {
          ...successNotificationOptions,
        });
        state.deleting = "succeeded";
      })
      .addCase(deleteTeamModuleById.rejected, (state) => {
        state.deleting = "failed";
      })
      .addCase(deleteSectionById.pending, (state) => {
        state.deleting = "loading";
      })
      .addCase(deleteSectionById.fulfilled, (state) => {
        toast.success("Deleting Section Successful!", {
          ...successNotificationOptions,
        });
        state.deleting = "succeeded";
      })
      .addCase(deleteSectionById.rejected, (state) => {
        state.deleting = "failed";
      })
      .addCase(updateGuidePhoto.pending, (state) => {
        state.updatingGuidePhotoStatus = "loading";
      })
      .addCase(
        updateGuidePhoto.fulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            coverPhotoBase64: string;
            coverPhoto: string;
            profilePicture: string;
            profilePictureBase64: string;
            teamId: number;
          }>
        ) => {
          toast.success("Updating Photo Successful!", {
            ...successNotificationOptions,
          });
          return {
            ...state,
            updatingGuidePhotoStatus: "succeeded",
            teamGuides: {
              ...state.teamGuides,
              [payload.teamId]: {
                ...state.teamGuides[payload.teamId],
                coverPhoto: {
                  picture: payload.coverPhoto,
                  base64: payload.coverPhotoBase64,
                },
                profilePicture: {
                  picture: payload.profilePicture,
                  base64: payload.profilePictureBase64,
                },
              },
            },
          };
        }
      )
      .addCase(updateGuidePhoto.rejected, (state) => {
        state.updatingGuidePhotoStatus = "failed";
      })
      .addCase(deleteGuidePhoto.pending, (state) => {
        state.deletingGuidePhotoStatus = "loading";
      })
      .addCase(
        deleteGuidePhoto.fulfilled,
        (
          state,
          action: PayloadAction<{
            teamId: number;
            photoType: "coverPhoto" | "profilePicture";
          }>
        ) => {
          const photoType =
            action.payload.photoType === "coverPhoto"
              ? "Cover Photo"
              : "Profile Picture";
          toast.success(`Deleting ${photoType} Successful!`, {
            ...successNotificationOptions,
          });
          return {
            ...state,
            deletingGuidePhotoStatus: "succeeded",
            teamGuides: {
              ...state.teamGuides,
              [action.payload.teamId]: {
                ...state.teamGuides[action.payload.teamId],
                [action.payload.photoType]: {
                  picture: null,
                  base64: null,
                },
              },
            },
          };
        }
      )
      .addCase(deleteGuidePhoto.rejected, (state) => {
        state.deletingGuidePhotoStatus = "failed";
      })
      .addCase(getTeamAnalyticsText.pending, (state) => {
        state.getTeamAnalyticsTextStatus = "loading";
      })
      .addCase(
        getTeamAnalyticsText.fulfilled,
        (
          state,
          action: PayloadAction<{ response: TeamAnalyticsText; teamId: number }>
        ) => {
          return {
            ...state,
            getTeamAnalyticsTextStatus: "succeeded",
            teamAnalyticsText: {
              ...state.teamAnalyticsText,
              [action.payload.teamId]: action.payload.response,
            },
          };
        }
      )
      .addCase(getTeamAnalyticsText.rejected, (state) => {
        state.getTeamAnalyticsTextStatus = "failed";
      })
      .addCase(getTeamDisplayStatusByTeamId.pending, (state) => {
        state.getTeam360StatusByTeamIdStatus = "loading";
      })
      .addCase(
        getTeamDisplayStatusByTeamId.fulfilled,
        (
          state,
          {
            payload: { teamId, response },
          }: PayloadAction<{ response: Team360Status; teamId: number }>
        ) => {
          return {
            ...state,
            getTeam360StatusByTeamIdStatus: "succeeded",
            team360StatusByTeamId: {
              ...state.team360StatusByTeamId,
              [teamId]: response,
            },
          };
        }
      )
      .addCase(getTeamDisplayStatusByTeamId.rejected, (state) => {
        state.getTeam360StatusByTeamIdStatus = "failed";
      })
      .addCase(getTipsAndInterpretiveText.pending, (state) => {
        state.getTipsAndInterpretiveTextStatus = "loading";
      })
      .addCase(
        getTipsAndInterpretiveText.fulfilled,
        (
          state,
          {
            payload: { teamId, response },
          }: PayloadAction<{
            response: DimensionsAndFactorsTipsAndInterpretiveText;
            teamId: number;
          }>
        ) => {
          return {
            ...state,
            getTipsAndInterpretiveTextStatus: "succeeded",
            TipsAndInterpretiveText: {
              ...state.TipsAndInterpretiveText,
              [teamId]: response,
            },
          };
        }
      )
      .addCase(getTipsAndInterpretiveText.rejected, (state, { payload }) => {
        state.getTipsAndInterpretiveTextStatus = "failed";
      })
      .addCase(getIndividualTeam360AssessmentResults.pending, (state) => {
        state.getIndividualTeam360AssessmentResultsStatus = "loading";
      })
      .addCase(
        getIndividualTeam360AssessmentResults.fulfilled,
        (
          state,
          {
            payload: { response, teamId },
          }: PayloadAction<{
            response: Team360AssessmentResultsResponse;
            teamId: number;
          }>
        ) => {
          return {
            ...state,
            getIndividualTeam360AssessmentResultsStatus: "succeeded",
            userTeam360AssessmentResults: {
              ...state.userTeam360AssessmentResults,
              [teamId]: response,
            },
          };
        }
      )
      .addCase(getIndividualTeam360AssessmentResults.rejected, (state) => {
        state.getIndividualTeam360AssessmentResultsStatus = "failed";
      })
      .addCase(getTeamComments.pending, (state) => {
        state.getTeamCommentsStatus = "loading";
      })
      .addCase(
        getTeamComments.fulfilled,
        (
          state,
          {
            payload: { response, teamId },
          }: PayloadAction<{
            response: TeamComments;
            teamId: number;
          }>
        ) => {
          return {
            ...state,
            getTeamCommentsStatus: "succeeded",
            teamCommentsById: {
              ...state.teamCommentsById,
              [teamId]: response,
            },
          };
        }
      )
      .addCase(getTeamComments.rejected, (state) => {
        state.getTeamCommentsStatus = "failed";
      })
      .addCase(updateTeam360DateRange.pending, (state) => {
        state.updatingTeam360DateRangeStatus = "loading";
      })
      .addCase(
        updateTeam360DateRange.fulfilled,
        (
          state,
          {
            payload: { teamId, startDate, endDate, instanceType, instance },
          }: PayloadAction<
            {
              teamId: number;
              instanceType?: (typeof TIME_INTERVAL_OPTIONS)[number];
            } & StartAndEndDateOrInstance
          >
        ) => {
          return {
            ...state,
            updatingTeam360DateRangeStatus: "succeeded",
            team360InstancePicked: {
              ...state.team360InstancePicked,
              [teamId]: {
                instanceType: instanceType ?? null,
                startDate,
                endDate,
                instance,
              },
            },
          };
        }
      )
      .addCase(updateTeam360DateRange.rejected, (state) => {
        state.updatingTeam360DateRangeStatus = "failed";
      })
      .addCase(getConversationPartners.pending, (state) => {
        state.getConversationToSuggestStatus = "loading";
      })
      .addCase(
        getConversationPartners.fulfilled,
        (
          state,
          {
            payload: {
              response,
              teamId,
              userAccountId,
              conversationPartner,
              conversationPartnerType,
            },
          }: PayloadAction<{
            response: ConversationPartner[];
            teamId: number;
            userAccountId: number;
            conversationPartner?: number;
            conversationPartnerType: ConversationPartnerType;
          }>
        ) => {
          const conversationPartnerKey =
            conversationPartner ?? conversationPartnerType;
          return {
            ...state,
            getConversationToSuggestStatus: "succeeded",
            conversationsToSuggest: {
              ...state.conversationsToSuggest,
              [teamId]: {
                ...state.conversationsToSuggest[teamId],
                activeUserAccountId: userAccountId,
                activeComparedTo: conversationPartnerKey,
                conversationToBeHad: {
                  ...state.conversationsToSuggest[teamId]?.conversationToBeHad,
                  [userAccountId]: {
                    ...state.conversationsToSuggest[teamId]
                      ?.conversationToBeHad?.[userAccountId],
                    [conversationPartnerKey]: response,
                  },
                },
              },
            },
          };
        }
      )
      .addCase(getConversationPartners.rejected, (state) => {
        state.getConversationToSuggestStatus = "failed";
      })
      .addCase(getTeamAssessmentInstances.pending, (state) => {
        state.getTeamAssessmentInstancesStatus = "loading";
      })
      .addCase(
        getTeamAssessmentInstances.fulfilled,
        (
          state,
          action: PayloadAction<{
            response: TeamAssessmentInstance[];
            teamId: number;
          }>
        ) => {
          return {
            ...state,
            getTeamAssessmentInstancesStatus: "succeeded",
            teamSurveyInstances: {
              ...state.teamSurveyInstances,
              [action.payload.teamId]: action.payload.response,
            },
          };
        }
      )

      .addCase(getTeamAssessmentInstances.rejected, (state, e) => {
        const teamId = e.meta?.arg?.teamId;
        return {
          ...state,
          getTeamAssessmentInstancesStatus: "failed",
          teamSurveyInstances: {
            ...state.teamSurveyInstances,
            [teamId]: [],
          },
        };
      });
  },
});

export const {
  addModuleTemplateIdToSet,
  removeModuleTemplateIdFromSet,
  removeSectionFromTeamGuide,
  setTeamGuide,
  clearDeleteStatus,
  resetUpdateTeam360DateRangeStatus,
  updateHasTeamChangedDateRangeForSurvey,
  clearConversationPartnersForTeam,
  clearGetConversationStatus,
  setTeam360CardsExpanded,
  updateTeamScanInstanceInformation,
  clearTeamVisibilitySettings,
} = teamGuideSlice.actions;

// ------------------ Selectors ------------------
export const selectTeamModuleTemplates = (state: RootState) =>
  state.teamGuide.moduleTemplates;
export const selectGetModuleTemplatesStatus = (state: RootState) =>
  state.teamGuide.getModuleTemplatesStatus;
export const selectGetTeamGuideStatus = (state: RootState) =>
  state.teamGuide.getTeamGuideStatus;
export const selectTeamGuide = (state: RootState) => state.teamGuide.teamGuides;
export const selectTeamConnectSection = (state: RootState) =>
  state.teamGuide.teamConnectionSection;
export const selectUsedTeamTemplateIds = (state: RootState) =>
  state.teamGuide.usedTeamTemplateIds;
export const selectUpdatingGuidePhotoStatus = (state: RootState) =>
  state.teamGuide.updatingGuidePhotoStatus;
export const selectDeletingGuidePhotoStatus = (state: RootState) =>
  state.teamGuide.deletingGuidePhotoStatus;
export const selectDeletingStatus = (state: RootState) =>
  state.teamGuide.deleting;
export const selectGetTeamAnalyticsTextStatus = (state: RootState) =>
  state.teamGuide.getTeamAnalyticsTextStatus;
export const selectAllTeamAnalyticsText = (state: RootState) =>
  state.teamGuide.teamAnalyticsText;
export const selectTeamAnalyticsText =
  (teamId?: number) => (state: RootState) =>
    teamId ? state.teamGuide.teamAnalyticsText[teamId] : undefined;
export const selectTeam360StatusByTeamId =
  (teamId?: number) => (state: RootState) =>
    teamId ? state.teamGuide.team360StatusByTeamId[teamId] : null;
export const selectGetTeam360StatusByTeamIdStatus = (state: RootState) =>
  state.teamGuide.getTeam360StatusByTeamIdStatus;
export const selectTipsAndInterpretiveTextByTeamId =
  (teamId?: number) => (state: RootState) =>
    teamId ? state.teamGuide.TipsAndInterpretiveText[teamId] : null;
export const selectAllTeam360StatusByTeamId = (state: RootState) =>
  state.teamGuide.team360StatusByTeamId;
export const selectUserTeam360AssessmentResultsByTeamId =
  (teamId?: number) => (state: RootState) =>
    teamId ? state.teamGuide.userTeam360AssessmentResults[teamId] : null;
export const selectTeamCommentsByTeamId =
  (teamId?: number) => (state: RootState) =>
    teamId ? state.teamGuide.teamCommentsById[teamId] : null;
export const selectUpdateTeam360DateRangeStatus = (state: RootState) =>
  state.teamGuide.updatingTeam360DateRangeStatus;
export const selectTeam360InstancePicked =
  (teamId?: number) => (state: RootState) =>
    teamId ? state.teamGuide.team360InstancePicked[teamId] : null;
export const selectTeamsSuggestedConversations =
  (teamId?: number) => (state: RootState) =>
    teamId ? state.teamGuide.conversationsToSuggest[teamId] : null;
export const selectGetTeamConversationSuggestionsStatus = (state: RootState) =>
  state.teamGuide.getConversationToSuggestStatus;
export const selectTeamSurveyInstancesByTeamId =
  (teamId?: number) => (state: RootState) =>
    teamId ? state.teamGuide.teamSurveyInstances[teamId] : null;
export const selectHasTeamUpdatedSurveyDateRange =
  (teamId?: number, surveyId: number = 1) =>
  (state: RootState) =>
    teamId
      ? state.teamGuide.hasTeamChangedDateRangeForSurvey[teamId]?.[surveyId]
      : null;
export const selectTeam360CardsExpanded =
  (teamId?: number) => (state: RootState) =>
    teamId ? state.teamGuide.team360CardsExpanded[teamId] : null;
export const selectTeamVisibilitySettings =
  (teamId?: number) => (state: RootState) =>
    teamId ? state.teamGuide.teamVisibilitySettings[teamId] : null;

export default teamGuideSlice.reducer;
