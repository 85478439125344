import { memo, useEffect, useState } from "react";
import Button from "app/storybookComponents/Button";
import {
  AgreeablenessResult,
  AgreementState,
  Team360AssessmentResult,
} from "./types";
import {
  ALL_TEAM_360_FACTORS,
  CONSTRUCT_NAME_KEY,
  TEAM360_DIMENSIONS_MAP,
} from "./constants";
import {
  getAgreementTagColor,
  getSplitDimensionByAgreement,
  getTeam360Score,
} from "./helpers";
import { useAppSelector } from "utils/redux/hooks";
import { selectAllDimensionsById } from "app/containers/SkillsGuide/slice";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getEntries } from "utils/helperFunctions";

// Should take two TEAMscan results and show the two skills that have the most difference in scores
interface Props {
  descriptionText?: string;
  score1?: Team360AssessmentResult | null;
  score2?: Team360AssessmentResult | null;
}

const INITIAL_STATE: AgreementState = {
  "Low Agreement": null,
  "Medium Agreement": null,
  "High Agreement": null,
};

const Team360LowAgreementSkillCards = ({
  score1,
  score2,
  descriptionText = "Here are 2 dimensions where you and your teammates had the most differing views:",
}: Props) => {
  const navigate = useNavigate();
  const dimensionsByName = useAppSelector(selectAllDimensionsById);
  const [agreementState, setAgreementState] = useState(INITIAL_STATE);

  useEffect(() => {
    if (!score2 && !score1) {
      return;
    }

    const agreeablenessResults: AgreeablenessResult[] = [];
    ALL_TEAM_360_FACTORS.forEach((factor) => {
      TEAM360_DIMENSIONS_MAP[factor].forEach((dimension) => {
        const dimensionName = CONSTRUCT_NAME_KEY[dimension] ?? dimension;
        const userScore = getTeam360Score(
          score1?.dimensions?.[dimensionName] ?? 0
        );
        const comparedScore = getTeam360Score(
          score2?.dimensions?.[dimensionName] ?? 0
        );
        agreeablenessResults.push({
          dimensionName,
          dimension,
          comparedScore,
          userScore,
          factor,
        });
      });
    });
    setAgreementState(getSplitDimensionByAgreement(agreeablenessResults));
  }, [score1, score2]);

  const getTwoLowestAgreementSkills = () => {
    const returnElms: JSX.Element[] = [];
    getEntries(agreementState).forEach(([agreementValue, agreementArray]) => {
      agreementArray?.forEach((result) => {
        if (!result) return null;
        const dimensionObj = dimensionsByName[result.dimension];
        if (!dimensionObj) return null;
        returnElms.push(
          <Card key={dimensionObj.dimensionId} className="skills-card">
            <h4>{dimensionObj.name}</h4>
            <p>{dimensionObj.title}</p>
            <div className="d-flex justify-content-between align-items-center mt-auto w-100">
              <Button
                onClick={() => {
                  navigate(
                    `/SkillsGuide?factor=${result.factor}&dimension=${result.dimension}`
                  );
                }}
                variant="secondary-blue"
              >
                See guide
              </Button>
              <span
                className={`label-tag m-0 ${getAgreementTagColor(
                  agreementValue
                )}`}
              >
                {agreementValue}
              </span>
            </div>
          </Card>
        );
      });
    });

    return returnElms.slice(0, 2);
  };

  return (
    <>
      <p className="grey-text" style={{ marginBottom: "8px" }}>
        {descriptionText}
      </p>
      <div className="responsive-two-column-container">
        {getTwoLowestAgreementSkills()}
      </div>
    </>
  );
};

export default memo(Team360LowAgreementSkillCards);
