import { ReactNode, useEffect, useRef, useState } from "react";

interface OverflowContainerProps {
  children: ReactNode;
  className?: string;
}

const OverflowContainer = ({
  children,
  className,
}: Readonly<OverflowContainerProps>) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [gradients, setGradients] = useState({ left: false, right: false });

  const checkScrollPosition = () => {
    if (!containerRef.current) {
      return;
    }
    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
    setGradients({
      left: scrollLeft > 0,
      right: scrollLeft + clientWidth + 2 < scrollWidth,
    });
  };

  useEffect(() => {
    const currentRef = containerRef.current;
    checkScrollPosition();
    if (currentRef) {
      currentRef.addEventListener("scroll", checkScrollPosition);
    }
    window.addEventListener("resize", checkScrollPosition);

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", checkScrollPosition);
      }
      window.removeEventListener("resize", checkScrollPosition);
    };
  }, []);

  const getWrapperClassName = () => {
    let baseClassName = "overflow-container-wrapper";
    if (gradients.left) {
      baseClassName += " gradient-left";
    }
    if (gradients.right) {
      baseClassName += " gradient-right";
    }
    if (className) {
      baseClassName += ` ${className}`;
    }
    return baseClassName;
  };

  return (
    <div className={getWrapperClassName()}>
      <div className={`overflow-container`} ref={containerRef}>
        {children}
      </div>
    </div>
  );
};

export default OverflowContainer;
