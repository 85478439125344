import { useEffect, useRef, useState } from "react";
import Button from "app/storybookComponents/Button";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import {
  selectAllTeam360Results,
  getTeam360Scores,
  selectGettingAllTeam360AssessmentResultsStatus,
} from "app/components/SurveyDataInstances/slice";
import Chart from "chart.js/auto";
import { selectTeamsByTeamId } from "app/containers/Global/slice";
import {
  selectDepartments,
  selectIsDepartmentsHidden,
} from "app/containers/AdminConsole/slice";
import {
  TTeam360Factor,
  TTeam360Dimension,
} from "app/components/Team360Assessment/types";
import {
  newGetTableColumnsAndData,
  COLOR_ARR,
  AdvancedAnalyticsOptions as options,
} from "./helpers";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterDrawer from "./FilterDrawer";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useNavigate } from "react-router-dom";
import { getOrganizationDepartments } from "../AdminConsole/helpers";

export default function AdvancedAnalytics() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // --------------------- Redux Data ---------------------
  const team360Scores = useAppSelector(selectAllTeam360Results());
  const gettingAllTeam360AssessmentResultsStatus = useAppSelector(
    selectGettingAllTeam360AssessmentResultsStatus
  );
  const teamInfoById = useAppSelector(selectTeamsByTeamId);
  const departments = useAppSelector(selectDepartments);
  const filteredDepartments = getOrganizationDepartments(departments);
  const isDepartmentsHidden = useAppSelector(selectIsDepartmentsHidden);

  // --------------------- Ref and State ---------------------
  const chartRefInstance = useRef<{ chart: Chart | null }>({ chart: null });
  const chartRef = useRef<HTMLCanvasElement>(null);
  const [activeFilters, setActiveFilters] = useState<{
    Department: number[];
    Team: number[];
  }>({
    Department: [],
    Team: [],
  });
  const [activeFactorFilter, setActiveFactorFilters] = useState<
    (TTeam360Factor | "Overall TEAM Score")[]
  >(["Overall TEAM Score"]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeDimensionFilters, setActiveDimensionFilters] = useState<
    TTeam360Dimension[]
  >([]);
  const [orgScoresEnabled, setOrgScoresEnabled] = useState(true);
  const [showOffCanvas, setShowOffCanvas] = useState(false);

  // Clean up chart on unmount
  useEffect(() => {
    const curr = chartRefInstance.current;
    return () => {
      if (curr.chart) {
        curr.chart.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (!team360Scores && gettingAllTeam360AssessmentResultsStatus === "idle") {
      dispatch(getTeam360Scores());
    }
  }, [team360Scores, gettingAllTeam360AssessmentResultsStatus, dispatch]);

  useEffect(() => {
    if (!chartRef?.current || !team360Scores) {
      return;
    }

    // Might be able to extract the entire chart into a separate component
    const { labels, datasets } = newGetTableColumnsAndData(
      activeFilters,
      activeFactorFilter,
      activeDimensionFilters,
      orgScoresEnabled,
      teamInfoById,
      filteredDepartments,
      team360Scores
    );

    chartRefInstance.current.chart?.destroy();
    chartRefInstance.current.chart = new Chart(chartRef.current, {
      type: "bar",
      data: {
        labels,
        datasets: datasets,
      },
      plugins: [ChartDataLabels],
      options: {
        ...options,
        // @ts-ignore
        backgroundColor: orgScoresEnabled ? COLOR_ARR : COLOR_ARR.slice(1),
        plugins: {
          ...options.plugins,
          datalabels: {
            ...options.plugins.datalabels,
            formatter: (value: number) => Math.round(value),
          },
        },
      },
    });
  }, [
    team360Scores,
    teamInfoById,
    filteredDepartments,
    orgScoresEnabled,
    activeFilters,
    activeFactorFilter,
    activeDimensionFilters,
  ]);

  const getTeamFilterName = () => {
    if (activeFactorFilter.length === 0) return "TEAMscan Factors";
    if (
      activeFactorFilter.length === 1 &&
      activeFactorFilter[0] === "Overall TEAM Score"
    ) {
      return activeFactorFilter[0];
    }
    return `TEAM Scores(${activeFactorFilter.length})`;
  };

  const getTeamsSpan = () => {
    if (activeFilters["Team"].length === 0) return null;
    if (activeFilters["Team"].length === 1) {
      return <span className={`filter-span active`}>Teams</span>;
    }
    return (
      <span className={`filter-span active`}>
        Teams ({activeFilters["Team"].length})
      </span>
    );
  };

  const getDepartmentSpan = () => {
    if (activeFilters["Department"].length === 0) return null;
    if (activeFilters["Department"].length === 1) {
      return <span className={`filter-span active`}>Departments</span>;
    }
    return (
      <span className={`filter-span active`}>
        Departments ({activeFilters["Department"].length})
      </span>
    );
  };

  return (
    <div className="page admin-console">
      <div style={{ marginBottom: "20px" }}>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          variant="secondary-blue"
          style={{ border: "none" }}
        >
          <FontAwesomeIcon icon="arrow-left" className="me-2" />
          Back
        </Button>
      </div>
      <Card>
        <FilterDrawer
          show={showOffCanvas}
          onHide={() => setShowOffCanvas(false)}
          departments={filteredDepartments}
          teamInfoById={teamInfoById}
          orgScoresEnabled={orgScoresEnabled}
          activeFactorFilters={activeFactorFilter}
          activeFilters={activeFilters}
          setActiveFactorFilters={setActiveFactorFilters}
          setActiveFilters={setActiveFilters}
          setOrgScoresEnabled={setOrgScoresEnabled}
          areDepartmentsHidden={isDepartmentsHidden}
        />
        <h1>TEAMscan Analytics</h1>
        <Card className="p-4" style={{ marginTop: "24px" }}>
          <h2 style={{ paddingBottom: "10px" }}>Score Comparison</h2>
          <p style={{ paddingBottom: "20px" }}>
            Compare TEAMscan scores across organization, department, and more.
          </p>
          <div className="filters" style={{ paddingBottom: "20px" }}>
            <span className={`filter-span active`}>{getTeamFilterName()}</span>
            {"|"}
            {orgScoresEnabled ? (
              <span className={`filter-span active`}>This Organization</span>
            ) : null}
            {getTeamsSpan()}
            {getDepartmentSpan()}
            {"|"}
            <span
              className="list-span"
              role="button"
              onClick={() => {
                setShowOffCanvas(true);
              }}
            >
              Add Comparison <FontAwesomeIcon icon={"plus"} />
            </span>
          </div>
          <canvas ref={chartRef} id="team360ResultsGraph" />
        </Card>
      </Card>
    </div>
  );
}
