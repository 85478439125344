import { useMemo } from "react";
import Button from "app/storybookComponents/Button";
import { useAppSelector } from "utils/redux/hooks";
import { selectIsCurrentUserAdmin } from "app/containers/Global/slice";
import { useNavigate } from "react-router-dom";
import AvatarCircle from "app/components/AvatarCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDaysRemaining } from "app/containers/Assessment/helpers";
import DashboardHelpPopover from "../DashboardOnboarding/DashboardHelpPopover";
import { selectDepartments } from "app/containers/AdminConsole/slice";
import { selectDepartmentsMostRecentAssessmentsInstancesById } from "app/containers/Assessment/slice";
import { selectAllTeam360Results } from "app/components/SurveyDataInstances/slice";

interface Props {
  departmentId: number;
  pendingAssessmentEventId?: number;
  hasDepartmentLeadAccess?: boolean;
}

export default function TempSmallTeam360Card({
  departmentId,
  pendingAssessmentEventId,
  hasDepartmentLeadAccess,
}: Readonly<Props>) {
  const navigate = useNavigate();
  // ---------------------- Selectors ----------------------
  const departments = useAppSelector(selectDepartments);
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const allTeam360Scores = useAppSelector(selectAllTeam360Results());

  // ---------------------- States ----------------------
  const teamsMostRecentAssessment = useAppSelector((state) =>
    selectDepartmentsMostRecentAssessmentsInstancesById(state, departmentId)
  );

  // ---------------------- Variables ----------------------
  const { name } = departments[departmentId] ?? {};
  const hasOverview = !!allTeam360Scores?.departmentScores?.[departmentId];
  const daysRemaining = getDaysRemaining(teamsMostRecentAssessment?.endDate);
  const isOverdue = daysRemaining !== null && daysRemaining < 0;
  const showDaysRemaining = daysRemaining !== null && daysRemaining > 0;
  const totalCompleted = teamsMostRecentAssessment?.totalCompleted ?? 0;
  const totalInvited = teamsMostRecentAssessment?.totalInvited ?? 0;

  // ---------------------- Helpers ----------------------

  // This is true if the user is an admin or if the user is a leader of the team
  const userHasAccessToEditTeam360 = useMemo(() => {
    return !!(isAdmin || hasDepartmentLeadAccess);
  }, [isAdmin, hasDepartmentLeadAccess]);

  const getPopoverTextAndTitle = (): { title: string; text: string } => {
    // If user does not have access to edit TEAMscan, then show the default text
    if (!userHasAccessToEditTeam360) {
      return getDaysRemainingTextAndTitle();
    }

    // If overdue and no results avaiable then we should send a sepeatete text and the title should be No Results Available

    if (isOverdue) {
      const totalNotCompleted = totalInvited - totalCompleted;
      return {
        title: "Extend survey period",
        text: `This survey is now closed, but ${totalNotCompleted} of your team members have not completed it. Would you like to extend the survey period for another week?`,
      };
    }
    return getDaysRemainingTextAndTitle();
  };

  const getDaysRemainingTextAndTitle = (): { title: string; text: string } => {
    const dayOrDays = daysRemaining === 1 ? "day" : "days";
    const baseText =
      "Results for this team will be be available once at least 3 team members have completed the TEAMscan survey.";
    const text = showDaysRemaining
      ? `${baseText} This survey closes in ${daysRemaining} ${dayOrDays}.`
      : baseText;
    return { text, title: "Results pending" };
  };

  const getPopoverFooter = () => {
    if (!userHasAccessToEditTeam360) {
      return null;
    }

    if (isOverdue) {
      return (
        <div className="row-gap-16px">
          <Button onClick={() => {}}>Extend for one week</Button>
          <Button variant="secondary-blue" onClick={() => {}}>
            Manage surveys
          </Button>
        </div>
      );
    }

    return (
      <div className="row-gap-16px">
        <Button variant="secondary-blue" onClick={() => {}}>
          Manage surveys
        </Button>
      </div>
    );
  };

  const getActionSection = () => {
    if (pendingAssessmentEventId && !isOverdue) {
      return (
        <div className="my-team-360-card-action">
          {getDaysRemainingText()}
          <Button
            variant="secondary-blue"
            onClick={() => {}}
            className="border-0 button-with-chevron"
          >
            Begin survey <FontAwesomeIcon icon="chevron-right" />
          </Button>
        </div>
      );
    }

    if (hasOverview) {
      return (
        <div className="my-team-360-card-action">
          <span className="results-ready-list-span">Results ready</span>
          <Button
            variant="secondary-blue"
            onClick={() =>
              navigate(`/DepartmentGuide/${departmentId}?tab=TEAMscan`)
            }
            className="border-0 button-with-chevron nowrap"
          >
            View results <FontAwesomeIcon icon="chevron-right" />
          </Button>
        </div>
      );
    }

    const { title, text } = getPopoverTextAndTitle();
    return (
      <div className="my-team-360-card-action row-gap-8px">
        {getDaysRemainingText()}
        <p
          className="row-gap-8px align-items-center"
          style={{
            color: "#86888b",
          }}
        >
          <b>
            {showDaysRemaining ? "Results pending" : "No Results Available"}
          </b>
          <DashboardHelpPopover
            title={title}
            showDismiss={!userHasAccessToEditTeam360}
            bodyText={text}
            popoverFooter={getPopoverFooter()}
          />
        </p>
      </div>
    );
  };

  const getDaysRemainingText = () => {
    if (!isOverdue && !showDaysRemaining) {
      return <span className="days-remaining">Due Today</span>;
    }
    if (!showDaysRemaining) {
      return null;
    }
    const dayOrDays = daysRemaining === 1 ? "day" : "days";
    return (
      <span className="days-remaining">
        {daysRemaining} {dayOrDays} left
      </span>
    );
  };

  const getCompletedInfoText = () => {
    if (hasOverview) return "Click on view results to see more details";
    // Will need to create a new endpoint that return back the number of the completed
    return "No results available";
  };

  return (
    <div className="my-team-360-card">
      <div className="my-team-360-card-action-info">
        <AvatarCircle name={name} size="small" />
        <div className="my-team-360-card-info-text">
          <p>
            <b>{name}</b>
          </p>
          <p className="small-body-text">{getCompletedInfoText()}</p>
        </div>
      </div>
      {getActionSection()}
    </div>
  );
}
