import { ReactNode } from "react";
import Button from "app/storybookComponents/Button";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  show: boolean;
  onHide: () => void;
  title?: string;
  children?: ReactNode;
  className?: string;
  isSecondary?: boolean;
  onBack?: () => void;
}

export default function SideDrawer({
  show,
  children,
  title,
  onHide,
  className,
  isSecondary,
  onBack,
}: Readonly<Props>) {
  const getTitleRow = () => {
    if (isSecondary) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <button onClick={onBack} className="side-drawer-back-button">
            <FontAwesomeIcon icon="arrow-left" className="me-2" />
          </button>
          <h2 className="side-drawer-title">{title}</h2>
        </div>
      );
    }

    return <h2 className="side-drawer-title">{title}</h2>;
  };
  return (
    <Card className={`side-drawer${show ? " active" : ""} ${className ?? ""}`}>
      <div className="d-flex justify-content-between">
        {getTitleRow()}
        <Button
          onClick={() => onHide()}
          variant={"secondary-blue"}
          style={{ border: "none", width: "auto" }}
          xIcon
        />
      </div>
      {children}
    </Card>
  );
}
