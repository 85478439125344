import React, { useMemo, useState } from "react";
import Robot from "resources/images/robot.svg";
import SimpleSideNavigation from "app/components/SimpleSideNavigation";
import { Card } from "react-bootstrap";
import Select from "react-select";
import CoachbotPromptCard from "./CoachbotPromptCard";
import { ALL_COACHBOT_PROMPT_TEMPLATES } from "./constants";
import NavigateBackButton from "app/components/NavigateBackButton";
import { CoachbotCardTemplate } from "./types";
import Paginator from "app/components/SortableTable/Paginator";
import { getSelectProps } from "utils/helperFunctions";
import { useAppDispatch } from "utils/redux/hooks";
import {
  setIsCoachbotOpen,
  setCoachBotPrompt,
  resetChatbotState,
} from "app/containers/Chatbot/slice";
import MobileSideNav from "app/components/SimpleSideNavigation/MobileSideNav";

interface CoachBotPromptsProps {
  // Define your prop types here
}

const navs = Object.keys(ALL_COACHBOT_PROMPT_TEMPLATES).map((key) => ({
  title: key,
}));

const MAX_CARDS_PER_PAGE = 6;

const CoachBotPrompts: React.FC<CoachBotPromptsProps> = (props) => {
  const dispatch = useAppDispatch();

  // ------------------ State ------------------
  const [navSelected, setNavSelected] = useState<string>("Productivity");
  const [selectedPrompt, setSelectedPrompt] =
    useState<null | CoachbotCardTemplate>(null);
  const [inputValue, setInputValue] = useState("");
  const [page, setPage] = useState(1);

  // ------------------ Functions ------------------
  const getCardTemplate = (navSelected: string | null) =>
    ALL_COACHBOT_PROMPT_TEMPLATES[navSelected ?? ""].promptTemplates ?? [];

  const getFormatOptionLabel = (
    optionInfo: {
      label: string;
      value: string;
      type: string;
    } | null
  ) => {
    if (!optionInfo) return null;
    const { label, type } = optionInfo;

    return (
      <div className="skill-search-option">
        <span>{label}</span>
        <p>{type}</p>
      </div>
    );
  };

  const getSearchOptions = () => {
    const options: { label: string; value: string; type: string }[] = [];
    Object.entries(ALL_COACHBOT_PROMPT_TEMPLATES).forEach(([key, value]) => {
      value.promptTemplates.forEach((template) => {
        options.push({
          label: template.title,
          value: template.title,
          type: key,
        });
      });
    });
    return options;
  };

  const onSearchSelect = (
    option: { label: string; value: string; type: string } | null
  ) => {
    if (!option) return;
    const { value } = option;
    const templates = getCardTemplate(option.type);
    let page = 0;
    const template = templates.find((t, idx) => {
      if (idx % MAX_CARDS_PER_PAGE === 0) {
        page++;
      }
      return t.title === value;
    });
    setPage(page);
    setNavSelected(option.type);
    onPromptSelectAndSave(template);
  };

  const getNavigationSearch = () => {
    const { selectStyles, components } = getSelectProps("search");

    return (
      <Select
        placeholder="Search"
        components={components}
        value={null}
        styles={selectStyles}
        inputValue={inputValue}
        onInputChange={setInputValue}
        isClearable
        formatOptionLabel={getFormatOptionLabel}
        options={getSearchOptions()}
        onChange={onSearchSelect}
      />
    );
  };

  const onPromptSelectAndSave = async (
    selectedPrompt?: CoachbotCardTemplate
  ) => {
    if (!selectedPrompt) return;
    setSelectedPrompt(selectedPrompt ?? null);
    dispatch(resetChatbotState());
    dispatch(
      setCoachBotPrompt({
        userPrompts: selectedPrompt.preSelectedPrompts ?? [],
        coachBotIntro: selectedPrompt.coachBotIntro,
        chatType: selectedPrompt.chatType ?? "",
      })
    );
    dispatch(setIsCoachbotOpen(true));
  };

  const navProps = {
    navs,
    onNavClick: (path: string) => {
      setNavSelected(path);
      setSelectedPrompt(null);
      setPage(1);
    },
    header: getNavigationSearch(),
    setNavSelected: (elm: string) => setNavSelected(elm),
    navSelected: [navSelected],
  };

  const allAvailableCardsForSelectedNav = useMemo(() => {
    return getCardTemplate(navSelected);
  }, [navSelected]);

  const cardsShowing = allAvailableCardsForSelectedNav
    .slice((page - 1) * MAX_CARDS_PER_PAGE, page * MAX_CARDS_PER_PAGE)
    .map((template) => (
      <CoachbotPromptCard
        key={template.title}
        template={template}
        onSelect={() => {
          onPromptSelectAndSave(template);
        }}
        isSelected={selectedPrompt?.title === template.title}
      />
    ));

  return (
    <div className="side-nav-page">
      <SimpleSideNavigation {...navProps} isResponsive />
      <div className="coachbot-prompt-page" style={{ marginRight: "24px" }}>
        <div
          style={{
            marginTop: "20px",
          }}
        >
          <NavigateBackButton />
          <MobileSideNav {...navProps} />
        </div>

        <Card className="coachbot-prompt-page-header">
          <div className="row-gap-20px align-items-center">
            <img src={Robot} alt="Criteria" className="assistant-avatar me-0" />
            <h1>Build your skills with Coach Bo</h1>
          </div>
          <div className="coachbot-prompt-page-header-description">
            <p>
              Search categories of chats from the left and select a specific
              prompt you want to chat about. The more Coach Bo knows about you,
              the better the chat will get. Coach Bo can learn more about you
              and your team by taking TEAMscan surveys, completing your
              personality profile, and having more chats in general.
            </p>
          </div>
        </Card>
        <Card className="column-gap-20px">
          <div className="d-flex justify-content-between">
            <h2>Prompts For {navSelected}</h2>
            <Paginator
              pageCount={Math.ceil(
                allAvailableCardsForSelectedNav.length / MAX_CARDS_PER_PAGE
              )}
              onPageChange={({ selected }) => setPage(selected + 1)}
              forcePage={page - 1}
            />
          </div>
          <div className="prompt-cards-container">{cardsShowing}</div>
        </Card>
      </div>
      <div />
    </div>
  );
};

export default CoachBotPrompts;
