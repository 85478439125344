import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import Button from "app/storybookComponents/Button";
import { Dropdown } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectCurrentUserAccountId,
  selectSampleTeamsByTeamId,
  selectSampleUsersInfoById,
  selectIsCurrentUserAdmin,
  selectCompanyInfo,
  selectGetAllUserStatus,
  selectTeamsByTeamId,
  selectAllCompanyUsersById,
  selectGetUserStatus,
} from "app/containers/Global/slice";
import {
  openCreateTeamModal,
  setIsNewDirectReportModalOpen,
} from "app/components/Modals/slice";
import {
  createCompanyInviteLink,
  selectAiFeaturesEnabled,
  selectCompanySettings,
  selectCreateCompanyInviteLinkStatus,
  selectDepartments,
  selectGetTeamAndDepartmentLeadsStatus,
  selectTeamAndDepartmentLeadIdsForLoggedInUser,
} from "app/containers/AdminConsole/slice";
import { ValidDashboardRole, isDashboardRole } from "./types";
import { getNotifications } from "app/components/Notifications/slice";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import AvatarCircle from "app/components/AvatarCircle";
import {
  getAssessmentInstances,
  getMostRecentAssessmentInstances,
  getPendingAssessments,
  selectGetPendingAssessmentsStatus,
} from "app/containers/Assessment/slice";
import Loading from "app/storybookComponents/Loading";
import InviteUsersModal from "app/components/Modals/InviteUsersModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getTeam360Scores,
  selectGettingAllTeam360AssessmentResultsStatus,
} from "app/components/SurveyDataInstances/slice";
import TeamLeaderCreateTeamOnboardingModal from "./DashboardOnboarding/TeamLeaderCreateTeamOnboardingModal";
import { getAllowedDomains } from "../AdminConsole/helpers";
import useTrackPage from "utils/hooks/useTrackPage";
import CoachBoGreeterCard from "./Cards/CoachBoGreeterCard";
import MemberDashboardV2 from "./DashboardOnboarding/MemberDashboardV2";

export default function DashboardIndex() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // ------------------------ Selectors ------------------------
  const userAccountId = useAppSelector(selectCurrentUserAccountId);
  const sampleUsersById = useAppSelector(selectSampleUsersInfoById);
  const companyUsersById = useAppSelector(selectAllCompanyUsersById);
  const sampleTeamsById = useAppSelector(selectSampleTeamsByTeamId);
  const allTeamsById = useAppSelector(selectTeamsByTeamId);
  const departments = useAppSelector(selectDepartments);
  const getAllTeam360ScoresStatus = useAppSelector(
    selectGettingAllTeam360AssessmentResultsStatus
  );
  const getPendingAssessmentsStatus = useAppSelector(
    selectGetPendingAssessmentsStatus
  );
  const teamAndDepartmentLeaderOf = useAppSelector(
    selectTeamAndDepartmentLeadIdsForLoggedInUser
  );
  const getTeamAndDepartmentLeadsStatus = useAppSelector(
    selectGetTeamAndDepartmentLeadsStatus
  );
  const createCompanyInviteLinkStatus = useAppSelector(
    selectCreateCompanyInviteLinkStatus
  );
  const companyInfo = useAppSelector(selectCompanyInfo);
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const companySettings = useAppSelector(selectCompanySettings);
  const areAiFeaturesEnabled = useAppSelector(selectAiFeaturesEnabled);
  const getAllUserStatus = useAppSelector(selectGetAllUserStatus);
  const gettingLoggedInUserStatus = useAppSelector(selectGetUserStatus);

  const teamsById = useMemo(
    () => ({ ...sampleTeamsById, ...allTeamsById }),
    [sampleTeamsById, allTeamsById]
  );
  const usersById = useMemo(
    () => ({ ...sampleUsersById, ...companyUsersById }),
    [sampleUsersById, companyUsersById]
  );
  const showUpdatedCoachBoHomePage = areAiFeaturesEnabled;

  // ------------------------ State ------------------------
  const [isTeamLead, setIsTeamLead] = useState(false);
  const [isDepartmentLead, setIsDepartmentLead] = useState(false);
  const [showCreateTeamBanner, setShowCreateTeamBanner] = useState(false);
  const [dashboardRoleShowing, setDashboardRoleShowing] =
    useState<ValidDashboardRole>("Team Member");
  const [selectedId, setSelectedId] = useState<null | number>(null);
  const [showInviteMemberModal, setShowInviteMemberModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showTeamLeaderOnboardingModal, setShowTeamLeaderOnboardingModal] =
    useState(false);
  const [teamCreationDefaultDepartment, setTeamCreationDefaultDepartment] =
    useState<null | number>(null);

  // ------------------------ Effects ------------------------
  useEffect(() => {
    dispatch(getNotifications());
    dispatch(getMostRecentAssessmentInstances());
    dispatch(getAssessmentInstances());
  }, [dispatch]);

  useTrackPage();

  // This useEffect is used to keep the url path clean, if the user is on the root path then we redirect them to the home path
  useEffect(() => {
    if (gettingLoggedInUserStatus !== "succeeded" || pathname !== "/") {
      return;
    }
    const newPath = isAdmin ? "/AdminConsole/Dashboard" : "/home";

    navigate(newPath, { replace: true });
  }, [pathname, navigate, isAdmin, gettingLoggedInUserStatus]);

  useEffect(() => {
    if (!teamAndDepartmentLeaderOf) {
      return;
    }
    const isUserATeamLead = teamAndDepartmentLeaderOf?.teams?.length > 0;
    setIsTeamLead(isUserATeamLead);

    const isUserADepartmentLead =
      !!teamAndDepartmentLeaderOf?.departments?.length;
    setIsDepartmentLead(isUserADepartmentLead);
  }, [teamAndDepartmentLeaderOf]);

  useEffect(() => {
    if (getAllTeam360ScoresStatus === "idle") {
      dispatch(getTeam360Scores());
    }
  }, [getAllTeam360ScoresStatus, dispatch]);

  useEffect(() => {
    if (getPendingAssessmentsStatus === "idle") {
      dispatch(getPendingAssessments());
    }
  }, [dispatch, getPendingAssessmentsStatus]);

  useEffect(() => {
    if (
      createCompanyInviteLinkStatus === "idle" &&
      companyInfo?.companyAccountId
    ) {
      dispatch(
        createCompanyInviteLink({
          payload: { expiration: 20 },
          companyAccountId: companyInfo?.companyAccountId,
        })
      );
    }
  }, [createCompanyInviteLinkStatus, companyInfo?.companyAccountId, dispatch]);

  useEffect(() => {
    if (
      !userAccountId ||
      !usersById[userAccountId] ||
      !teamAndDepartmentLeaderOf
    ) {
      return;
    }

    const user = usersById[userAccountId];
    const isUserATeamLead = teamAndDepartmentLeaderOf?.teams?.length > 0;
    // We will only show the modal if the id is 3 and the user is not team leader for any teams
    if (user.ti_onboardingRoleId === 3 && !isUserATeamLead) {
      setShowCreateTeamBanner(true);
    }
  }, [usersById, userAccountId, teamAndDepartmentLeaderOf]);

  // This function will take in array of departmentId, we will then iterate through each of them
  // and return the first one that has an actual department in the system. This is needed because sometimes when the user deletes a department it will still be in the array
  const getFirstDepartmentId = useCallback(
    (departmentIds: number[] = []) =>
      departmentIds.find((departmentId) => departments[departmentId]),
    [departments]
  );

  // This function will take in array of teamId, we will then iterate through each of them
  // and return the first one that has an actual team in the system. This is needed because sometimes when the user deletes a team it will still be in the array
  const getFirstTeamId = useCallback(
    (teamIds: number[] = []) => teamIds.find((teamId) => teamsById[teamId]),
    [teamsById]
  );

  useLayoutEffect(() => {
    const newDirectReportUserAccountId = searchParams.get("newDirectReport");
    if (!newDirectReportUserAccountId) return;
    dispatch(
      setIsNewDirectReportModalOpen({
        userAccountIds: [Number(newDirectReportUserAccountId)],
      })
    );
  }, [searchParams, dispatch]);

  useLayoutEffect(() => {
    setIsLoading(true);

    // if the users have not yet been loaded don't proceed
    if (getAllUserStatus !== "succeeded") {
      return;
    }

    // Save the query params into a variables
    const dashboardRole = searchParams.get("dashboardRole");
    const dashboardSelectedId = searchParams.get("dashboardSelectedId")
      ? Number(searchParams.get("dashboardSelectedId"))
      : null;

    // if dashboardSelectedId is a valid number then we set the selectedId to that number
    if (typeof dashboardSelectedId === "number") {
      setSelectedId(dashboardSelectedId);
      if (!dashboardRole) {
        return setIsLoading(false);
      }
    }

    // If a dashboard role is defined inside of the url and it is a valid role then we want to show that role
    if (dashboardRole && isDashboardRole(dashboardRole)) {
      setDashboardRoleShowing(dashboardRole);
      return setIsLoading(false);
    }

    // If not we check the departments, if the user is a department leader of any department then we set the initial role to department leader
    const firstDepartmentId = getFirstDepartmentId(
      teamAndDepartmentLeaderOf?.departments
    );
    if (firstDepartmentId) {
      setDashboardRoleShowing("Department Leader");
      setSelectedId(firstDepartmentId);
      return setIsLoading(false);
    }

    // If the user is not a department leader then we check if they are a team leader of any team
    const firstTeamId = getFirstTeamId(teamAndDepartmentLeaderOf?.teams);
    if (firstTeamId) {
      setDashboardRoleShowing("Team Leader / Team Member");
      setSelectedId(null); // Since we are consolidating team member and team leader dashboard we don't need to set the selectedId
      return setIsLoading(false);
    }

    // If the user is not a team leader or department leader then show them the team member role
    setDashboardRoleShowing("Team Member");
    setSelectedId(null);
    setIsLoading(false);
  }, [
    searchParams,
    userAccountId,
    teamAndDepartmentLeaderOf,
    getAllUserStatus,
    getFirstDepartmentId,
    getFirstTeamId,
  ]);

  const onRoleSelect = (role: string | null) => {
    if (!isDashboardRole(role)) {
      return;
    }

    setDashboardRoleShowing(role);
    const params: {
      dashboardRole: string;
      dashboardSelectedId?: string;
    } = {
      dashboardRole: role,
    };

    // If we select the role of Department Leader then we need to set the selectedId to the first department the user is a leader of
    if (role === "Department Leader" && userAccountId) {
      const firstDepartmentId = getFirstDepartmentId(
        teamAndDepartmentLeaderOf?.departments ?? []
      );
      if (firstDepartmentId) {
        params.dashboardSelectedId = String(firstDepartmentId);
        setSelectedId(firstDepartmentId);
      } else {
        setSelectedId(null);
      }
    }

    setSearchParams(params);
  };

  const getRoleDropdown = () => {
    const dropdownOptions: ValidDashboardRole[] = [];

    if (isTeamLead) {
      dropdownOptions.unshift("Team Leader / Team Member");
    } else {
      dropdownOptions.unshift("Team Member");
    }

    if (isDepartmentLead) {
      dropdownOptions.unshift("Department Leader");
    }

    // If the user only has one option no need to show the dropdown
    if (dropdownOptions.length === 1 || getAllUserStatus !== "succeeded") {
      return null;
    }

    return (
      <>
        <span>Pick a view by role:</span>
        <Dropdown onSelect={onRoleSelect}>
          <Dropdown.Toggle
            variant="light"
            id="dropdown-basic"
            className="dropdown-menu-240px"
          >
            {dashboardRoleShowing}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-240px">
            {dropdownOptions.map((option) => (
              <Dropdown.Item eventKey={option} key={option}>
                {option}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const onIdSelect = (id: string | null) => {
    if (!id) return;
    setSearchParams((searchParams) => {
      searchParams.set("dashboardSelectedId", id);
      return searchParams;
    });
  };

  const getSpecificTeamOrDepartmentDropdown = () => {
    if (!userAccountId) return null;

    if (dashboardRoleShowing === "Department Leader") {
      return (
        <Dropdown onSelect={onIdSelect}>
          <Dropdown.Toggle
            variant="light"
            id="dropdown-basic"
            className="dropdown-menu-240px"
          >
            {selectedId ? departments[selectedId]?.name : "Select a department"}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-240px">
            {teamAndDepartmentLeaderOf?.departments?.map((departmentId) =>
              departments[departmentId] ? (
                <Dropdown.Item eventKey={departmentId} key={departmentId}>
                  {departments[departmentId]?.name}
                </Dropdown.Item>
              ) : null
            )}
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    // If the role selected is not team leader or department leader than we don't show any dropdown
    return null;
  };

  const getInviteMemberModalTitle = () => {
    if (dashboardRoleShowing === "Team Leader / Team Member" && selectedId) {
      return `Invite Member to ${teamsById[selectedId]?.teamName}`;
    }

    return "Invite Team Member";
  };

  const userInfo = useMemo(
    () => (userAccountId ? usersById[userAccountId] : null),
    [userAccountId, usersById]
  );
  const { headerAvatar, headerText, headerBar } = useMemo((): {
    headerAvatar: JSX.Element | null;
    headerText: JSX.Element | null;
    headerBar: JSX.Element | null;
  } => {
    const userName = userInfo
      ? `${userInfo.firstName ?? ""} ${userInfo.lastName ?? ""}`
      : "";
    const jobTitle = userInfo?.jobTitle;
    switch (dashboardRoleShowing) {
      case "Department Leader":
      case "Team Leader / Team Member":
      case "Team Member":
        return {
          headerAvatar: (
            <Link to="/UserGuide" className="no-underline">
              <AvatarCircle
                userAccountId={userAccountId ?? undefined}
                size="extra-large"
              />
            </Link>
          ),
          headerText: (
            <div
              className="row-gap-20px"
              style={{
                paddingTop: "4px",
                height: "40px",
              }}
            >
              <h1
                style={{
                  color: "white",
                }}
              >
                {userName}
              </h1>
              {jobTitle ? (
                <div className={`label-tag blue`}>{jobTitle}</div>
              ) : null}
            </div>
          ),
          headerBar: (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100px",
                backgroundColor: "#425CC7",
                zIndex: -1,
              }}
            />
          ),
        };
      default:
        return { headerText: null, headerAvatar: null, headerBar: null };
    }
  }, [dashboardRoleShowing, userAccountId, userInfo]);

  const getDashboardHeader = () => {
    if (!showUpdatedCoachBoHomePage) {
      return (
        <div
          className="row-gap-32px"
          style={{
            marginTop: "20px",
          }}
        >
          {headerAvatar}
          <div className="w-100 column-gap-36px">
            {headerText}
            <div className="dashboard-header-v2">
              <div className="row-gap-12px align-items-center flex-wrap">
                {getRoleDropdown()}
                {getSpecificTeamOrDepartmentDropdown()}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <CoachBoGreeterCard name={userInfo?.firstName} />;
  };

  const getDashboardContent = () => {
    if (getTeamAndDepartmentLeadsStatus === "loading" || isLoading) {
      return <Loading />;
    }

    return (
      <>
        {getDashboardHeader()}
        {showCreateTeamBanner && !userInfo?.teamIds.length ? (
          <div className="dashboard-banner">
            <p>
              <FontAwesomeIcon icon={["far", "lightbulb-on"]} /> Are you a team
              leader? Create your first team now
            </p>
            <div className="dashboard-banner-action-button">
              <Button
                onClick={() => setShowCreateTeamBanner(false)}
                variant="secondary-blue"
              >
                Dismiss
              </Button>
              <Button onClick={() => dispatch(openCreateTeamModal())}>
                Create a team
              </Button>
            </div>
          </div>
        ) : null}
        <MemberDashboardV2 />
      </>
    );
  };

  return (
    <>
      {showUpdatedCoachBoHomePage ? null : headerBar}
      <div className="page dashboard">
        <div className="column-gap-20px">
          {/* Modals */}
          <InviteUsersModal
            modalTitle={getInviteMemberModalTitle()}
            showing={showInviteMemberModal}
            hideModal={() => setShowInviteMemberModal(false)}
            teamId={
              dashboardRoleShowing === "Team Leader / Team Member" && selectedId
                ? selectedId
                : undefined
            }
            onInviteSuccess={() => setShowInviteMemberModal(false)}
          />

          <TeamLeaderCreateTeamOnboardingModal
            show={showTeamLeaderOnboardingModal}
            onHide={() => {
              setShowTeamLeaderOnboardingModal(false);
              setTeamCreationDefaultDepartment(null);
            }}
            userFirstName={userInfo?.firstName ?? ""}
            allowedDomains={getAllowedDomains(companySettings)}
            defaultDepartmentId={teamCreationDefaultDepartment}
          />
          {/* Modal End */}
          {getDashboardContent()}
        </div>
      </div>
    </>
  );
}
