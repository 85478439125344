import Button from "app/storybookComponents/Button";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  getModuleTemplates,
  selectGetModuleTemplatesStatus,
} from "app/containers/CompanyGuide/slice";
import {
  selectTeamsByTeamId,
  selectAllCompanyUsersById,
  selectGetAllUserStatus,
  selectCurrentUserAccountId,
} from "app/containers/Global/slice";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { Card, Collapse, OverlayTrigger, Popover } from "react-bootstrap";
import {
  getAnalyticsText,
  selectDepartments,
  selectAnalyticsText,
  getAssessmentInformation,
  selectAssessmentInformation,
  selectIsDepartmentsHidden,
  selectUserIsAbleToInviteTeamLeaders,
  selectGetAnalyticsTextStatus,
} from "app/containers/AdminConsole/slice";
import {
  showScheduleAssessmentModalForOrganization,
  showScheduleAssessmentModalForDepartmentId,
} from "app/components/LaunchAssessmentModal/slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setShowModal } from "app/components/Onboarding/slice";
import { selectAllTeam360Results } from "app/components/SurveyDataInstances/slice";
import {
  getDepartmentAssessmentCompletionInfo,
  selectGetDepartmentAssessmentInfoStatus,
  getPendingAllPendingTeamLeaders,
  selectAllPendingTeamLeaders,
  getPendingDepartmentLeaders,
} from "../slice";
import InfoCardV2 from "app/components/InfoCard/InfoCardV2";
import QuickActionCard from "app/components/QuickActions/QuickActionCard";
import { AssessmentMap } from "app/containers/Assessment/constants";
import HalfDonutChart from "app/storybookComponents/Charts/HalfDonutChart";
import { getTeam360Score } from "app/components/Team360Assessment/helpers";
import SurveyResultsModal from "app/components/Modals/SurveyResultsModal";
import PendingTeamsModal from "app/components/Modals/PendingTeamsModal";
import PendingUsersModal from "app/components/Modals/PendingUsersModal";
import { selectAllTeamAssessmentsInstances } from "app/containers/Assessment/slice";
import {
  openCreateTeamModal,
  openEditDepartmentModal,
} from "app/components/Modals/slice";
import PendingDepartmentsModal from "app/components/Modals/PendingDepartmentsModal";
import { getOrganizationDepartments } from "app/containers/AdminConsole/helpers";
import Loading from "app/storybookComponents/Loading";
import CirclePie from "app/storybookComponents/DynamicIcons/CirclePie";

interface Props {
  onShowInviteTeamLeaderModal: () => void;
  onShowInviteMemberModal: () => void;
}

export default function AdminOnboarding({
  onShowInviteTeamLeaderModal,
  onShowInviteMemberModal,
}: Readonly<Props>) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // ------------------------ useStates ------------------------
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [showPendingUserModal, setShowPendingUserModal] = useState(false);
  const [showPendingTeamsModal, setShowPendingTeamsModal] = useState(false);
  const [showPendingDepartmentsModal, setShowPendingDepartmentsModal] =
    useState(false);
  const [isUnderStandTeamworkCollapsed, setIsUnderStandTeamworkCollapsed] =
    useState(false);

  // ------------------------ useSelectors ------------------------
  const userAccountId = useAppSelector(selectCurrentUserAccountId);
  const getModuleTemplatesStatus = useAppSelector(
    selectGetModuleTemplatesStatus
  );
  const departmentAssessmentInfoStatus = useAppSelector(
    selectGetDepartmentAssessmentInfoStatus
  );
  const departments = useAppSelector(selectDepartments);
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const organizationDepartmentsById = getOrganizationDepartments(departments);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const allTeam360Results = useAppSelector(selectAllTeam360Results());
  const analyticsText = useAppSelector(selectAnalyticsText);
  const pendingTeamLeaders = useAppSelector(selectAllPendingTeamLeaders);
  const assessmentInformation = useAppSelector(selectAssessmentInformation);
  const isDepartmentsHidden = useAppSelector(selectIsDepartmentsHidden);
  const isUserAbleToInviteTeamLeader = useAppSelector(
    selectUserIsAbleToInviteTeamLeaders
  );
  const getAllUserStatus = useAppSelector(selectGetAllUserStatus);
  const getAllTeamStatus = useAppSelector(selectGetAllUserStatus);
  const getAnalyticsTextStatus = useAppSelector(selectGetAnalyticsTextStatus);
  const allTeamAssessmentInstances = useAppSelector(
    selectAllTeamAssessmentsInstances
  );

  // ------------------------ useCallbacks ------------------------

  // ------------------------ useEffects ------------------------
  useEffect(() => {
    if (getModuleTemplatesStatus === "idle") {
      dispatch(getModuleTemplates());
    }
  }, [getModuleTemplatesStatus, dispatch]);

  useEffect(() => {
    if (departmentAssessmentInfoStatus === "idle") {
      dispatch(getDepartmentAssessmentCompletionInfo());
    }
  }, [departmentAssessmentInfoStatus, dispatch]);

  useEffect(() => {
    if (getAnalyticsTextStatus === "idle") {
      dispatch(getAnalyticsText());
    }
  }, [dispatch, getAnalyticsTextStatus]);

  // These function calls have conditionals to prevent infinite loops
  useEffect(() => {
    dispatch(getPendingAllPendingTeamLeaders());
    dispatch(getPendingDepartmentLeaders());
    dispatch(getAssessmentInformation());
  }, [dispatch]);

  // ------------------------ memo functions ------------------------
  const pendingTeamMembers = useMemo(() => {
    // We shall go through all of the users and return the count of all the users who don't have firstTimeLogin
    let pendingCount = 0;
    Object.values(usersById).forEach((user) => {
      if (!user.firstTeamsLogin) {
        pendingCount++;
      }
    });
    return pendingCount;
  }, [usersById]);

  // ------------------------ get functions ------------------------
  const seeOrgTeam360Results = () => {
    window.scrollTo(0, 0);
    navigate("/AdminConsole/Analytics");
  };

  const getTeamScanLaunchedTooltipContent = () => (
    <div>
      <p>
        This is the total number of TEAMscans launched for all teams in this
        organization.
      </p>
    </div>
  );

  const getTeamTooltipContent = () => (
    <div>
      <p>
        View the total count of active teams in your organization. Teams drive
        collaborative performance and are the core unit of group productivity.
      </p>
    </div>
  );

  const getPeopleTooltipContent = () => (
    <div>
      <p>
        View the total number of users who have been added to your organization.
      </p>
    </div>
  );

  const getS = (count: number) => (count === 1 ? "" : "s");

  const getTotalActiveTeamScanLaunchedCount = () => {
    return (
      allTeamAssessmentInstances?.filter(
        (instance) => instance.status?.toLowerCase() === "active"
      ).length ?? 0
    );
  };

  const getTotalTeamScanLaunchedCard = () => (
    <InfoCardV2
      title="Total TEAMscans Launched"
      body={allTeamAssessmentInstances?.length ?? 0}
      actions={[
        {
          onClick: () => {
            navigate("/AdminConsole/Surveys");
          },
          text: "Manage Surveys",
        },
      ]}
      toolTipContent={getTeamScanLaunchedTooltipContent()}
      pendingText={
        <div className="row-gap-8px align-items-center">
          <CirclePie percentage={66} color={"#53565A"} />
          <p className="sapphire-150-text">
            {getTotalActiveTeamScanLaunchedCount()} active TEAMscans
          </p>
        </div>
      }
    />
  );

  const getTeamsInfoCardPendingText = () => {
    if (!isUserAbleToInviteTeamLeader) {
      return;
    }
    const pendingTeamLeadersLength = pendingTeamLeaders?.length;

    if (!pendingTeamLeadersLength) {
      return "No pending leaders";
    }

    return `${pendingTeamLeadersLength} pending leader${getS(
      pendingTeamLeadersLength
    )}`;
  };

  const getTeamsInfoCard = () => (
    <InfoCardV2
      title="Teams In This Organization"
      body={Object.keys(teamsById).length}
      actions={[
        {
          onClick: () => {
            navigate("/AdminConsole/Teams");
          },
          text: "Create / Manage teams",
        },
      ]}
      toolTipContent={getTeamTooltipContent()}
      onPendingTextClick={
        pendingTeamLeaders?.length
          ? () => setShowPendingTeamsModal(true)
          : () => onShowInviteTeamLeaderModal()
      }
      pendingText={getTeamsInfoCardPendingText()}
    />
  );

  const getPeopleInfoCard = () => (
    <InfoCardV2
      title="People In This Organization"
      body={Object.keys(usersById).length}
      actions={[
        {
          onClick: () => {
            navigate("/AdminConsole/People");
          },
          text: "Create / Manage people",
        },
      ]}
      pendingText={
        pendingTeamMembers
          ? `${pendingTeamMembers} pending members`
          : "No pending members"
      }
      onPendingTextClick={
        pendingTeamMembers
          ? () => {
              setShowPendingUserModal(true);
            }
          : undefined
      }
      toolTipContent={getPeopleTooltipContent()}
    />
  );

  // The following functions are going to be the new UI we use.
  const getInfoCardsV2 = () => {
    // If no teams or departments then we need to show the empty card to allow the admin to create a team or department
    if (
      !Object.keys(organizationDepartmentsById).length &&
      !Object.keys(teamsById).length
    ) {
      return (
        <Card className="setup-empty-card">
          <div className="card-content">
            <h3>Set Up Your First Department</h3>
            <p
              style={{
                maxWidth: "320px",
              }}
            >
              Invite department leaders in this organization to get departments
              in this organization up and running
            </p>
          </div>
          <div className="column-gap-20px">
            <Button
              onClick={() => {
                dispatch(openEditDepartmentModal());
              }}
            >
              Create a department
            </Button>
            <Button
              variant="tertiary-blue"
              onClick={() => dispatch(openCreateTeamModal())}
            >
              Pilot a team instead
            </Button>
          </div>
        </Card>
      );
    }

    return (
      <Card className="column-gap-20px">
        <h2>Organization Structure</h2>
        <div className="container-with-same-size-cards info-cards-dashboard">
          {getPeopleInfoCard()}
          {getTeamsInfoCard()}
          {getTotalTeamScanLaunchedCard()}
        </div>
      </Card>
    );
  };

  const teamsCompletedTEAM360 = useMemo(() => {
    if (!analyticsText?.teamSummary?.classificationArray) {
      return 0;
    }
    return (
      analyticsText.teamSummary.classificationArray.healthy +
      analyticsText.teamSummary.classificationArray["high-performing"] +
      analyticsText.teamSummary.classificationArray["needs-help"]
    );
  }, [analyticsText?.teamSummary]);

  const departmentsCompletedTEAM360 = useMemo(() => {
    if (!analyticsText?.departmentSummary?.classificationArray) {
      return 0;
    }

    return (
      analyticsText.departmentSummary.classificationArray.healthy +
      analyticsText.departmentSummary.classificationArray["high-performing"] +
      analyticsText.departmentSummary.classificationArray["needs-help"]
    );
  }, [analyticsText?.departmentSummary]);

  const getCompletionText = () => {
    const teamOrTeams = teamsCompletedTEAM360 === 1 ? "team" : "teams";
    const departmentOrDepartments =
      departmentsCompletedTEAM360 === 1 ? "department" : "departments";

    return `${analyticsText?.completionInfo?.totalCompleted} completed responses from
    ${teamsCompletedTEAM360} ${teamOrTeams} and
    ${departmentsCompletedTEAM360} ${departmentOrDepartments}`;
  };

  const getOrgTeam360Results = () => {
    // This means that no TEAMscan has been scheduled yet so we should not show this card at all.
    if (!analyticsText?.completionInfo?.totalInvited) {
      return null;
    }

    if (
      !allTeam360Results?.companyScores?.overall ||
      !analyticsText?.overview
    ) {
      const overlay = (
        <Popover className="team-360-popover">
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <strong>No Results Available</strong>
            <p>
              Unfortunately, not enough responses were received to show results.
              In order for results to be anonymous, at least three teams need
              three responses.
            </p>
          </div>
        </Popover>
      );
      return (
        <Card className="dashboard-container-header">
          <div className="dashboard-container-header__info">
            <div className="small-square-icon dark-grey">
              <FontAwesomeIcon icon="users" className="icon" />
            </div>
            <div className="column-gap-8px">
              <div className="row-gap-8px">
                <h3>Organization TEAMscan results not available</h3>
                <div className="label-tag grey m-0">
                  Full organization report
                </div>
              </div>
              <p>Not enough responses collected.</p>
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <p>No results available</p>{" "}
            <OverlayTrigger rootClose placement="auto" overlay={overlay}>
              <FontAwesomeIcon icon={["far", "circle-info"]} />
            </OverlayTrigger>
          </div>
        </Card>
      );
    }

    // If pending should show ard indicating that its pending and should show the count of teams/departments invited
    // If we already scheduled a TEAMscan but no results are available, and the expiration date is passed then we just show not enough responses collected
    const companyTeam360Results = allTeam360Results?.companyScores?.overall;

    return (
      <Card className="dashboard-container-header">
        <div className="dashboard-container-header__info">
          <div className="small-square-icon blue">
            <FontAwesomeIcon icon="users" className="icon" />
          </div>
          <div className="column-gap-8px">
            <div className="row-gap-8px">
              <h3>Organization TEAMscan results available</h3>
              <div className="label-tag green m-0">
                Full organization report
              </div>
            </div>
            <p>{getCompletionText()}</p>
          </div>
        </div>
        <div
          className="row-gap-20px align-items-center"
          style={{ gap: "40px" }}
        >
          <div
            style={{ height: "70px", width: "150px" }}
            className="hide-on-991px"
          >
            <HalfDonutChart
              canvasId="small-team360-preview-card-chart"
              currentVal={getTeam360Score(companyTeam360Results)}
              size="extra-small"
              borderWidth={2}
            />
          </div>
          <div className="row-gap-16px align-items-center">
            <div
              role="button"
              onClick={() => {
                navigate("/AdminConsole/Surveys");
              }}
            >
              <FontAwesomeIcon icon={["far", "gear"]} />
            </div>
            <Button
              onClick={() => {
                seeOrgTeam360Results();
              }}
              style={{
                height: "fit-content",
              }}
            >
              View results
            </Button>
          </div>
        </div>
      </Card>
    );
  };

  const getTeam360Card = () => {
    const isLaunchTeam360Disabled =
      !!assessmentInformation?.organization.activeAssessment;
    return (
      <Card className="column-gap-20px">
        <div className="dashboard-container-header">
          <div className="dashboard-container-header__info">
            <div className="small-square-icon">
              <FontAwesomeIcon icon={["fas", "poll-people"]} className="icon" />
            </div>
            <div>
              <h3>
                Launch and manage the TEAMscan teamwork survey and view results
              </h3>
              <p>
                Understand teamwork and empower higher-performing managers.{" "}
                <Link
                  to="#"
                  onClick={() => {
                    dispatch(
                      setShowModal({
                        eventType: "generalTeam360Information",
                      })
                    );
                  }}
                >
                  What is the TEAMscan?
                </Link>
              </p>
            </div>
          </div>
          <div className="row-gap-16px">
            <Button
              onClick={() => {
                setIsUnderStandTeamworkCollapsed(
                  !isUnderStandTeamworkCollapsed
                );
              }}
            >
              {isUnderStandTeamworkCollapsed ? "See" : "Hide"} actions
            </Button>
          </div>
        </div>
        <Collapse in={!isUnderStandTeamworkCollapsed}>
          <div className="three-card-container">
            <QuickActionCard
              title="Measure Teamwork"
              description="Launch the TEAMscan survey for a single team, one or more departments or your entire organization."
              actionButton={{
                onClick: () => {
                  dispatch(showScheduleAssessmentModalForOrganization());
                },
                text: "Launch TEAMscan",
              }}
              iconName="rocket-launch"
              isWholeCardDisabled={isLaunchTeam360Disabled}
              cornerTag={
                isLaunchTeam360Disabled ? (
                  <div className="label-tag green">Already active</div>
                ) : null
              }
              imageIcon={
                <img
                  alt={AssessmentMap[1].name}
                  src={AssessmentMap[1].assessmentIcon}
                />
              }
            />
            <QuickActionCard
              title="View Results"
              description={`View results from past surveys for teams${
                isDepartmentsHidden ? "" : " and departments"
              } in this organization.`}
              actionButton={{
                onClick: () => {
                  setShowResultsModal(true);
                },
                text: "View TEAMscan results",
              }}
              iconName="square-poll-vertical"
            />
            <QuickActionCard
              title="Manage Surveys"
              description="View 1-time and recurring surveys. Adjust survey period, reminders, and more."
              actionButton={{
                onClick: () => {
                  navigate("/AdminConsole/Surveys");
                },
                text: "Manage TEAMscan",
              }}
              iconName="wrench"
            />
          </div>
        </Collapse>
      </Card>
    );
  };

  const teamIds = useMemo(() => {
    return Object.keys(teamsById).map((teamId) => parseInt(teamId));
  }, [teamsById]);

  const getSurveyResultModalEmptyCard = (selectedDepartmentId?: number) => {
    const onShowGeneralTeam360Information = () => {
      dispatch(
        setShowModal({
          eventType: "generalTeam360Information",
        })
      );
      setShowResultsModal(false);
    };
    if (selectedDepartmentId) {
      return (
        <div className="empty-card">
          <span>No TEAMscan Results In This Department</span>
          <p>
            No teams have an active TEAMscan currently. Launch a TEAMscan for
            your department or one or more teams now.
          </p>
          <div className="action-buttons">
            <Button
              onClick={() => {
                dispatch(
                  showScheduleAssessmentModalForDepartmentId(
                    selectedDepartmentId
                  )
                );
                setShowResultsModal(false);
              }}
            >
              Launch TEAMscan
            </Button>
            <Button
              variant="secondary-blue"
              onClick={onShowGeneralTeam360Information}
            >
              See details
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div className="empty-card">
        <span>No TEAMscan Results In This Organization</span>
        <p>
          No teams have an active TEAMscan currently. Launch a TEAMscan for your
          organization, department, or one or more teams now.
        </p>
        <div className="action-buttons">
          <Button
            onClick={() => {
              dispatch(showScheduleAssessmentModalForOrganization());
              setShowResultsModal(false);
            }}
          >
            Launch TEAMscan
          </Button>
          <Button
            variant="secondary-blue"
            onClick={onShowGeneralTeam360Information}
          >
            See details
          </Button>
        </div>
      </div>
    );
  };

  const getLetGetStartedComponent = (hasPeople: boolean, hasTeams: boolean) => {
    const userInfo = userAccountId ? usersById[userAccountId] : null;
    const name = userInfo?.firstName ?? "";
    return (
      <>
        <h2
          style={{
            marginBottom: "20px",
          }}
        >
          Let's Get Started {name}
        </h2>
        <div className="three-card-container let-get-started">
          {!hasPeople ? (
            <QuickActionCard
              title="Step 1: Add people to your organization"
              description="Once you have people in your organization, you can create teams, add team members, and launch teamwork surveys."
              actionButton={{
                onClick: () => {
                  onShowInviteMemberModal();
                },
                text: "Add people",
                variant: "primary",
              }}
              iconName="user-plus"
              className="active-step"
            />
          ) : (
            getPeopleInfoCard()
          )}
          {!hasTeams ? (
            <QuickActionCard
              title="Step 2: Create a team"
              description="When you create a team, you can add members from the people in your organization."
              actionButton={{
                onClick: () => {
                  dispatch(openCreateTeamModal());
                },
                disabled: !hasPeople,
                text: "Create a team",
                variant: hasPeople ? "primary" : "secondary-blue",
              }}
              iconName="screen-users"
              className={hasPeople ? "active-step" : undefined}
            />
          ) : (
            getTeamsInfoCard()
          )}
          <QuickActionCard
            title="Step 3: Measure Teamwork"
            description="Once you have a team, either you (as an admin) or the team leader can launch our TEAMscan survey and measure teamwork."
            actionButton={{
              onClick: () => {
                dispatch(showScheduleAssessmentModalForOrganization());
              },
              text: "Launch TEAMscan",
              disabled: !hasTeams,
              variant: hasPeople && hasTeams ? "primary" : "secondary-blue",
            }}
            iconName="rocket-launch"
            className={hasPeople && hasTeams ? "active-step" : undefined}
          />
        </div>
      </>
    );
  };

  const hasLaunchedTeamScan = () => {
    if (analyticsText?.completionInfo?.totalInvited) {
      return true;
    }
    const teamAssessmentInfo = assessmentInformation?.teams ?? {};
    return Object.values(teamAssessmentInfo).some(
      (teamInfo) => teamInfo.activeAssessment
    );
  };

  const getBody = () => {
    if (
      getAllUserStatus !== "succeeded" ||
      getAllTeamStatus !== "succeeded" ||
      getAnalyticsTextStatus === "loading"
    ) {
      return <Loading />;
    }

    const hasPeople = Object.keys(usersById).length > 1;
    const hasTeams = Object.keys(teamsById).length > 0;
    if (!hasPeople || !hasTeams || !hasLaunchedTeamScan()) {
      return getLetGetStartedComponent(hasPeople, hasTeams);
    }

    return (
      <div className="dashboard-container">
        {getInfoCardsV2()}
        <h2>Surveys</h2>
        {getOrgTeam360Results()}
        {getTeam360Card()}
      </div>
    );
  };

  return (
    <>
      <SurveyResultsModal
        onHide={() => setShowResultsModal(false)}
        show={showResultsModal}
        teamIds={teamIds}
        showManageSurveysButton
        isAdmin
        getEmptyCard={getSurveyResultModalEmptyCard}
      />
      <PendingTeamsModal
        onHide={() => setShowPendingTeamsModal(false)}
        show={showPendingTeamsModal}
        onShowInviteTeamLeaderModal={() => {
          setShowPendingTeamsModal(false);
          onShowInviteTeamLeaderModal();
        }}
        pendingTeamLeaders={pendingTeamLeaders}
      />
      <PendingUsersModal
        onHide={() => setShowPendingUserModal(false)}
        show={showPendingUserModal}
      />
      <PendingDepartmentsModal
        onHide={() => setShowPendingDepartmentsModal(false)}
        show={showPendingDepartmentsModal}
      />
      {getBody()}
    </>
  );
}
