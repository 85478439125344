import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SortableTable from "app/components/SortableTable";
import { Link, useNavigate } from "react-router-dom";
import Button from "app/storybookComponents/Button";
import { Dropdown } from "react-bootstrap";
import {
  deleteTeam,
  selectDeleteTeamStatus,
  selectTeamsByTeamId,
  selectAllCompanyUsersById,
  selectGetAllTeamsStatus,
  selectCompanyInfo,
  getAllTeamsByCompanyAccountId,
} from "app/containers/Global/slice";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { useEffect, useState, useMemo } from "react";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import TeamLeadersPopup from "../TeamLeadersPopup";
import {
  inviteTeamLeadsForTeamId,
  selectCompanySettings,
  selectDepartments,
  selectIsDepartmentsHidden,
} from "../slice";
import { openCreateTeamModal } from "app/components/Modals/slice";
import Loading from "app/storybookComponents/Loading";
import { RowContent } from "app/components/SortableTable/types";
import SimplePopover from "app/components/Popovers/SimplePopover";
import GeneratedLogo from "resources/icons/generated-logo.svg";
import AutomaticTeamPopover from "app/containers/AutomationSettings/Popovers/AutomaticTeamPopover";
import CircularTabs from "app/storybookComponents/Tabs/CircularTabs";
import { TeamTab } from "../types";

type TeamFilterTab = "All" | "Automatic" | "Manual";

interface Props {
  filteredTeamIds?: number[];
  onInviteToTeamClick?: (teamId: number) => void;
  searchTerm?: string;
  isLoading?: boolean;
}

export default function TeamsTable({
  filteredTeamIds,
  onInviteToTeamClick,
  searchTerm,
  isLoading,
}: Readonly<Props>) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // ------------------------- App selectors ----------------------------------//
  const teamInfoById = useAppSelector(selectTeamsByTeamId);
  const departments = useAppSelector(selectDepartments);
  const usersInfoById = useAppSelector(selectAllCompanyUsersById);
  const deleteTeamStatus = useAppSelector(selectDeleteTeamStatus);
  const gettingAllTeamsStatus = useAppSelector(selectGetAllTeamsStatus);
  const companyInformation = useAppSelector(selectCompanyInfo);
  const isDepartmentsHidden = useAppSelector(selectIsDepartmentsHidden);
  const companySettings = useAppSelector(selectCompanySettings);

  // ------------------------- States ----------------------------------//
  const [showDeleteTeamWarning, setShowDeleteTeamWarning] = useState<
    false | number
  >(false);
  const [teamsFetched, setTeamsFetched] = useState(false);
  const [tabFilterSelected, setTabFilterSelected] =
    useState<TeamFilterTab>("All");

  // ------------------------- Effects ----------------------------------//
  useEffect(() => {
    if (deleteTeamStatus === "succeeded") {
      setShowDeleteTeamWarning(false);
    }
  }, [deleteTeamStatus]);

  // This useEffect hook is needed so that we rehydrate the teams in the store when we navigate to the admin console
  useEffect(() => {
    const fetchTeams = async () => {
      if (!companyInformation?.companyAccountId) {
        return;
      }
      await dispatch(
        getAllTeamsByCompanyAccountId({
          companyAccountId: companyInformation.companyAccountId,
          refreshTeams: true,
        })
      );
      setTeamsFetched(true);
    };

    if (!teamsFetched) {
      fetchTeams();
    }
  }, [dispatch, companyInformation, teamsFetched]);

  // ------------------------- Local variables ----------------------------------//
  const totalTeamsInOrganization = Object.keys(teamInfoById).length;

  // ------------------------- Function ----------------------------------//
  const onTeamTableDropdownSelect = (e: string | null, teamId: number) => {
    switch (e) {
      case "invite": {
        return onInviteToTeamClick?.(teamId);
      }
      case "manageTeam": {
        return navigate(`/TeamGuide/${teamId}/TeamSettings`);
      }
      case "viewTeamGuide": {
        return navigate(`/TeamGuide/${teamId}`);
      }
      case "deactivateTeam": {
        return setShowDeleteTeamWarning(teamId);
      }
      case "reactivateTeam": {
        return;
      }
      case "deleteTeam": {
        return;
      }
    }
  };

  const getTeamLeadWarning = (teamId: number, isLeaderPending: boolean) => {
    if (!isLeaderPending) {
      return null;
    }
    return (
      <SimplePopover
        popoverTitle="Not Invited Yet"
        popoverContent={
          <div className="column-gap-16px">
            <p>
              The leader(s) for this team has not been invited yet. Send an
              invite to team leader(s) to join this team?
            </p>
            <div>
              <Button
                variant="primary"
                onClick={() => dispatch(inviteTeamLeadsForTeamId(teamId))}
              >
                Send Invite
              </Button>
            </div>
          </div>
        }
        trigger="hover"
      >
        <FontAwesomeIcon
          icon={["far", "exclamation-circle"]}
          style={{
            color: "#CC8200",
          }}
        />
      </SimplePopover>
    );
  };

  const getTeamLeadName = (teamId: number) => {
    if (!teamInfoById[teamId]?.teamLeadUserAccountIds?.length) {
      return "";
    }

    if (teamInfoById[teamId].teamLeadUserAccountIds?.length === 1) {
      const teamLeadUserAccountId = teamInfoById[teamId]
        .teamLeadUserAccountIds?.[0] as number;
      const teamLead = usersInfoById[teamLeadUserAccountId];
      const leadName = `${teamLead?.firstName || ""} ${
        teamLead?.lastName ?? ""
      }`;
      return {
        displayValue: getTeamLeadNameElm(
          teamId,
          <Link to={`/UserGuide/${teamLeadUserAccountId}`}>{leadName}</Link>
        ),
        sortValue: leadName,
      };
    }
    return {
      displayValue: getTeamLeadNameElm(
        teamId,
        <TeamLeadersPopup
          teamLeaderIds={teamInfoById[teamId].teamLeadUserAccountIds}
        />
      ),
      sortValue: `${teamInfoById[teamId].teamLeadUserAccountIds?.length}`,
    };
  };

  const getTeamLeadNameElm = (teamId: number, content: JSX.Element) => {
    const teamInfo = teamInfoById[teamId];
    const isLeaderPending = !!teamInfo?.uninvitedTeamLeaders?.length;
    return (
      <div className="row-gap-8px align-items-center">
        <div className={isLeaderPending ? "pending-cell" : ""}>{content}</div>
        {getTeamLeadWarning(teamId, isLeaderPending)}
      </div>
    );
  };

  const getNumberOfMembers = (teamId: number, numberOfMembers?: number) => {
    // If no numberOfMembers then return 0
    if (!numberOfMembers) return 0;
    // Else return the number of members as the sort value and the display value be the link to the search page with the teamId filter as the teamId
    return {
      displayValue: (
        <Link to={`/Search/People?teamId=${teamId}`}>{numberOfMembers}</Link>
      ),
      sortValue: numberOfMembers,
    };
  };

  const onGetDropdown = (
    teamId: number,
    isActive?: boolean,
    isAutomatic?: boolean
  ) => (
    <Dropdown
      onSelect={(e) => onTeamTableDropdownSelect(e, Number(teamId))}
      style={{ marginLeft: "auto" }}
    >
      <Dropdown.Toggle
        variant="outline-primary"
        id="dropdown-basic"
        className="no-caret"
      >
        <FontAwesomeIcon icon="ellipsis" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {isActive ? (
          <>
            {isAutomatic ? null : (
              <>
                <Dropdown.Item eventKey="invite">Invite to team</Dropdown.Item>
                <Dropdown.Divider />
              </>
            )}
            <Dropdown.Item eventKey="manageTeam">
              Manage team / settings
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="viewTeamGuide">
              Go to team profile
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="deactivateTeam" className="danger">
              Deactivate team
            </Dropdown.Item>
          </>
        ) : (
          <>
            <Dropdown.Item eventKey="reactivateTeam">
              Reactivate team
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="deleteTeam" className="danger">
              Delete team
            </Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );

  const getProfileAccessCell = (teamId: number) => {
    const displayValue = teamInfoById[teamId].private ? "Private" : "Public";
    const isAutomatic = !!teamInfoById[teamId].isAutomatic;
    if (!isDepartmentsHidden) {
      return displayValue;
    }
    return {
      displayValue: (
        <div className="invitee-cell">
          {displayValue}
          {onGetDropdown(Number(teamId), isActive, isAutomatic)}
        </div>
      ),
      sortValue: displayValue,
    };
  };

  const isActive = true; // Temporary

  const tabsObj = useMemo(() => {
    const allTab: TeamTab = {
      label: "All",
      teamIds: [],
      isDisabled: false,
    };
    const manualTab: TeamTab = {
      label: "Manual",
      teamIds: [],
      isDisabled: false,
    };
    const automaticTab: TeamTab = {
      label: "Automatic",
      teamIds: [],
      isDisabled: false,
    };
    const teamIds = filteredTeamIds ?? Object.keys(teamInfoById);

    teamIds.forEach((teamId) => {
      const teamIdNum = Number(teamId);
      const teamObject = teamInfoById[teamIdNum];
      if (!teamObject) return;
      const isAutomatic = !!teamObject?.isAutomatic;
      allTab.teamIds.push(teamIdNum);
      if (isAutomatic) {
        automaticTab.teamIds.push(teamIdNum);
      } else {
        manualTab.teamIds.push(teamIdNum);
      }
    });

    return { allTab, manualTab, automaticTab };
  }, [filteredTeamIds, teamInfoById]);

  const teamIds = useMemo(() => {
    switch (tabFilterSelected) {
      case "All":
        return tabsObj.allTab.teamIds;
      case "Manual":
        return tabsObj.manualTab.teamIds;
      case "Automatic":
        return tabsObj.automaticTab.teamIds;
    }
  }, [tabFilterSelected, tabsObj]);

  const tabArray = useMemo(() => {
    const tabs: TeamTab[] = [tabsObj.allTab, tabsObj.manualTab];

    if (companySettings?.showAutomationSettings) {
      tabs.splice(tabs.length - 1, 0, tabsObj.automaticTab);
    }

    return tabs.map((tab) => ({
      label: tab.label,
      count: tab.teamIds.length,
      isDisabled: tab.teamIds.length === 0,
    }));
  }, [tabsObj, companySettings]);

  const rows: RowContent[] = [];

  teamIds.forEach((teamId) => {
    const teamObject = teamInfoById[Number(teamId)];
    if (!teamObject) return;
    const {
      teamName,
      teamMemberIds = [],
      pendingTeamMemberIds = [],
      departmentId,
    } = teamInfoById[Number(teamId)];

    const isAutomatic = !!teamObject?.isAutomatic;

    const teamNameDisplayValue = isAutomatic ? (
      <AutomaticTeamPopover
        children={
          <div className="automatic-team">
            <img
              src={GeneratedLogo}
              style={{ marginRight: "12px" }}
              alt="Generated Logo"
            />
            <span>{teamName}</span>
          </div>
        }
        isAdmin
      />
    ) : (
      teamName
    );
    rows.push({
      teamName: {
        displayValue: (
          <Link to={`/TeamGuide/${teamId}`}>{teamNameDisplayValue}</Link>
        ),
        sortValue: teamName,
      },
      teamLead: getTeamLeadName(Number(teamId)),
      numberOfMembers: getNumberOfMembers(
        Number(teamId),
        teamMemberIds.length + pendingTeamMemberIds.length
      ),
      profileAccess: getProfileAccessCell(Number(teamId)),
      department: {
        displayValue: (
          <div className="invitee-cell">
            {departmentId && departments[departmentId]?.name ? (
              <p>{departments[departmentId].name}</p>
            ) : null}
            {onGetDropdown(Number(teamId), isActive, isAutomatic)}
          </div>
        ),
        sortValue:
          (departmentId && departments[departmentId]?.name) ?? "zzzzzzz",
      },
    });
  });

  const getEmptyCard = () => {
    if (totalTeamsInOrganization === 0) {
      return (
        <div className="empty-card">
          <div className="column-gap-12px align-items-center">
            <span>
              <b>No teams have been created yet.</b>
            </span>
            <p>Teams created will be shown here</p>
            <Button
              onClick={() =>
                dispatch(openCreateTeamModal({ isFromAdminConsole: true }))
              }
            >
              <FontAwesomeIcon icon="plus" className="me-2" /> Create a team
            </Button>
          </div>
        </div>
      );
    }

    // if the length of rows is 0 then return the empty card
    if (rows.length === 0) {
      return (
        <div className="empty-card">
          <div className="column-gap-12px">
            <span>
              <b>{`No team found matching "${searchTerm}"`}</b>
            </span>
            <p>Try searching by another team name</p>
          </div>
        </div>
      );
    }

    return null;
  };

  const getColumnHeaders = () => {
    const headers = [
      {
        key: "teamName",
        label: "Team Name",
      },
      {
        key: "teamLead",
        label: "Team Lead",
      },
      {
        key: "numberOfMembers",
        label: "# of Members",
      },
      {
        key: "profileAccess",
        label: "Profile Access",
      },
    ];

    if (!isDepartmentsHidden) {
      headers.push({
        key: "department",
        label: "Department",
      });
    }
    return headers;
  };

  if (isLoading || gettingAllTeamsStatus === "loading") {
    return <Loading />;
  }

  return (
    <>
      <WarningModal
        modalTitle="Delete Team"
        warningTitle="Are you sure you want to delete this team?"
        warningMessage="This will permanently delete all team information/assessments, team guide details, and all team members from the team. This action cannot be undone."
        isOpen={showDeleteTeamWarning !== false}
        onConfirmClick={() => {
          if (typeof showDeleteTeamWarning !== "number") return;
          dispatch(deleteTeam(showDeleteTeamWarning));
        }}
        hideModal={() => setShowDeleteTeamWarning(false)}
        isInProgress={deleteTeamStatus === "loading"}
      />
      <CircularTabs
        selectedTab={tabFilterSelected}
        onTabSelected={(tab) => {
          setTabFilterSelected(tab as TeamFilterTab);
        }}
        tabs={tabArray}
        className="team-table-tabs"
      />
      <div className="admin-console-table-container">
        <SortableTable
          rows={rows}
          tableClassName="admin-console-table"
          columnHeaders={getColumnHeaders()}
        />
        {getEmptyCard()}
      </div>
    </>
  );
}
