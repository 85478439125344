import SideDrawer from "app/components/SideDrawer/SideDrawer";
import QuickActionCard from "./QuickActionCard";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  openCreateTeamModal,
  openSampleTEAMscanModal,
  openSamplePersonalityModal,
  getSampleDataInfo,
  selectSampleDataInfo,
  selectGetSampleDataInfoStatus,
  openInviteTeamLeaderModal,
  openEditDepartmentModal,
} from "app/components/Modals/slice";
import {
  showScheduleAssessmentModal,
  showScheduleAssessmentModalForOrganization,
  showScheduleAssessmentModalForDepartmentId,
  showScheduleAssessmentModalForTeamId,
} from "app/components/LaunchAssessmentModal/slice";
import {
  selectCurrentUserAccountId,
  selectUserInfoById,
} from "app/containers/Global/slice";
import {
  selectIsDepartmentsHidden,
  selectTeamAndDepartmentLeadIdsForLoggedInUser,
  selectUserIsAbleToInviteTeamLeaders,
} from "app/containers/AdminConsole/slice";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SamplePersonalityReportModal from "../Modals/SamplePersonalityReportModal";
import SampleTEAMscanModal from "../Modals/SampleTEAMscanModal";
import {
  selectUserIsAbleToCreateTeams,
  selectUserIsAbleToInviteUsers,
} from "app/containers/UserGuide/slice";
import {
  selectIsQuickActionDrawerOpen,
  setIsQuickActionDrawerOpen,
} from "./slice";
import { useIsMobile } from "utils/hooks";

export default function QuickActionDrawer() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [searchParams] = useSearchParams();
  const dashboardSelectedId = searchParams.get("dashboardSelectedId")
    ? Number(searchParams.get("dashboardSelectedId"))
    : null;
  const dashboardRole = searchParams.get("dashboardRole");
  const showing = useAppSelector(selectIsQuickActionDrawerOpen);
  const sampleDataInfo = useAppSelector(selectSampleDataInfo);
  const getSampleDataInfoStatus = useAppSelector(selectGetSampleDataInfoStatus);
  const isDepartmentsHidden = useAppSelector(selectIsDepartmentsHidden);
  const canUserInviteNewMembers = useAppSelector(selectUserIsAbleToInviteUsers);
  const canUserCreateTeams = useAppSelector(selectUserIsAbleToCreateTeams);
  const isUserAbleToInviteTeamLeader = useAppSelector(
    selectUserIsAbleToInviteTeamLeaders
  );
  const currentUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const usersInfo = useAppSelector(
    selectUserInfoById(currentUserAccountId ?? 0)
  );
  const teamAndDepartmentLeaderOf = useAppSelector(
    selectTeamAndDepartmentLeadIdsForLoggedInUser
  );

  const [showAllSampleData, setShowAllSampleData] = useState(false);
  const [slightlyShowing, setSlightlyShowing] = useState(false);

  const getRole = () => {
    if (dashboardRole) {
      return dashboardRole;
    }
    if (usersInfo.tmgRoleId === 1) {
      return "Admin";
    }

    if (teamAndDepartmentLeaderOf?.departments?.length) {
      return "Department Leader";
    }

    if (teamAndDepartmentLeaderOf?.teams?.length) {
      return "Team Leader / Team Member";
    }

    return "Team Member";
  };

  const getDepartmentId = () => {
    if (dashboardRole === "Department Leader" && dashboardSelectedId) {
      return dashboardSelectedId;
    }
    if (teamAndDepartmentLeaderOf?.departments?.length) {
      return teamAndDepartmentLeaderOf.departments[0];
    }
    return null;
  };

  const getTeamId = () => {
    if (dashboardRole === "Team Leader / Team Member" && dashboardSelectedId) {
      return dashboardSelectedId;
    }
    if (teamAndDepartmentLeaderOf?.teams?.length) {
      return teamAndDepartmentLeaderOf.teams[0];
    }
    return null;
  };

  const role = getRole();
  const departmentId = getDepartmentId();

  useEffect(() => {
    if (getSampleDataInfoStatus === "idle") {
      dispatch(getSampleDataInfo());
    }
  }, [dispatch, getSampleDataInfoStatus]);

  const getPersonalityInsightsQuickActionCard = () => {
    return (
      <QuickActionCard
        title="Understand the power of personality"
        description="Learn more about your working style and tips for working with your teammates."
        secondaryButton={{
          onClick: () => {
            dispatch(openSamplePersonalityModal());
          },
          text: "See sample personality reports",
        }}
        iconPrefix="fas"
        iconName="file-chart-pie"
      />
    );
  };

  const navigateWrapperFn = (path: string, state?: any) => {
    navigate(path, state);
    if (isMobile) {
      setShowing(false);
    }
  };

  const getSampleDrawerBody = () => (
    <>
      <h3 className="sample-reports-header">Teamwork Survey</h3>
      <QuickActionCard
        title="Sample team-level TEAMscan report"
        description="This is a sample report any team leader or member gets after their team has completed the TEAMscan."
        actionButton={{
          onClick: () =>
            navigateWrapperFn(
              `TeamGuide/${sampleDataInfo?.teamScanTeamId}?tab=TEAMscan`
            ),
          text: "View sample report",
        }}
      />
      <QuickActionCard
        title="Sample department-level TEAMscan report"
        description="This is a sample report any department leader gets after teams in their department have completed the TEAMscan."
        actionButton={{
          onClick: () =>
            navigateWrapperFn(
              `/DepartmentGuide/${sampleDataInfo?.teamScanDepartmentId}?tab=TEAMscan`
            ),
          text: "View sample report",
        }}
      />
      <QuickActionCard
        title="Sample organization-level TEAMscan report"
        description="This is a sample report any admin gets after teams in the organization have completed the TEAMscan."
        actionButton={{
          onClick: () =>
            navigateWrapperFn("AdminConsole/Analytics?isSample=true"),
          text: "View sample report",
        }}
      />
      <QuickActionCard
        title="Preview TEAMscan survey"
        description="Preview one question from each of the four teamwork areas in this survey."
        actionButton={{
          onClick: () => navigateWrapperFn("/survey/team360?eventId=sample"),
          text: "Preview TEAMscan",
        }}
      />
      <h3 className="sample-reports-header">Personality Insights</h3>
      <QuickActionCard
        title="Sample Individual Personality Reports"
        description="This is where you will find sample Workplace Insights Reports (for self-awareness) as well as sample Collaboration Guides and Manager Guides."
        actionButton={{
          onClick: () =>
            navigateWrapperFn(
              `UserGuide/${sampleDataInfo?.sampleUserAccountId}?tab=Workplace+Insights`
            ),
          text: "View sample report",
        }}
      />
      <QuickActionCard
        title="Sample Team Personality report"
        description="This is a sample team-level personality report. This report is generated for any team whose members take or connect the Employee Personality Profile. Visibility can be set in settings."
        actionButton={{
          onClick: () =>
            navigateWrapperFn(
              `TeamGuide/${sampleDataInfo?.teamPersonalityTeamId}?tab=Team+Personality`
            ),
          text: "View sample report",
        }}
      />
      <h3 className="sample-reports-header">Profiles</h3>
      <QuickActionCard
        title="Sample user profile"
        description="This is a sample user profile where you can share more about yourself and your role with your teammates."
        actionButton={{
          onClick: () =>
            navigateWrapperFn(
              `/UserGuide/${sampleDataInfo?.sampleUserAccountId}?tab=About`
            ),
          text: "View sample report",
        }}
      />
      <QuickActionCard
        title="Sample team profile"
        description="This is a sample team profile where your team can share what the team does, your core values, and key resources with other teams."
        actionButton={{
          onClick: () =>
            navigateWrapperFn(
              `TeamGuide/${sampleDataInfo?.teamPersonalityTeamId}?tab=About`
            ),
          text: "View sample report",
        }}
      />
    </>
  );

  const getDrawerBody = () => {
    switch (role) {
      case "Team Leader":
      case "Team Member":
      case "Team Leader / Team Member":
        return (
          <>
            {canUserCreateTeams ? (
              <QuickActionCard
                title="Create a new team"
                description="Name your new team, invite team members, and set up your team guide."
                actionButton={{
                  onClick: () => dispatch(openCreateTeamModal()),
                  text: "Create a team",
                }}
                secondaryButton={{
                  onClick: () => {
                    navigateWrapperFn(
                      `/TeamGuide/${sampleDataInfo?.teamPersonalityTeamId}?tab=About`
                    );
                  },
                  text: "See sample team guide",
                }}
                iconPrefix="fas"
                iconName="poll-people"
              />
            ) : null}
            {role === "Team Leader" || role === "Team Leader / Team Member" ? (
              <QuickActionCard
                title="Launch TEAMscan"
                description="Launch the survey for your entire team."
                actionButton={{
                  onClick: () => {
                    const teamId = getTeamId();
                    if (teamId) {
                      dispatch(showScheduleAssessmentModalForTeamId(teamId));
                    } else {
                      dispatch(showScheduleAssessmentModal());
                    }
                  },
                  text: "Launch TEAMscan",
                }}
                secondaryButton={{
                  onClick: () => {
                    dispatch(openSampleTEAMscanModal());
                  },
                  text: "See sample TEAMscan report",
                }}
                iconName="rocket-launch"
              />
            ) : null}
            {getPersonalityInsightsQuickActionCard()}
          </>
        );
      case "Department Leader":
        return (
          <>
            {canUserCreateTeams ? (
              <QuickActionCard
                title="Create a team"
                description="Create a team of your direct reports and assign yourself or another teammate as team leader. Suggestion: Only create teams you directly manage."
                actionButton={{
                  onClick: () =>
                    dispatch(
                      openCreateTeamModal({
                        preSelectedDepartmentId: departmentId,
                      })
                    ),
                  text: "Create a team",
                }}
                secondaryButton={{
                  onClick: () => {
                    navigateWrapperFn(
                      `/TeamGuide/${sampleDataInfo?.teamPersonalityTeamId}?tab=About`
                    );
                  },
                  text: "See sample team guide",
                }}
                iconPrefix="fas"
                iconName="poll-people"
              />
            ) : null}
            <QuickActionCard
              title="Launch TEAMscan"
              description="Launch the survey for your entire department or specific teams in your department."
              actionButton={{
                onClick: () => {
                  if (departmentId) {
                    dispatch(
                      showScheduleAssessmentModalForDepartmentId(departmentId)
                    );
                  }
                },
                text: "Launch TEAMscan",
              }}
              secondaryButton={{
                onClick: () => {
                  dispatch(openSampleTEAMscanModal());
                },
                text: "See sample TEAMscan report",
              }}
              iconName="rocket-launch"
            />
            {isUserAbleToInviteTeamLeader ? (
              <QuickActionCard
                title="Invite team leaders"
                description="Invite team leaders to set up their team, invite team members, and create their team guide."
                actionButton={{
                  onClick: () => {
                    dispatch(
                      openInviteTeamLeaderModal({ departmentId: departmentId })
                    );
                  },
                  text: "Invite leader",
                }}
                iconPrefix="fas"
                iconName="user-plus"
              />
            ) : null}
          </>
        );
      case "Admin":
        return (
          <>
            <QuickActionCard
              title="All Sample Reports"
              description="See all sample reports for the TEAMscan survey and personality insight reports."
              actionButton={{
                onClick: () => {
                  setShowAllSampleData(true);
                },
                text: "See sample reports",
              }}
            />
            {canUserInviteNewMembers ? (
              <QuickActionCard
                title="Add people to this Develop"
                description="Add people to this organization. This enables you to add them to teams or make them team leaders."
                actionButton={{
                  onClick: () => {
                    navigateWrapperFn(`/AdminConsole/People`, {
                      state: {
                        openInviteModal: true,
                      },
                    });
                  },
                  text: "Add people",
                }}
                iconPrefix="fas"
                iconName="user-plus"
              />
            ) : null}
            {canUserCreateTeams ? (
              <QuickActionCard
                title="Create a team"
                description="Create a team of your direct reports and assign yourself or another teammate as team leader. Suggestion: Only create teams you directly manage."
                actionButton={{
                  onClick: () => {
                    navigateWrapperFn(`/AdminConsole/Teams`);
                    dispatch(openCreateTeamModal());
                  },
                  text: "Create a team",
                }}
                iconPrefix="fas"
                iconName="poll-people"
              />
            ) : null}
            <QuickActionCard
              title="Launch TEAMscan"
              description="Launch the survey for a single team, one or more departments, or your entire organization."
              actionButton={{
                onClick: () => {
                  dispatch(showScheduleAssessmentModalForOrganization());
                },
                text: "Launch TEAMscan",
              }}
              iconName="rocket-launch"
            />
            {!isDepartmentsHidden ? (
              <QuickActionCard
                title="Set up departments"
                description="Set up your departments structure to enable easier onboarding and analytics."
                actionButton={{
                  onClick: () => {
                    navigateWrapperFn(`/AdminConsole?tab=Departments`);
                    dispatch(openEditDepartmentModal());
                  },
                  text: "Create a department",
                }}
                iconPrefix="fas"
                iconName="wrench"
                listSpan="Full Roll Out"
              />
            ) : null}
          </>
        );
      default:
        return null;
    }
  };

  const getTitle = () => {
    if (showAllSampleData) {
      return "Back to Quick Actions";
    }

    return "Quick Actions";
  };

  const getClassName = () => {
    if (showing) {
      return "quick-actions open";
    }
    if (slightlyShowing) {
      return "quick-actions slightly-open";
    }

    return "quick-actions";
  };

  const getQuickActionsButtonClass = () => {
    if (showing) {
      return "quick-action-button open";
    }
    if (slightlyShowing) {
      return "quick-action-button slightly-open";
    }
    return "quick-action-button";
  };

  const setShowing = (showing: boolean) => {
    dispatch(setIsQuickActionDrawerOpen(showing));
  };

  return (
    <>
      <SampleTEAMscanModal sampleDataInfo={sampleDataInfo} />
      <SamplePersonalityReportModal sampleDataInfo={sampleDataInfo} />
      <button
        className={getQuickActionsButtonClass()}
        onClick={() => setShowing(!showing)}
        onMouseEnter={() => {
          if (!showing) setSlightlyShowing(true);
        }}
        onMouseLeave={() => {
          if (!showing) setSlightlyShowing(false);
        }}
      >
        Quick Actions
        <FontAwesomeIcon
          icon={`circle-arrow-${showing ? "down" : "up"}`}
          className="ms-2"
        />
      </button>
      <SideDrawer
        show={true}
        onHide={() => {
          setShowing(false);
        }}
        title={getTitle()}
        className={getClassName()}
        isSecondary={showAllSampleData}
        onBack={() => setShowAllSampleData(false)}
      >
        {showAllSampleData ? getSampleDrawerBody() : getDrawerBody()}
      </SideDrawer>
    </>
  );
}
