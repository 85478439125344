import { useEffect, useRef, useState } from "react";
import Button from "app/storybookComponents/Button";
import Chatbot from "./Chatbot";
import Robot from "resources/images/robot.svg";
import {
  selectAiFeaturesEnabled,
  selectHasCoachBoConversationHistoryEnabled,
} from "app/containers/AdminConsole/slice";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  getCoachBoConversations,
  processConversation,
  resetChatbotState,
  selectAllChatbotConversations,
  selectChatbotConversationEntities,
  selectChatbotMessages,
  selectGetCoachBoConversationsStatus,
  selectHidingChatbotButtonFromRoot,
  selectIsCoachbotOpen,
  selectShowFeedbackCardOnClose,
  selectSubmittingCoachbotFeedbackStatus,
  selectThreadId,
  setChatbotMessages,
  setCoachBotPrompt,
  setIsCoachbotOpen,
  setThreadId,
  submitCoachbotFeedback,
  updateCoachbotConversation,
} from "./slice";
import TextArea from "app/storybookComponents/Forms/TextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CHATBOT_FEEDBACK_EXAMPLES, CHATBOT_OTHER_FEEDBACK } from "./constants";
import { useLocation, useSearchParams } from "react-router-dom";
import { selectCurrentUserInfo } from "app/containers/Global/slice";

export default function FloatingChatbotButton() {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryThreadId = searchParams.get("threadId");

  const isOpen = useAppSelector(selectIsCoachbotOpen);
  const areAiFeaturesEnabled = useAppSelector(selectAiFeaturesEnabled);
  const forceHideChatbotButton = useAppSelector(
    selectHidingChatbotButtonFromRoot
  );
  const threadId = useAppSelector(selectThreadId);
  const submittingFeedbackStatus = useAppSelector(
    selectSubmittingCoachbotFeedbackStatus
  );
  const getCoachBoConversationsStatus = useAppSelector(
    selectGetCoachBoConversationsStatus
  );
  const savedChatBoConversations = useAppSelector(
    selectAllChatbotConversations
  );
  const savedChatBoConversationsEntities = useAppSelector(
    selectChatbotConversationEntities
  );
  const currentUserInfo = useAppSelector(selectCurrentUserInfo);
  const showFeedbackCardOnClose = useAppSelector(selectShowFeedbackCardOnClose);
  const chatBotMessages = useAppSelector(selectChatbotMessages);
  const isConversationHistoryEnabledCompanyWide = useAppSelector(
    selectHasCoachBoConversationHistoryEnabled
  );

  const [showFeedbackCard, setShowFeedbackCard] = useState(false);
  const [isHelpful, setIsHelpful] = useState<null | boolean>(null);
  const [showFeedbackInput, setShowFeedbackInput] = useState(false);
  const [feedbackInputText, setFeedbackInputText] = useState("");
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const feedBackShowingRef = useRef<boolean | null>(null);

  useEffect(() => {
    setShowFeedbackCard(false);
    feedBackShowingRef.current = false;
  }, [pathname]);

  useEffect(() => {
    if (isOpen) {
      setShowFeedbackCard(false);
      feedBackShowingRef.current = false;
    }
  }, [isOpen]);

  useEffect(() => {
    if (getCoachBoConversationsStatus === "idle") {
      dispatch(getCoachBoConversations());
    }
  }, [dispatch, getCoachBoConversationsStatus]);

  useEffect(() => {
    const selectedConversation = queryThreadId
      ? savedChatBoConversationsEntities[queryThreadId]
      : null;

    if (!selectedConversation) {
      return;
    }

    setSearchParams((sp) => {
      sp.delete("threadId");
      return sp;
    });
    dispatch(setIsCoachbotOpen(true));
    dispatch(setThreadId(selectedConversation.threadId));
    dispatch(setChatbotMessages(selectedConversation.messages));
  }, [
    dispatch,
    queryThreadId,
    savedChatBoConversationsEntities,
    setSearchParams,
  ]);

  const closeFeedbackCard = () => {
    setShowFeedbackCard(false);
    setIsHelpful(null);
    setShowFeedbackInput(false);
    setFeedbackInputText("");
    dispatch(resetChatbotState());
    feedBackShowingRef.current = false;
  };

  // Function to show the feedback card and start the timer
  const onShowFeedbackCard = () => {
    setShowFeedbackCard(true);
    feedBackShowingRef.current = true;
    startTimer(5000);
    // Only process the conversation if the user has the setting enabled
    // and the feature flag is enabled
    if (
      threadId &&
      currentUserInfo?.hasCoachBoConversationHistoryEnabled &&
      isConversationHistoryEnabledCompanyWide
    ) {
      dispatch(processConversation(threadId));
    }
  };

  // When starting the timer we clear any existing timer and set a new one
  const startTimer = (time: number = 3000) => {
    timerRef.current && clearTimeout(timerRef.current); // Clear any existing timer
    if (!timerRef) {
      return;
    }

    timerRef.current = setTimeout(() => {
      if (feedBackShowingRef.current) {
        closeFeedbackCard();
      }
    }, time);
  };

  const onSubmitFeedback = (feedbackString: string) => {
    // send request to the api
    if (isHelpful !== null) {
      dispatch(
        submitCoachbotFeedback({
          feedback: feedbackString,
          feedbackType: isHelpful ? "thumbsUp" : "thumbsDown",
        })
      );
    }
  };

  const getFeedbackExamples = () => {
    if (submittingFeedbackStatus === "succeeded") {
      return null;
    }

    if (showFeedbackInput) {
      return (
        <div>
          <TextArea
            inputLabel="Let us know your comments:"
            inputText={feedbackInputText}
            onTextChange={(e) => setFeedbackInputText(e)}
            placeholder="Feel free to add any specific details here..."
            controlId="feedback-input"
          />
          <Button
            onClick={() => {
              onSubmitFeedback(feedbackInputText);
            }}
            disabled={!feedbackInputText}
            style={{
              marginTop: "12px",
            }}
          >
            Submit
          </Button>
        </div>
      );
    }

    const helpfulOrNot = isHelpful ? "helpful" : "notHelpful";
    return (
      <div>
        <p className="textarea-label mb-2">Tell us more:</p>
        <div className="row-gap-8px flex-wrap">
          {CHATBOT_FEEDBACK_EXAMPLES[helpfulOrNot].map((tip) => (
            <Button
              onClick={() => {
                if (tip === CHATBOT_OTHER_FEEDBACK) {
                  setShowFeedbackInput(true);
                } else {
                  onSubmitFeedback(tip);
                }
              }}
              variant="secondary-blue"
              disabled={submittingFeedbackStatus === "loading"}
              key={tip}
            >
              {tip}
            </Button>
          ))}
        </div>
      </div>
    );
  };

  const getHelpfulButton = (helpful: boolean, active?: boolean) => (
    <Button
      onClick={() => {
        setIsHelpful(helpful);
      }}
      variant="secondary-blue"
      active={active}
    >
      <FontAwesomeIcon
        icon={`thumbs-${helpful ? "up" : "down"}`}
        className="me-2"
      />
      {helpful ? "" : "Not "}Helpful
    </Button>
  );

  const getFeedbackButtonText = () => {
    if (isHelpful === null) {
      return (
        <div className="column-gap-12px">
          <div>
            <p>How was your conversation with Coach Bo?</p>
            <p>Let us know so we can continue to improve your experience.</p>
          </div>
          <div className="row-gap-8px">
            <div>{getHelpfulButton(true)}</div>
            <div>{getHelpfulButton(false)}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="row-gap-12px align-items-center">
        {getHelpfulButton(isHelpful, true)}
        <p>Thank you for your feedback!</p>
      </div>
    );
  };

  const getFeedbackButton = () => {
    if (!showFeedbackCard) {
      return null;
    }
    return (
      <div
        className="floating-chatbot-feedback-card"
        onMouseEnter={() => {
          timerRef?.current && clearTimeout(timerRef.current);
        }}
        onMouseLeave={() => {
          startTimer(3000);
        }}
      >
        <div className="row-gap-16px align-items-center">
          <FontAwesomeIcon
            icon={["far", "circle-question"]}
            fontSize={16}
            className={isHelpful === null ? "align-self-start" : ""}
          />
          {getFeedbackButtonText()}
          <div className="align-self-start ms-auto">
            <Button
              xIcon
              variant="secondary-blue"
              onClick={closeFeedbackCard}
              className="border-0  p-0"
            />
          </div>
        </div>
        {isHelpful !== null ? <>{getFeedbackExamples()}</> : null}
      </div>
    );
  };

  const onOpenChatbot = () => {
    // if length of savedChatBoConversations is 0 and status is succeeded we show the welcome chat
    if (
      savedChatBoConversations.length === 0 &&
      getCoachBoConversationsStatus === "succeeded" &&
      currentUserInfo?.hasCoachBoConversationHistoryEnabled
    ) {
      const name = currentUserInfo?.firstName ?? "";
      dispatch(
        setCoachBotPrompt({
          chatType: "welcomeChat",
          userPrompts: [
            "Explore how chatting together regularly can help",
            "Envision my ideal future",
            "Create a 1-2 month growth goal",
          ],
          fullBotIntro: `Hi ${name}, I’m looking forward to getting to know you and helping you achieve your goals. What can I help you with today?`,
        })
      );
    } else {
      dispatch(setCoachBotPrompt(null));
    }
    dispatch(setIsCoachbotOpen(true));
  };

  const onCloseChatbot = () => {
    dispatch(setIsCoachbotOpen(false));

    if (showFeedbackCardOnClose) {
      const existingConversation =
        (threadId && savedChatBoConversationsEntities[threadId]) ?? {};
      const now = new Date().toISOString();

      dispatch(
        updateCoachbotConversation({
          created_at: now,
          ...existingConversation, // If there is an existing conversation we should keep the created at the same
          updated_at: now,
          messages: chatBotMessages,
          threadId: threadId ?? "",
        })
      );
      return onShowFeedbackCard();
    }
    // if there is no threadId then we should reset the state
    dispatch(resetChatbotState());
  };

  const getChatbotWelcomeMessage = () => {
    if (
      savedChatBoConversations.length === 0 &&
      getCoachBoConversationsStatus === "succeeded"
    ) {
      return "Want help making this week a great one?";
    }
    return "Need coaching advice?";
  };

  if (!areAiFeaturesEnabled) {
    return null;
  }

  return (
    <>
      <div className="analytics-sticky-footer" />
      <button
        className="floating-chatbot-button"
        style={{
          display: !isOpen && !forceHideChatbotButton ? "block" : "none",
        }}
        onClick={onOpenChatbot}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={Robot} alt="Criteria" className="assistant-avatar" />
          <p>{getChatbotWelcomeMessage()}</p>
        </div>
      </button>
      <Chatbot isOpen={isOpen} closeChatbot={onCloseChatbot} />
      {getFeedbackButton()}
    </>
  );
}
