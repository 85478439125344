import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectGetInvitationOverviewStatus,
  selectInvitationOverview,
  getInvitationOverview,
} from "./slice";
import { useEffect } from "react";
import SimplePopover from "../Popovers/SimplePopover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  departmentIds: number[];
}

export default function InvitationOverview({ departmentIds }: Readonly<Props>) {
  const dispatch = useAppDispatch();

  const invitationOverview = useAppSelector(selectInvitationOverview);
  const getInvitationOverviewStatus = useAppSelector(
    selectGetInvitationOverviewStatus
  );

  const inviteOrg = departmentIds?.length === 0;

  useEffect(() => {
    dispatch(
      getInvitationOverview({
        departmentIds: departmentIds || [],
        inviteOrg: inviteOrg,
      })
    );
  }, [departmentIds, dispatch, inviteOrg]);

  const getToolTip = (popoverContent: JSX.Element, popoverTitle: string) => {
    return (
      <SimplePopover
        popoverContent={popoverContent}
        popoverTitle={popoverTitle}
        trigger="hover"
      >
        <FontAwesomeIcon
          icon={["far", "question-circle"]}
          className="ms-1 grey-text"
        />
      </SimplePopover>
    );
  };

  const getSection = (
    category: string,
    count: number,
    tooltip: JSX.Element
  ) => {
    const memberCount = count === 1 ? `${count} member` : `${count} members`;
    return (
      <div className="invite-overview-section">
        <div className="d-flex align-items-center">
          <p className="grey-text">{category}</p>
          {getToolTip(tooltip, category)}
        </div>
        <p className="grey-text">{memberCount}</p>
      </div>
    );
  };

  if (!invitationOverview || getInvitationOverviewStatus === "loading") {
    return null;
  }

  return (
    <>
      <p className="sapphire-150-text fw-bold">Invitation overview:</p>
      <p>
        Each person will receive one email. This email will contain links to
        complete the TEAMscan for all teams they’ve been invited to complete the
        survey for.
      </p>
      <div className="invite-overview">
        {inviteOrg &&
          getSection(
            "People receiving no invites",
            invitationOverview.receivingNoInvite.length,
            <>
              <p>
                The TEAMscan survey is designed for teams that work together
                regularly with shared goals.
              </p>
              <p>
                Some people may not receive invitations because they have not
                been added to a team yet.
              </p>
            </>
          )}
        {getSection(
          "People receiving 1 invite",
          invitationOverview.receivingOneInvite.length,
          <>
            <p>
              In this TEAMscan instance, these are people who are members of one
              team.
            </p>
            <p>
              They'll receive an invitation to provide feedback about their
              team's dynamics.
            </p>
          </>
        )}
        {getSection(
          "People receiving 2-3 invites",
          invitationOverview.receivingTwoToThreeInvites.length,
          <>
            <p>
              In this TEAMscan instance, these are people who are members of
              four or more teams.
            </p>
            <p>
              They’ll be prompted to take a TEAMscan for each team, as team
              dynamics vary between groups.
            </p>
          </>
        )}
        {getSection(
          "People receiving 4+ invites",
          invitationOverview.receivingFourOrMoreInvites.length,
          <>
            <p>
              These people are on four or more teams that are part of this
              TEAMscan.
            </p>
            <p>
              Consider checking to see if there are any duplicate teams in your
              organization, or communicating to your company that they can check
              which teams to prioritize with their team leaders.
            </p>
            <p>Each survey takes about 5 minutes.</p>
          </>
        )}
      </div>
    </>
  );
}
